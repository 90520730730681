/* eslint-disable react/prop-types */
//
import React, { useCallback, PureComponent, Fragment, useContext } from 'react';
import {
  SideDrawerContent,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
} from '@whispir/ui-lib-v2';
import { trackEvent } from '@whispir/utils-js';
import { defaultExpression } from '@whispir/workflow-data-model';
import { useWorkflowContext } from '../../../../state/Workflow.context';
import { UserContext } from '../../../../state';
import { ExpressionsContainer } from './Expression.style';
import ExpressionComposite from './ExpressionComposite';

export class Expression extends PureComponent {
  static defaultProps = {
    isReadOnly: false,
  };

  state = {
    expression: defaultExpression(),
  };

  componentDidMount() {
    const {
      node: {
        data: { expression },
      },
    } = this.props;
    this.updateExpressionState(expression);
  }

  componentWillUnmount() {
    const { onUnmount } = this.props;
    onUnmount();
  }

  updateExpressionState = (expression) => {
    if (expression) {
      this.setState({ expression });
    }
  };

  handleApplyClick = () => {
    const { onSaveRequest, onCloseRequest } = this.props;
    const { expression } = this.state;
    onSaveRequest(expression);
    onCloseRequest();
  };

  render() {
    const {
      validate,
      isReadOnly,
      onDeleteClick,
      node: {
        id: nodeId,
        data: { stepName },
      },
    } = this.props;

    const { expression } = this.state;

    const hasErrors = Boolean(validate({ expression, stepName }));
    const updateEnabled = !(hasErrors || isReadOnly);

    return (
      <SideDrawerContent
        title="If Condition"
        header={
          <IconButton size="medium" icon="Delete" onClick={onDeleteClick} />
        }
        footer={
          <Fragment>
            <Divider isHorizontal dividerType="solid" />
            <ButtonGroup>
              <Button
                disabled={!updateEnabled}
                onClick={this.handleApplyClick}
                text="Save &amp; Close"
                className="apply-button"
              />
            </ButtonGroup>
          </Fragment>
        }
      >
        <ExpressionsContainer>
          <ExpressionComposite
            nodeId={nodeId}
            expressionState={expression}
            updateExpressionState={this.updateExpressionState}
          />
        </ExpressionsContainer>
      </SideDrawerContent>
    );
  }
}

export default Expression;

const getExpressionOperatorsRecursive = (expression) => {
  if (['and', 'or'].includes(expression.operator)) {
    const nestedOperators = expression.operands.map(
      getExpressionOperatorsRecursive,
    );
    const operators = [expression.operator, ...nestedOperators];
    return operators.flat();
  }

  return [expression.operator];
};

export const FeatureFlaggedExpression = (props) => {
  const { onDeleteClick, node } = props;
  const { id } = node;
  const [workflowContext] = useWorkflowContext();
  const [userContext] = useContext(UserContext);
  const {
    user: { rawUserId },
  } = userContext;

  const onSaveRequest = useCallback(
    (expression) => {
      const {
        workflow,
        workflow: { updateNodeData },
      } = workflowContext;
      updateNodeData(id, { expression });

      trackEvent({
        event: 'Expression Saved',
        userId: rawUserId,
        properties: {
          workflowId: workflow.id,
          operators: getExpressionOperatorsRecursive(expression),
        },
      });
    },
    [workflowContext, id, rawUserId],
  );

  return (
    <Expression
      {...props}
      onDeleteClick={onDeleteClick}
      onSaveRequest={onSaveRequest}
    />
  );
};
