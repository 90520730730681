import styled from 'styled-components';
import { AccordianStepper, Fonts, Colors } from '@whispir/ui-lib-v2';

export const StyledAccordianStepper = styled(AccordianStepper)`
  .last-form-item {
    margin-bottom: 0px;
  }
`;

export const StyledEditableTitle = styled.div`
  .error {
    ${Fonts.caption};
    color: ${Colors.error};
  }

  input {
    ${Fonts.subheadSerif};
    border: none;
    padding: 0;
  }

  span {
    &:hover {
      pointer: text;
    }
  }
`;

export const ActionRadioCardWrapper = styled.div`
  width: 448px;

  .intro {
    ${Fonts.body}
    padding-bottom: 16px;
  }

  .button-actions {
    & > * {
      margin: 32px 16px 0 0;
    }
  }
`;
