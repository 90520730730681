import React from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import { Colors, Elevations, Fonts } from '@whispir/ui-lib-v2';

const Spacing = '32px';
const SpacingSide = '32px';
const MinWidth = '280px';
const MaxWidth = '400px';

export const StyledDialog = styled(({ paper, ...other }) => (
  <Dialog classes={{ paper: 'paper' }} {...other} />
))`

  .button {
    width: 120px; 
  }

  .dialog-actions {
    justify-content: flex-end;
    margin: 0;

    .cancel-button,
    .option-button {
      cursor: pointer;
      margin-right: 8px;
    }
  }

  .dialog-title{
    padding: 0 0 32px 0;
    width: ${MaxWidth};

    h2 {
      ${Fonts.headline};
      font-weight: bold;
    }
  }

  .dialog-content {
    padding: 0 0 32px 0;
    width: ${MaxWidth};
    overflow: initial;
    ${Fonts.body};
  }

  .paper {
    min-width: ${MinWidth};
    padding: ${Spacing} ${SpacingSide};
    margin: 4px 0 0 0;
    border-radius: 8px;
    font-family: ${Fonts.fontFamily};
    color: ${Colors.grey_blue_5}
    box-shadow: ${Elevations.elevation_1};

    p {
      margin-top:0px;
    }
  }

  .cancel {
    background-color: transparent;
    color: ${Colors.accent};
  }

  .close {
    position: absolute;
    right: calc(${Spacing} - 2px);
    top: calc(${Spacing} - 2px);
    border: 0;
    background: none;
    padding: 0;
    cursor: pointer;
    outline: none;

    svg {
      fill: ${Colors.grey_blue_4};
      width: 24px;
      height: 24px;

      &:hover {
        fill: ${Colors.accent};
      }
    }
  }

  .cancel-button,
  .option-button {
    ${Fonts.bodyLarge};
    color: ${Colors.accent};
    text-decoration: none;
    border: none;
    background: transparent;
  }
`;
