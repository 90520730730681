import { sendMessageFactory } from '@whispir/workflow-factory';

const {
  contentFactory: { generateRawContent, generateComponentContent },
  messageFactory: { generateEmailMessage, generateSmsMessage },
  linkFactory: { generateWebLink },
} = sendMessageFactory;

export const updateChannelSubject = ({ channelType, subject, nodeData }) => {
  let updatedMessageData;
  let updatedLinkData;

  const { message, link } = nodeData;

  switch (channelType) {
    case 'sms': {
      updatedMessageData = generateSmsMessage({
        content: generateRawContent({
          body: message.content.body,
          subject,
        }),
      });
      updatedLinkData = link;
      break;
    }
    case 'email': {
      updatedMessageData = generateEmailMessage({
        content: generateComponentContent({
          subject,
          components: message.content.components,
        }),
      });
      updatedLinkData = link;
      break;
    }
    case 'web': {
      const linkComponentsExist = link && link.content.components.length > 0;
      if (subject === '' && !linkComponentsExist) {
        updatedLinkData = null;
      } else {
        updatedLinkData = generateWebLink({
          content: generateComponentContent({
            subject,
            components: link ? link.content.components : [],
          }),
        });
      }
      updatedMessageData = message;
      break;
    }
    case 'webform': {
      // Same as web, but not wtih the nulling stuff
      updatedLinkData = generateWebLink({
        content: generateComponentContent({
          subject,
          components: link ? link.content.components : [],
        }),
      });
      updatedMessageData = message;
      break;
    }

    default:
      throw new Error(`Channel name not found: ${channelType}`);
  }

  return { link: updatedLinkData, message: updatedMessageData };
};

export const updateChannelBody = ({
  channelType,
  value,
  nodeData,
  isContentBuilderEnabled = false,
}) => {
  let updatedMessageData;
  let updatedLinkData;

  const { message, link } = nodeData;

  switch (channelType) {
    case 'sms': {
      updatedMessageData = generateSmsMessage({
        content: generateRawContent({
          body: value,
          subject: message.content.subject,
        }),
      });
      updatedLinkData = link;
      break;
    }
    case 'email': {
      if (isContentBuilderEnabled) {
        updatedMessageData = generateEmailMessage({
          content: value[channelType],
        });
      } else {
        updatedMessageData = generateEmailMessage({
          content: generateComponentContent({
            subject: message.content.subject,
            components: value,
          }),
        });
      }
      updatedLinkData = link;
      break;
    }
    case 'web': {
      // If value has no items in array, the link can be reset to null
      const linkSubjectExists =
        link && link.content.subject && link.content.subject.length !== 0;
      if (isContentBuilderEnabled) {
        const { type, contentId } = value[channelType];

        updatedLinkData = generateWebLink({
          content: { type, contentId },
        });
      } else if (value.length === 0 && !linkSubjectExists) {
        updatedLinkData = null;
      } else {
        updatedLinkData = generateWebLink({
          content: generateComponentContent({
            subject: link ? link.content.subject : '',
            components: value,
          }),
        });
      }
      updatedMessageData = message;
      break;
    }
    case 'webform': {
      if (isContentBuilderEnabled) {
        const { type, contentId } = value[channelType];

        updatedLinkData = generateWebLink({
          content: { type, contentId },
        });
      } else {
        // Same as web, but we don't want the reset to null thing.
        updatedLinkData = generateWebLink({
          content: generateComponentContent({
            subject: link ? link.content.subject : '',
            components: value,
          }),
        });
      }
      updatedMessageData = message;
      break;
    }

    default:
      throw new Error(`Channel name not found: ${channelType}`);
  }

  return { link: updatedLinkData, message: updatedMessageData };
};
