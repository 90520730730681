import { AxiosInstance } from 'axios';
import { useCallback } from 'react';
import { GET_WORKFLOW_QUERY } from '../apollo/queries/workflow-queries';
import { useLoading } from './useLoading';

export const useGetWorkflow = ({ api }: { api: AxiosInstance }) => {
  const getWorkflow = useCallback(
    async (body) => {
      const { data } = await api.request(
        {
          data: {
            operationName: 'getWorkflow',
            variables: {
              id: body.workflowId,
            },
            query: GET_WORKFLOW_QUERY,
          }
        },
      );

      return data;
    },
    [api],
  );

  return useLoading(getWorkflow);
};
