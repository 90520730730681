/* eslint-disable max-lines */

import styled from 'styled-components';
import { Colors, Fonts, transitionStyle } from '@whispir/ui-lib-v2';

export const NodeWidth = '200px';
export const IconSize = '48px';

export const WithEllipsis = `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const WithError = `
  &:after {
    content: '';
    height: 8px;
    width: 8px;
    border-radius: 50%;
    display: inline-block;
    background-color: ${Colors.error};
    margin-left: 8px;
  }
`;

export const NodeWrapper = styled.div`
  button {
    width: ${NodeWidth};
    padding: 8px;
    cursor: pointer;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: transparent;

    .group-icon {
      display: flex;
      margin-left: 8px;
    }

    .single-icon {
      margin-left: 8px;
    }

    &:hover {
      .label,
      .state {
        color: ${Colors.grey_blue_5};
      }
      .icon-shadow {
        box-shadow: 0 0 0 4px ${Colors.accent_2};
      }
    }

    &.active,
    &:focus {
      .label,
      .state {
        color: ${Colors.grey_blue_5};
      }
      .icon-shadow {
        box-shadow: 0 0 0 4px ${Colors.accent_4};
      }
      .condition,
      .comment {
        color: ${Colors.grey_blue_5};
        border: solid 4px ${Colors.accent_4};
      }

      .expression {
        .expression-label,
        .and-operand-wrapper {
          background-color: ${Colors.accent_2};
        }
      }
    }

    .icon-shadow {
      border-radius: 50%;
      margin-left: calc(-${IconSize} * 0.15);
      transition: box-shadow ${transitionStyle};
    }

    .icon-pos-send {
      margin-left: calc(-${IconSize} * 0);
    }

    .label {
      ${Fonts.subheadSmall};
      margin: 4px 0 2px 0;
      font-weight: bold;
      max-width: ${NodeWidth};
      transition: color ${transitionStyle};
      ${WithEllipsis}
    }

    .state {
      ${Fonts.body};
      padding: 0 16px;
      max-width: ${NodeWidth};
      transition: color ${transitionStyle};
      ${WithEllipsis}

      &.dirty {
        color: ${Colors.accent};
      }

      &.error {
        ${WithError}
        color: ${Colors.error};
      }
    }

    .condition {
      width: auto;
      height: auto;
      color: ${Colors.grey_blue_5};
      ${Fonts.body}
      display: inline-block;
      margin: 0 16px;
      border-radius: 24px;
      border: solid 4px transparent;
      transition: color ${transitionStyle}, border-color ${transitionStyle};

      &:hover {
        color: ${Colors.grey_blue_5};
        border-color: ${Colors.accent_2};
      }

      .content {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${Colors.white};
        padding: 8px 16px;
        border-radius: 24px;
        max-width: calc(${NodeWidth} - 22);
        ${Fonts.bodySmall};
        ${WithEllipsis}

        &.error {
          ${WithError}

          &:after {
            position: relative;
            top: 2px;
          }
        }
      }

      svg {
        fill: ${Colors.grey_blue_4};
        width: 15px;
        height: 15px;
        margin-right: 8px;
      }
    }

    .comment {
      width: 244px;
      height: auto;
      color: ${Colors.grey_blue_5};
      ${Fonts.body}
      margin: 0 16px;
      border-radius: 8px;
      border: solid 4px transparent;
      transition: color ${transitionStyle}, border-color ${transitionStyle};

      &:hover {
        color: ${Colors.grey_blue_5};
        border-color: ${Colors.accent_2};
      }

      .content {
        display: flex;
        justify-content: center;
        overflow: hidden;
        background-color: ${Colors.white};
        padding: 8px 16px;
        border-radius: 8px;
        line-height: 18px;
      }
    }

    .expression {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 244px;
      height: auto;
      margin: 8px 16px 0;
      ${Fonts.link};
      font-weight: normal;
      font-size: 14px;
      transition: color ${transitionStyle}, background-color ${transitionStyle};

      .icon-wrapper {
        height: 18px;
        position: absolute;
        top: -2px;
        left: calc(50% - 6px);

        &.if-icon {
          z-index: 9;
        }

        &.if-icon-nested {
          top: -10px;
        }
      }

      .expression-label {
        border-radius: 8px;
        background-color: ${Colors.accent_1};
        color: ${Colors.voice};
        padding: 16px;
        z-index: 0;
        margin: 8px;
        transition: color ${transitionStyle},
          background-color ${transitionStyle};

        &.default {
          color: ${Colors.accent};
          font-size: 16px;
        }

        .variable-name {
          display: block;
          background-color: ${Colors.accent_light};
          border-radius: 12px;
          padding: 0 16px;
          font-weight: 500;
        }

        .constant-name {
          display: block;
          color: ${Colors.grey_blue_5};
          padding-top: 4px;
        }
      }

      .expression-clause {
        color: ${Colors.voice};
        text-transform: uppercase;
        ${Fonts.body}
        font-weight: 900;
        padding-top: 8px;
        margin-top: -10px;
      }

      .operator-title {
        color: ${Colors.voice};
        text-transform: uppercase;
        ${Fonts.body}
        font-weight: 900;
        letter-spacing: -0.01em;
        margin-left: -10px;

        &.or-operator-title {
          padding: 0 4px;
          background-color: ${Colors.white};
          border: 2px solid ${Colors.voice};
          border-radius: 12px;
        }
      }

      &:hover {
        .expression-label {
          background-color: ${Colors.accent_2};
        }
      }

      svg {
        height: 100%;
        fill: ${Colors.white};
        background: ${Colors.voice};
        border-radius: 12px;
        padding: 1px 4px;
        z-index: 9;
        margin: 0px 0 0 -10px;
      }

      .or-operand-wrapper,
      .and-operand-wrapper {
        .fulfilled-expression {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: auto;
          margin-top: 10px;

          &:first-child {
            margin-top: 0;
          }
        }
      }

      .and-operand-wrapper {
        background-color: ${Colors.accent_1};
        border-radius: 8px;
        padding: 0;
        margin-bottom: 18px;
        transition: color ${transitionStyle},
          background-color ${transitionStyle};

        .fulfilled-expression {
          margin-top: 0;

          .icon-wrapper {
            top: -10px;
          }
        }

        .expression-label {
          background-color: transparent;
          margin-bottom: 0;
          padding-left: 8px;
          padding-right: 8px;
        }
      }

      .or-operand-wrapper {
        &:hover {
          .and-operand-wrapper {
            background-color: ${Colors.accent_2};
          }
        }
      }

      .and-operand-wrapper {
        &:hover {
          background-color: ${Colors.accent_2};
        }
      }
    }
  }

  &.is-type-start {
    button {
      padding: 0;
      cursor: default;
    }
  }

  &.is-type-empty {
    height: 0;
  }
`;
