import React, { useContext, Fragment } from 'react';

import { Chip, createExpressionLabel, Icons } from '@whispir/ui-lib-v2';
import { defaultExpression } from '@whispir/workflow-data-model';
import { WorkflowContext } from '../../../../state';
import { ExpressionText } from './Expression.style';

const Expression = ({ node, isValid }) => {
  const { expression = defaultExpression() } = node.data;
  const { operands } = expression;
  const isExpressionFulfilled = expression && expression.operator !== null;
  const hasAndOperand = Boolean(expression.operator === 'and');
  const hasNestedAndOperand = Boolean(
    operands && operands[0].operator === 'and',
  );

  const ifIconWrapper = () => (
    <div
      className={`
      icon-wrapper if-icon ${
        hasAndOperand || hasNestedAndOperand ? 'if-icon-nested' : ''
      }
    `}
    >
      <Icons.If />
    </div>
  );

  const renderEmptyStep = () => (
    <div className="expression-label default">
      {ifIconWrapper()}
      <ExpressionText
        data-text="default-label"
        className={isValid ? '' : 'error'}
      >
        Set Condition
      </ExpressionText>
    </div>
  );

  return (
    <div className="expression">
      {isExpressionFulfilled ? (
        <Fragment>
          {ifIconWrapper()}
          <FulfilledExpression expression={expression} stepId={node.id} />
        </Fragment>
      ) : (
        renderEmptyStep()
      )}
    </div>
  );
};

export const FulfilledExpression = ({
  expression,
  parentOperand,
  index,
  stepId,
}) => {
  const [ctx] = useContext(WorkflowContext);
  const { getWorkflowVariableNameById } = ctx.workflow;
  const { operator, operands } = expression;
  const hasOperands = Boolean(operands && operands.length);

  const renderOperatorLabel = (operator) => (
    <span className={`operator-title ${operator}-operator-title`}>
      {operator}
    </span>
  );

  const renderExpression = (expression, parentOperand) => {
    const { left: { variableId } = {}, right } = expression;
    const isExpressionFulfilled = expression && expression.operator !== null;
    const expressionLabel = createExpressionLabel({ operator, operand: right });
    const variableName = getWorkflowVariableNameById(variableId, stepId);

    return (
      <div
        className={`expression-label ${isExpressionFulfilled ? '' : 'default'}`}
      >
        <div className="icon-wrapper">
          {parentOperand === '' || parentOperand === 'or' || index === 0
            ? renderOperatorLabel('or')
            : renderOperatorLabel('and')}
        </div>
        {isExpressionFulfilled ? (
          <Fragment>
            <Chip
              text={variableName}
              color="variable"
              tooltipPlacement="left"
            />
            <span className="constant-name">{expressionLabel}</span>
          </Fragment>
        ) : (
          <span data-text="default-label">Set Condition</span>
        )}
      </div>
    );
  };

  return (
    <Fragment>
      <div
        className={`fulfilled-expression
        ${
          operator === 'or'
            ? 'or-operand-wrapper'
            : operator === 'and'
            ? 'and-operand-wrapper'
            : ''
        }
      `}
      >
        {hasOperands && operands
          ? operands.map((childExp, index) => (
              <FulfilledExpression
                key={childExp.id}
                expression={childExp}
                parentOperand={operator}
                index={index}
                stepId={stepId}
              />
            ))
          : renderExpression(expression, parentOperand)}
      </div>
      {parentOperand === '' && <span className="expression-clause">then</span>}
    </Fragment>
  );
};

Expression.defaultProps = {
  node: {
    id: 0,
    data: {
      expression: defaultExpression(),
    },
  },
};

FulfilledExpression.defaultProps = {
  node: {
    id: 0,
    data: {
      expression: defaultExpression(),
    },
  },
  parentOperand: null,
  index: 0,
};

export default Expression;
