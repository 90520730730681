//
import { GET_NOTIFICATIONS_LOCAL } from '../../queries/workflow-queries';

const createNotification = (_, { notification }, { cache }) => {
  const { notifications } = cache.readQuery({ query: GET_NOTIFICATIONS_LOCAL });

  const data = {
    notifications: [
      ...notifications,
      {
        __typename: 'Notification',
        id: Date.now(),
        ...notification,
      },
    ],
  };

  cache.writeQuery({ query: GET_NOTIFICATIONS_LOCAL, data });
  return null;
};

export default createNotification;
