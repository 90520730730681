//
import React, { Fragment, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { prefix } from '../../../router/routerConstants';
import { withSideDrawerContext } from '@whispir/ui-lib-v2';
import {
  ON_WEBFORM_REPLY_STATIC_VARIABLES,
  extractComponentContentVariables,
  getComponents,
} from '@whispir/workflow-data-model';
import { EmbeddedChannelModal } from '../../EmbeddedChannelModal';

import { WorkflowContext, UserContext } from '../../../state';
import { useForceUpdate } from '../../../state/utils';
import NodeFactory from './NodeFactory';
import { NodeWrapper } from './Node.style';
import { updateChannelSubject, updateChannelBody } from './utils';

export const Node = ({ node, nodeIndex, drawerContext, match, history }) => {
  /**
   * forceUpdate updates state to force a re-render.
   * This is required because we are mutating the context, and React does not detect the changes.
   *  */
  const forceUpdate = useForceUpdate();
  const [userContext] = useContext(UserContext);
  const [workflowContext, setWorkflowContext] = useContext(WorkflowContext);
  const nodeFactory = new NodeFactory();
  const [isConfigDisplayed, setIsConfigDisplayed] = useState(false);

  const showNodeConfiguration = (event) => {
    const { setContent } = drawerContext;

    const nodeConfigurationContent = nodeFactory.createPopoverContent({
      node,
      nodeIndex,
      workflowContext,
      setWorkflowContext,
      userContext,
      onCloseRequest: hideNodeConfiguration,
      onUnmount: handleUnmount,
    });

    if (!nodeConfigurationContent) {
      return;
    }

    setIsConfigDisplayed(true);

    if (!setContent) {
      throw new Error(
        'side-drawer feature toggle is emabled, but we were not passed a setContent function (the function for updating the SideDrawer context)',
      );
    }
    setContent(nodeConfigurationContent);
  };

  const setActiveChannel = (channelType) => {
    setWorkflowContext({ ...workflowContext, activeChannel: channelType });
  };

  const handleUnmount = () => {
    // This thing is neccessary,
    // As due to all sorts of weirdness that happens with mutating the context
    // The node labels aren't responding to context changes, until next click
    // Even though the render methods are firing, and displaying that the data is changing.
    setIsConfigDisplayed(false);
  };

  const hideNodeConfiguration = () => {
    const { setContent } = drawerContext;
    setContent(null);
  };

  // suppress panning when clicking the node
  const onPress = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const isChannelModalOpened = () => {
    const channelTypes = ['sms', 'email', 'web', 'webform'];
    const {
      params: { stepId, modalType },
    } = match;

    return Boolean(
      stepId &&
        stepId === node.id.toString() &&
        modalType &&
        channelTypes.includes(modalType),
    );
  };

  const onModalClose = () => {
    const { push } = history;
    const {
      params: { flowId },
    } = match;
    setActiveChannel(null);
    push(`${prefix}/${flowId}`);
  };

  // This function would only be called whenever there is a node update while content-builder is toggled OFF
  const updateChannel = (channelType, value) => {
    const { workflow } = workflowContext;
    const { id: nodeId, data: nodeData } = node;

    const updatedChannelData = updateChannelBody({
      channelType,
      value,
      nodeData,
    });

    workflow.updateNodeData(nodeId, updatedChannelData);

    if (channelType === 'webform') {
      // extracting outputVariables from form components
      const outputVariablesFromFormComponents = extractComponentContentVariables(
        {
          components: getComponents({ channelType, nodeData }),
        },
      );

      const [onReplyNode] = node.next;
      // `outputVariablesFromFormComponents` always returns a complete set of variables that need to be added
      const updatedVariables = [
        // eslint-disable-next-line new-cap
        ...ON_WEBFORM_REPLY_STATIC_VARIABLES(node.id),
        ...outputVariablesFromFormComponents,
      ];

      workflow.replaceOutputVariables(onReplyNode.id, updatedVariables);
    }

    forceUpdate();
  };

  const updateSubject = (channelType, subject) => {
    const { workflow } = workflowContext;
    const { id: nodeId, data: nodeData } = node;

    const updatedChannelData = updateChannelSubject({
      channelType,
      subject,
      nodeData,
    });

    workflow.updateNodeData(nodeId, updatedChannelData);
    forceUpdate();
  };

  const { id, data } = node;
  const isDisabled = node.type === 'empty';
  const { workflow } = workflowContext;

  // by default, will retrieve variables from root node
  const rootWorkflowVariables = workflow.getWorkflowVariablesByStepId(id);

  return (
    <Fragment>
      <NodeWrapper className={`is-type-${node.type}`}>
        <button
          className={isConfigDisplayed ? 'active' : ''}
          type="button"
          disabled={isDisabled}
          onMouseDown={onPress}
          onClick={showNodeConfiguration}
          data-button="node"
        >
          {nodeFactory.createNode({
            node,
            isValid: workflow && !workflow.hasErrorForNode(id),
          })}
        </button>
      </NodeWrapper>
      {isChannelModalOpened() && (
        <EmbeddedChannelModal
          node={node}
          defaultTitle={workflow.title}
          passedDownWorkflowVariables={rootWorkflowVariables}
          onClose={onModalClose}
          nodeData={data}
          nodeId={id}
          onUpdateChannelObj={updateChannel}
          onUpdateSubject={updateSubject}
        />
      )}
    </Fragment>
  );
};

const ConnectedNodeWithRouter = withRouter(withSideDrawerContext(Node));
// The contextType is declared after the withRouter defaut export to avoid React
// complaining about function components not supporting contextType.

export default ConnectedNodeWithRouter;
