import React from 'react';
import styled from 'styled-components';
import Popover from '@material-ui/core/Popover';
import { Colors, Fonts, Elevations } from '@whispir/ui-lib-v2';
import { SIDE_DRAWER_WIDTH } from '@whispir/ui-lib-v2/lib/Foundation/Spacing';

const Spacing = '24px';

export const StyledPopover = styled(({ paper, ...other }) => (
  <Popover classes={{ paper: 'popover-no-tabindex' }} {...other} />
))`
  .popover-no-tabindex {
    min-width: ${SIDE_DRAWER_WIDTH};
    padding: 48px 24px 32px;
    border-radius: 8px;
    margin: ${(props) => props.popoverposition};
    font-family: ${Fonts.fontFamily};
    color: ${Colors.grey_blue_5};
    box-shadow: ${Elevations.elevation_1};
    overflow: auto;
  }

  .close {
    z-index: 2;
    position: absolute;
    right: calc(${Spacing} - 2px);
    top: calc(${Spacing} - 2px);
    border: 0;
    background: none;
    padding: 0;
    cursor: pointer;
    outline: none;

    svg {
      fill: ${Colors.grey_blue_4};
      width: 24px;
      height: 24px;

      &:hover {
        fill: ${Colors.accent};
      }
    }
  }
`;
