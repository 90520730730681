import React, { useState, useContext, useEffect } from 'react';
import { graphql, withApollo } from 'react-apollo';
import { compose } from 'recompose';
import {
  HomepageBanner,
  useSegmentContext,
  QueryDataTable,
} from '@whispir/ui-lib-v2';
import { Typography } from '@material-ui/core';
import { CreateNotification } from '../../components/Notification';
import { UserContext } from '../../state';
import { STRIPE_CUSTOMER } from '../../apollo/queries/workflow-queries';
import LightboxPanel from '../../components/LightboxPanel/LightboxPanel';
import {
  sortRows,
  createWorkflowsTableHeader,
  createWorkflowRows,
  getWorkflowListWithStatus,
  sortWorkflowsByDate,
  WORKFLOW_STATUS_ACTIVE,
  WORKFLOW_STATUS_DRAFT,
  WORKFLOW_STATUS_ARCHIVED,
} from '../../utils/dashboardUtils';
import {
  HomepageBody,
  ContentWrapper,
  WorkflowTableWrapper,
} from './Dashboard.style';
import { ReactComponent as BackgroundImageSource } from './assets/workflowBannerImage.svg';
import { useWorkflowApi } from '../../hooks/useWorkflowApi';

const defaultNotificationState = {
  success: false,
  message: null,
};

const defaultActionState = {
  isActionModalOpen: false,
  title: null,
  body: null,
  handleActionConfirmation: () => {},
};

const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE_NUMBER = 0;

export const Dashboard = ({ history, match, client, stripeCustomer }) => {
  const {
    deactivateWorkflow,
    getTriggeredWorkflows,
    isLoading,
    getWorkflows,
    getWorkflow,
    deleteWorkflow,
  } = useWorkflowApi();

  // "workflowsList" acts as a main vault that caches all of the workflows data that have been fetched.
  const [workflowsList, setWorkflowsList] = useState([]);
  // "rowsToDisplay" refers to a set of workflows that will be displayed one page a time
  const [rowsToDisplay, setRowsToDisplay] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  // "workflowTriggersList" stores the history of workflows executions (or triggers)
  //  which helps to determine the status of each workflow
  const [workflowTriggersList, setWorkflowTriggersList] = useState([]);
  // Both "pageNumber" and "pageSize" are essential for querying specific subsets of workflows
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [userContext] = useContext(UserContext);
  const [notificationState, setNotificationState] = useState(
    defaultNotificationState,
  );
  const [actionState, setActionState] = useState(defaultActionState);

  const { trackEvent } = useSegmentContext();
  const {
    user: { rawUserId },
  } = userContext;

  useEffect(() => {
    const getWorkflowsByWorkspace = async () => {
      const getWorkflowsResult = await getWorkflows({ pageSize });
      setWorkflowsList(getWorkflowsResult.data.getPaginatedUserWorkflows.data);
      setTotalCount(
        getWorkflowsResult.data.getPaginatedUserWorkflows.totalCount,
      );
      setRowsToDisplay(
        // by default, we're sorting the workflows by the date it was created on initial render
        sortWorkflowsByDate({
          workflowsList: getWorkflowsResult.data.getPaginatedUserWorkflows.data,
        }),
      );
    };

    const getTriggeredWorkflowsByWorkspace = async () => {
      const getTriggeredWorkflowsResult = await getTriggeredWorkflows();
      setWorkflowTriggersList(
        getTriggeredWorkflowsResult.data.getTriggeredWorkflows,
      );
    };

    getTriggeredWorkflowsByWorkspace();
    getWorkflowsByWorkspace();
    // onMountOnly
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetNotificationState = () => {
    setNotificationState(defaultNotificationState);
  };

  const resetActionState = () => {
    setActionState(defaultActionState);
  };

  const createNotification = (message, type = true) => {
    setNotificationState({
      success: type,
      message,
    });

    // Remove notification from state after render
    setTimeout(() => {
      resetNotificationState();
    });
  };

  const createConfirmationModal = ({ title, body, onConfirm }) => {
    setActionState({
      isActionModalOpen: true,
      title,
      body,
      handleActionConfirmation: () => {
        onConfirm();
        resetActionState();
      },
    });
  };

  /**
   * Table Actions (e.g. handleDeletion, handleDeactivation, handleEdit, handleOnTableViewChange)
   */

  const handleDeleteSelection = (row) => {
    createConfirmationModal({
      title: 'Delete this Workflow?',
      onConfirm: () => {
        handleDelete(row);
      },
      body: (
        <p>
          Are you sure you want to delete this workflow?{' '}
          <strong>This action can’t be undone.</strong> Do you want to continue?
        </p>
      ),
    });
  };

  const handleDelete = async (row) => {
    const { id: deletedWfId, name } = row;
    const offset = pageNumber * pageSize;
    const { name: wfName } = name;

    try {
      await deleteWorkflow({ id: deletedWfId });

      // Removing deleted workflow from the "worklfowsList"
      const updatedWorkflowsList = workflowsList.filter(
        ({ id }) => id !== deletedWfId,
      );

      // "estimatedRecordsFetched" determines the number of workflows that have already been fetched in respect to the current values of pageSize and pageNumber
      const estimatedRecordsFetched = offset + pageSize - 1;

      // If the value of "estimatedRecordsFetched" equals the value of the length of the "workflowsList"
      // this means, the user has not visited the next page yet, so we need to perform a fetch from the server
      if (estimatedRecordsFetched === updatedWorkflowsList.length) {
        const {
          data: {
            getPaginatedUserWorkflows: { data: refetchedWorkflows, totalCount },
          },
        } = await getWorkflows({
          pageSize,
          offset,
        });

        // here we are updating the temporal "workflowsList" with the newly fetched values
        updatedWorkflowsList.splice(
          offset,
          refetchedWorkflows.length,
          ...refetchedWorkflows,
        );

        setTotalCount(totalCount);
      }

      // calling "getWorkflowListWithStatus" to ensure we get to deduce the "Status" of the workflow
      const rowsToBeDisplayed = updatedWorkflowsList.slice(
        offset,
        offset + pageSize,
      );

      setRowsToDisplay(rowsToBeDisplayed);
      setWorkflowsList(updatedWorkflowsList);
      setTotalCount(totalCount - 1);

      createNotification(`Workflow deleted: ${wfName}`);
      trackEvent({
        event: 'Workflow Deleted',
        userId: rawUserId,
        properties: {
          workflowId: deletedWfId,
        },
      });
    } catch (e) {
      const isSuccess = false;
      console.error(e);
      createNotification(`Failed to delete workflow: ${name}`, isSuccess);
    }
  };

  const handleDeactivate = async (id) => {
    await deactivateWorkflow({ id });

    // refetching data
    const {
      data: { getWorkflow: archivedWorkflow },
    } = await getWorkflow({ workflowId: id });

    // Updating the workflow list
    const newWorkflowsList = workflowsList.map((workflow) => {
      if (workflow.id === archivedWorkflow.id) {
        return archivedWorkflow;
      }
      return workflow;
    });

    setWorkflowsList(newWorkflowsList);
    const refetchedWorkflowTriggers = await getTriggeredWorkflows();

    setWorkflowTriggersList(
      refetchedWorkflowTriggers.data.getTriggeredWorkflows,
    );

    createNotification(`Workflow archived: ${archivedWorkflow.name}`);
    trackEvent({
      event: 'Workflow Deactivated',
      userId: rawUserId,
      properties: {
        workflowId: id,
      },
    });
  };

  const handleEdit = (row) => {
    if (row.status !== WORKFLOW_STATUS_ARCHIVED) {
      history.push(`${match.path}/${row.id}`);
    }
  };

  const handleCancel = () => {
    resetActionState();
  };

  const handleDeactivateSelection = (row) => {
    const { id } = row;
    createConfirmationModal({
      title: 'Archive this Workflow?',
      onConfirm: () => {
        handleDeactivate(id);
      },
      body: (
        <p>
          Archiving this Workflow will deactivate any current executions and
          disable its trigger form.{' '}
          <strong>This action can’t be undone.</strong> Do you want to continue?
        </p>
      ),
    });
  };

  const handleOnTableViewChange = async (changeOptions) => {
    const { rowsPerPage, page, sortAscending, columnToSort } = changeOptions;
    const pageSize = rowsPerPage;
    const offset = page * pageSize;

    setPageSize(pageSize);
    setPageNumber(page);

    const sortedRows = sortRows({
      rowsData: workflowsList.slice(offset, offset + pageSize),
      field: columnToSort,
      isAscending: sortAscending,
    });

    // this is when we already have fetched the workflows to be displayed
    setRowsToDisplay(sortedRows);

    // only fetch more workflows when the current total number of workflows fetched is less than the offset values
    if (workflowsList.length <= offset && offset < totalCount) {
      const refetchedWorfklowsData = await getWorkflows({
        pageSize,
        offset,
      });

      const updatedWorkflowsList = [
        ...workflowsList,
        ...refetchedWorfklowsData.data.getPaginatedUserWorkflows.data,
      ];

      setRowsToDisplay(updatedWorkflowsList.slice(offset, offset + pageSize));
      setWorkflowsList(updatedWorkflowsList);
    }
  };

  return (
    <>
      {notificationState.message && (
        <CreateNotification
          notification={{
            type: notificationState.success ? 'success' : 'error',
            message: notificationState.message,
          }}
        />
      )}
      {actionState.isActionModalOpen && (
        <LightboxPanel
          confirm="Confirm"
          title={actionState.title}
          onCancel={handleCancel}
          onConfirm={actionState.handleActionConfirmation}
        >
          {actionState.body}
        </LightboxPanel>
      )}
      <ContentWrapper>
        <HomepageBanner
          title="Workflows"
          bodyText="Use our intuitive builder to automate your communications and manual business processes in a few clicks."
          buttonText="Create Workflow"
          image={<BackgroundImageSource />}
          baseUrl={`${match.path}/templates`}
          hasRouterLink
        />
        <HomepageBody isLoading={isLoading}>
          <Typography className="homepage-body-header">My Workflows</Typography>
          <WorkflowTableWrapper>
            <QueryDataTable
              rows={createWorkflowRows(
                getWorkflowListWithStatus(workflowTriggersList, rowsToDisplay),
              )}
              columns={createWorkflowsTableHeader({ createNotification })}
              isLoading={isLoading}
              totalRowsInAllPages={totalCount}
              selectMode="single"
              rowActions={[
                {
                  label: 'Edit',
                  action: handleEdit,
                  check: (rowItem) =>
                    rowItem.status !== WORKFLOW_STATUS_ARCHIVED,
                },
                {
                  label: 'Archive',
                  action: handleDeactivateSelection,
                  check: (rowItem) => rowItem.status === WORKFLOW_STATUS_ACTIVE,
                },
                {
                  label: 'Delete',
                  action: handleDeleteSelection,
                  check: (rowItem) => rowItem.status === WORKFLOW_STATUS_DRAFT,
                },
              ]}
              onTableViewChange={handleOnTableViewChange}
              onRowClick={handleEdit}
              rowsPerPageOptions={[5, 10, 15]}
              page={DEFAULT_PAGE_NUMBER}
              rowsPerPage={DEFAULT_PAGE_SIZE}
              disableRow={(rowData) =>
                rowData.status === WORKFLOW_STATUS_ARCHIVED
              }
            />
          </WorkflowTableWrapper>
        </HomepageBody>
      </ContentWrapper>
    </>
  );
};

// TODO: Move stripe portal query to Layout once Apollo upgraded
export default withApollo(
  compose(
    graphql(STRIPE_CUSTOMER, {
      name: 'stripeCustomer',
      options: () => ({
        variables: {
          // returnUrl: `${window.location.origin}/signin/dashboard`,
        },
        fetchPolicy: 'network-only',
      }),
      skip: () => true,
    }),
  )(Dashboard),
);
