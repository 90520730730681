import styled from 'styled-components';
import { Colors } from '@whispir/ui-lib-v2';
import { headerHeight } from '../../components/Header/Header.style';

const sceneSize = '15000px';
const verticalCenterPoint = `calc(-${sceneSize} / 2)`;
const horizontalCenterPoint = `calc((-${sceneSize} / 2) + 50%)`;

export const OuterWrapper = styled.div`
  height: calc(100% - ${headerHeight});
  position: relative;
  flex: 1 1 auto;
  overflow: hidden;
`;

export const InnerWrapper = styled.div`
  cursor: grab;
  position: absolute;
  top: ${verticalCenterPoint};
  left: ${horizontalCenterPoint};
  min-width: ${sceneSize};
  min-height: ${sceneSize};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  ${
    '' /* this colour #d8d9f8 is a once off use for
the dotted background and therefore will remain hardcoded */
  }
  background: linear-gradient(90deg, ${
    Colors.background
  } 24px, transparent 0%) center,
    linear-gradient(${Colors.background} 24px, #d8d9f8 0%) center,
    ${Colors.accent_2};
  background-size: 27px 27px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(0, 50%);
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const PageWrapper = styled.div`
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  animation-name: fade-in;
  animation-fill-mode: both;
  animation-duration: 0.5s;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;

  .side-drawer .MuiDrawer-paper {
    min-width: 504px;
  }
`;
