/**
 * This function is used to sort an array of items into multiple subarrays
 *  - see the tests
 * @param {*} array
 * @param {*} validityFunctions
 */

export const partition = (array, validityFunctions) => {
  const results = new Array(validityFunctions.length).fill(true).map((v) => []);
  array.forEach((value) => {
    validityFunctions.forEach((fn, i) => {
      if (fn(value)) {
        results[i].push(value);
      }
    });
  });
  return results;
};
