//
// $FlowFixMe
import React, { useContext } from 'react';
// $FlowFixMe

import { IconButton } from '@whispir/ui-lib-v2';
import ExpressionInput from '../../../ExpressionInput/ExpressionInput';
import { WorkflowContext } from '../../../../state';

import {
  ExpressionItemWrapper,
  ConditionTitle,
  ExpressionMoleculeWrapper,
} from './Expression.style';

const ExpressionItem = ({
  index,
  isDeletable,
  expression,
  handleDelete,
  handleUpdate,
  nodeId,
}) => {
  const [ctx] = useContext(WorkflowContext);
  const { getWorkflowVariableGroupsByStepId } = ctx.workflow;

  return (
    <ExpressionItemWrapper>
      <div className="expression-item-header">
        <ConditionTitle className="prefix">
          {index === 0 ? 'if' : 'and'}
        </ConditionTitle>
        {isDeletable && (
          <IconButton icon="Delete" size="small" onClick={handleDelete} />
        )}
      </div>

      <ExpressionMoleculeWrapper>
        <ExpressionInput
          expressionValue={expression}
          onChange={handleUpdate}
          variables={getWorkflowVariableGroupsByStepId(nodeId, 'expression')}
        />
      </ExpressionMoleculeWrapper>
    </ExpressionItemWrapper>
  );
};

ExpressionItem.defaultProps = {
  index: null,
  isDeletable: true,
};

export default React.memo(ExpressionItem);
