//
// $FlowFixMe
import React, { Fragment, useState, useContext } from 'react';
import {
  Modal,
  Illustrations,
  Icons,
  Button,
  useSegmentContext,
} from '@whispir/ui-lib-v2';
import { Title } from '../../PropertyContent/PropertyContent.style';
import { WorkflowContext, UserContext } from '../../../state';
import PopoverPanel from '../../PopoverPanel';
import {
  SplitWrapper,
  TextBody,
  TextHeading,
  Headline,
  SvgWrapper,
  ModalActionsWrapper,
  LinkStyles,
  PopoverPanelcontent,
  TopWrapper,
  BodyWrapper,
} from './Split.style';

const Split = ({ previousNode }) => {
  const [workflowContext, setWorkflowContext] = useContext(WorkflowContext);
  const { workflow } = workflowContext;
  const [userContext] = useContext(UserContext);
  const { trackEvent } = useSegmentContext();
  const {
    user: { rawUserId },
  } = userContext;

  const [state, setState] = useState({
    modalProps: {
      headerContent: null,
      bodyContent: null,
      footerContent: null,
      handleExit: null,
      isOpen: false,
    },
    domElement: null,
  });

  const addNewBranch = () => {
    const {
      workflow: { addBranch },
    } = workflowContext;
    addBranch(previousNode);
    setWorkflowContext({ ...workflowContext });
    trackEvent({
      event: 'Branch Added',
      userId: rawUserId,
      properties: {
        workflowId: workflow.id,
      },
    });
  };

  const renderModalHeader = (
    <SvgWrapper>
      <Illustrations.Delete />
    </SvgWrapper>
  );

  const renderModalBody = (
    <BodyWrapper>
      <TextHeading>Delete Split?</TextHeading>
      <TextBody>
        All branches and content will be removed. This action cannot be undone.
      </TextBody>
    </BodyWrapper>
  );

  const renderModalFooter = () => (
    <ModalActionsWrapper>
      <Button
        underline={false}
        onClick={onClose}
        aria-label="Cancel"
        variant="link"
        type="secondary"
      >
        Cancel
      </Button>

      <Button data-button="confirm" aria-label="Confirm" onClick={removeSplit}>
        Confirm
      </Button>
    </ModalActionsWrapper>
  );

  const onClose = () => {
    setState({
      modalProps: {
        headerContent: null,
        bodyContent: null,
        footerContent: null,
        handleExit: null,
        isOpen: false,
      },
    });
  };

  const triggerDialogBox = () => {
    setState({
      modalProps: {
        headerContent: renderModalHeader,
        bodyContent: renderModalBody,
        footerContent: renderModalFooter(),
        handleExit: onClose,
        isOpen: true,
      },
    });
  };

  const removeSplit = () => {
    const {
      workflow,
      workflow: { deleteSplit },
    } = workflowContext;
    deleteSplit(previousNode.id);
    trackEvent({
      event: 'Step Deleted',
      userId: rawUserId,
      properties: {
        workflowId: workflow.id,
        stepAction: 'split',
      },
    });
    setWorkflowContext({ ...workflowContext });
    onClose();
    closePopoverPanel();
  };

  const openPopoverPanel = (event) => {
    setState({ domElement: event.currentTarget });
  };

  const closePopoverPanel = () => {
    setState({ domElement: null });
  };

  const renderPopoverPanel = () => {
    const { domElement } = state;
    return (
      <PopoverPanel
        popoverposition="split"
        domElement={domElement}
        handleClose={closePopoverPanel}
      >
        <Title>Split</Title>
        <PopoverPanelcontent>
          <p>Divide this path into 2 or more branches.</p>
          <Button
            customStyle={LinkStyles}
            underline={false}
            onClick={triggerDialogBox}
            variant="link"
            aria-label="Delete"
          >
            Delete
          </Button>
        </PopoverPanelcontent>
      </PopoverPanel>
    );
  };

  const { modalProps, domElement } = state;
  return (
    <Fragment>
      <SplitWrapper>
        <TopWrapper onClick={openPopoverPanel}>
          <div className="icon-wrapper">
            <Icons.BranchContainer className="svg-container" />
            <Icons.BranchDiamond />
          </div>
          <Headline>Split</Headline>
        </TopWrapper>
        <button onClick={addNewBranch} type="button" data-button="add-branch">
          + Add a Branch
        </button>
        <Modal {...modalProps} />
      </SplitWrapper>
      {domElement ? renderPopoverPanel() : null}
    </Fragment>
  );
};

export default Split;
