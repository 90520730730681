import styled from 'styled-components';
import { Colors, Fonts, Elevations, Depth } from '@whispir/ui-lib-v2';

export const headerHeight = '88px';

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${Colors.white};
  box-shadow: ${Elevations.elevation_1};
  height: ${headerHeight};
  color: ${Colors.grey_blue_5};
  z-index: ${Depth.header};
`;

export const RightSideMenuWrapper = styled.div`
  display: flex;
  align-items: center;

  .button-list {
    margin-right: 16px;
  }
`;

export const TitleWrapper = styled.div`
  padding-left: 32px;
  .edit-icon {
    :hover {
      cursor: pointer;
      :after {
        content: '';
        position: absolute;
        width: 48px;
        height: 48px;
        right: 11px;
        border-radius: 50%;
        background-color: ${Colors.grey_blue_1};
      }
    }
  }

  fieldset {
    border: none;
  }

  #workflow-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    ${Fonts.headline};
    :focus {
      + .edit-icon {
        display: none;
      }
    }
  }

  svg {
    margin-left: 16px;
    fill: ${Colors.grey_blue_4};
  }

  #click-focus {
    pointer-events: none;
    opacity: 0;
    user-select: none;
    position: fixed;
    z-index: 2;
    width: 100vw;
    height: 100vh;
    top: 88px;
    left: 0;
    :hover {
      cursor: grab;
    }
  }
`;

export const CloseLinkWrapper = styled.div`
  padding: 0 32px 0 0;
  text-align: right;

  button {
    display: flex;
    align-items: center;
    ${Fonts.bodyLarge};
    text-decoration: none;
    border: none;
    background: none;
    cursor: pointer;

    svg {
      fill: ${Colors.grey_blue_4};
      width: 24px;
      height: 24px;

      &:hover {
        fill: ${Colors.accent};
      }
    }
  }
`;

export const ModalBodyWrapper = styled.div`
  .copylinkbox-wrapper {
    margin: 0 auto;
  }

  .message-body {
    margin-top: 0;
    margin-bottom: 24px;
  }
`;
