//
import React, { Fragment, useState, useContext } from 'react';
import { Button, useSegmentContext } from '@whispir/ui-lib-v2';
import { WorkflowContext, UserContext } from '../../state';
import { Title, ButtonWrapper } from '../PropertyContent/PropertyContent.style';
import PopoverPanel from '../PopoverPanel';
import { PopoverPanelcontent } from './RemoveBranchButton.style';
import { ReactComponent as Delete } from './assets/Delete.svg';

const RemoveBranchButton = ({ step, index = -1 }) => {
  const [workflowContext] = useContext(WorkflowContext);
  const { setActiveNodes } = workflowContext;
  const [userContext] = useContext(UserContext);
  const { trackEvent } = useSegmentContext();
  const {
    user: { rawUserId },
  } = userContext;

  const [state, setState] = useState({ domElement: null });

  const openPopoverPanel = (event) => {
    const highlightedBranches = [step.id];
    const deletingSplit = index === -1;

    if (deletingSplit) {
      step.next.forEach((childStep) => {
        highlightedBranches.push(childStep.id);
      });
    }

    setActiveNodes({ nodeIds: highlightedBranches });

    setState({ domElement: event.currentTarget });
  };

  const closePopoverPanel = (actionType) => {
    setState({ domElement: null });

    if (actionType === 'delete') {
      removeBranch();
    }
    setActiveNodes({ nodeIds: [] });
  };

  // suppress panning when clicking the node
  const onButtonPress = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const removeBranch = () => {
    const {
      workflow,
      workflow: { deleteSplit, deleteBranch },
    } = workflowContext;
    const deletingSplit = index === -1;

    if (deletingSplit) {
      deleteSplit(step.id);
      trackEvent({
        event: 'Step Deleted',
        userId: rawUserId,
        properties: {
          workflowId: workflow.id,
          stepAction: 'split',
        },
      });
    } else {
      deleteBranch(step.id, index);
      trackEvent({
        event: 'Branch Deleted',
        userId: rawUserId,
        properties: {
          workflowId: workflow.id,
        },
      });
    }
  };

  const renderPopoverPanel = () => {
    const { domElement } = state;

    return (
      <PopoverPanel
        domElement={domElement}
        handleClose={() => closePopoverPanel()}
      >
        <Title>Delete Branch</Title>
        <PopoverPanelcontent>
          <p>This Branch and all its consecutive steps will be deleted.</p>
          <ButtonWrapper>
            <button
              type="button"
              className="cancel-button"
              data-button="cancel"
              onClick={closePopoverPanel}
            >
              Cancel
            </button>
            <Button
              data-button-confirm="delete"
              type="primary"
              onClick={() => closePopoverPanel('delete')}
              text="Delete"
            />
          </ButtonWrapper>
        </PopoverPanelcontent>
      </PopoverPanel>
    );
  };

  const { domElement } = state;

  return (
    <Fragment>
      <button
        className={
          domElement ? 'active btn-delete-branch' : 'btn-delete-branch'
        }
        onMouseDown={onButtonPress}
        onClick={openPopoverPanel}
        data-button="delete"
        type="button"
      >
        <Delete className="delete-icon" />
      </button>
      {domElement ? renderPopoverPanel() : null}
    </Fragment>
  );
};

export default RemoveBranchButton;
