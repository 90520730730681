import gql from 'graphql-tag';

export const SAVE_WORKFLOW_MUTATION = `
  mutation saveWorkflow($workflow: WorkflowInput!) {
    saveWorkflow(input: $workflow) {
      id
      name
      creatorUserName
      creatorUserId
      workspaceId
      modifierUserName
      modifierUserId
      definition
      version
      clonedFrom
      clonedDate
    }
  }
`;

export const UPDATE_WORKFLOW_MUTATION = `
  mutation updateWorkflow($workflow: WorkflowInput!) {
    updateWorkflow(input: $workflow) {
      id
      name
      creatorUserName
      creatorUserId
      workspaceId
      modifierUserName
      modifierUserId
      definition
      version
    }
  }
`;

export const DEACTIVATE_WORKFLOW_MUTATION = `
  mutation deactivateWorkflow($id: String!) {
    deactivateWorkflow(id: $id) {
      workflowId
      workflowEndDate
    }
  }
`;

export const DELETE_WORKFLOW_MUTATION = `
  mutation deleteWorkflow($id: String!) {
    deleteWorkflow(id: $id)
  }
`;

export const PUBLISH_WORKFLOW_MUTATION = `
  mutation publishForm($input: PublishFormInput!) {
    publishForm(input: $input)
  }
`;

export const TRIGGER_WORKFLOW_MUTATION = `
  mutation triggerWorkflow($input: WorkflowTriggerInput!) {
    triggerWorkflow(input: $input) {
      id
    }
  }
`;

export const PUBLISH_ACIVATION_MUTATION = `
  mutation publishActivation($input: PublishActivationInput!) {
    publishActivation(input: $input)
  }
`;

export const SAVE_WORKFLOW = gql(SAVE_WORKFLOW_MUTATION);
export const UPDATE_WORKFLOW = gql(UPDATE_WORKFLOW_MUTATION);
export const DEACTIVATE_WORKFLOW = gql(DEACTIVATE_WORKFLOW_MUTATION);
export const DELETE_WORKFLOW = gql(DELETE_WORKFLOW_MUTATION);
export const PUBLISH_WORKFLOW = gql(PUBLISH_WORKFLOW_MUTATION);
export const TRIGGER_WORKFLOW = gql(TRIGGER_WORKFLOW_MUTATION);
export const PUBLISH_ACTIVATION = gql(PUBLISH_ACIVATION_MUTATION);

export const CREATE_NOTIFICATION_LOCAL = gql`
  mutation CreateNotification($notification: Object) {
    createNotification(notification: $notification) @client
  }
`;

export const REMOVE_NOTIFICATION_LOCAL = gql`
  mutation RemoveNotification($id: String) {
    removeNotification(id: $id) @client
  }
`;
