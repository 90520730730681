import React, { useCallback, useContext } from 'react';
import { withRouter } from 'react-router';
import {
  PrebuiltTemplatesSelector,
  useSegmentContext,
} from '@whispir/ui-lib-v2';
import { UserContext } from '../../state';
import { ContentWrapper } from './templateSelector.styles';
import {
  prebuiltTemplates,
  startFromScratchTemplates,
  surveyTemplates,
} from './templates';
import { prefix } from '../../router/routerConstants';

const TemplateSelectorPageRender = (props) => {
  const [userContext] = useContext(UserContext);
  const { trackEvent } = useSegmentContext();
  const {
    user: { rawUserId },
  } = userContext;
  const { history } = props;

  const handleTemplateClick = useCallback(
    (templateId) => {
      history.push(`${prefix}/0${templateId ? `?template=${templateId}` : ''}`);

      const workflowTemplateName = templateId ? templateId : 'Blank';
      trackEvent({
        event: 'Workflow Template Selected',
        userId: rawUserId,
        properties: {
          workflowTemplateName,
        },
      });
    },
    [history, rawUserId, trackEvent],
  );

  const handleCancelClick = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <ContentWrapper>
      <PrebuiltTemplatesSelector
        onTemplateClick={handleTemplateClick}
        onCancelClick={handleCancelClick}
        startFromScratchTemplates={startFromScratchTemplates}
        prebuiltTemplates={prebuiltTemplates}
        surveyTemplates={surveyTemplates}
      />
    </ContentWrapper>
  );
};

export const TemplateSelectorPage = withRouter(TemplateSelectorPageRender);
