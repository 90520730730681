import React from 'react';
import { Icons, Button, Spinner } from '@whispir/ui-lib-v2';
import { DialogActions, DialogTitle, DialogContent } from '@material-ui/core';
import { StyledDialog } from './LightboxPanel.style';

const LightboxPanel = ({
  children,
  title,
  confirm,
  onConfirm = null,
  onCancel,
  customButton = null,
  onCustomButton = null,
}) => {
  const open = true;

  const renderConfirmButton = () => {
    if (onConfirm == null) {
      return <Spinner ariaLabel="Sending" size="medium" />;
    }

    return <Button data-button="confirm" onClick={onConfirm} text={confirm} />;
  };

  const renderOptionalButton = () => {
    if (customButton === null) {
      return (
        <button
          type="button"
          className="cancel-button"
          data-button="cancel"
          onClick={onCancel}
        >
          Cancel
        </button>
      );
    }
    return (
      <button
        data-button="optional"
        className="option-button"
        type="button"
        onClick={onCustomButton}
      >
        {customButton}
      </button>
    );
  };

  return (
    <StyledDialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <button type="button" className="close" onClick={onCancel}>
        <Icons.Close />
      </button>
      <DialogTitle className="dialog-title" id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent className="dialog-content">{children}</DialogContent>

      <DialogActions className="dialog-actions">
        {renderOptionalButton()}
        {renderConfirmButton()}
      </DialogActions>
    </StyledDialog>
  );
};

export default LightboxPanel;
