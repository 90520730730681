//
import React from 'react';
import {
  Description,
  TriggerTypesWrapper,
  Headline,
  LinkText,
} from './WorkflowTriggerButton.style';

const WorkflowTriggerButton = ({
  icon,
  headline,
  description,
  linkText,
  action,
}) => {
  const handleClick = () => {
    action && action();
    return;
  };

  // suppress panning when clicking the Button
  const onPress = (event) => {
    if (action) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    <TriggerTypesWrapper
      onClick={handleClick}
      className={action && 'button'}
      onMouseDown={onPress}
      data-button="trigger"
    >
      <div className="iconPosition">{icon}</div>
      <Headline>{headline}</Headline>
      {description && <Description>{description}</Description>}
      {linkText && <LinkText>{linkText}</LinkText>}
    </TriggerTypesWrapper>
  );
};

WorkflowTriggerButton.defaultProps = {
  description: '',
  linkText: '',
  action: null,
};

WorkflowTriggerButton.displayName = 'WorkflowTriggerButton';

export default WorkflowTriggerButton;
