// @flow
import gql from 'graphql-tag';

const GET_MESSAGE_TEMPLATES = gql`
  query MessageTemplates($workspaceId: String!, $offset: Int, $limit: Int) {
    workspace(id: $workspaceId) {
      templates(offset: $offset, limit: $limit) {
        id
        messageTemplateName
        email {
          contentType
        }
        isSupported
      }
    }
  }
`;

const GET_MESSAGE_TEMPLATES_RESPONSE = {
  workspace: {
    templates: [
      {
        id: 'EB416B033F2517AA',
        messageTemplateName: 'Sparx 101',
        email: {
          contentType: '',
        },
        isSupported: true,
      },
      {
        id: 'ABCFA1D568A7E194',
        messageTemplateName: 'Sparx 102',
        email: {
          contentType: 'text/plain',
        },
        isSupported: true,
      },
      {
        id: 'GRF9812739879335',
        messageTemplateName: 'Sparx 103',
        email: {
          contentType: 'unrecognised',
        },
        isSupported: true,
      },
      {
        id: 'HIJ7727819919191',
        messageTemplateName: 'Sparx 104',
        email: {
          contentType: 'text/html',
        },
        isSupported: true,
      },
    ],
  },
};

const GET_CONTACTS = gql`
  query Contacts(
    $offset: Int!
    $limit: Int!
    $workspaceId: String!
    $predicates: Filters
  ) {
    workspaceContact(id: $workspaceId) {
      contacts(offset: $offset, limit: $limit, predicates: $predicates) {
        firstName
        lastName
        mri
        id
        workPhoneNumber
        workEmail
        jobTitle
        companyName
        division
        businessUnit
        department
        teamName
        role
      }
    }
  }
`;

const GET_SINGLE_CONTACT = gql`
  query Contact($id: String!, $workspaceId: String!) {
    workspaceContact(id: $workspaceId) {
      contact(id: $id) {
        firstName
        lastName
        mri
        id
        workPhoneNumber
        workEmail
        jobTitle
        companyName
        division
        businessUnit
        department
        teamName
        role
      }
    }
  }
`;

const GET_CONTACTS_BY_IDS = gql`
  query Contact($ids: [String]!, $workspaceId: String!) {
    workspaceContact(id: $workspaceId) {
      contactsByIds(ids: $ids) {
        firstName
        lastName
        mri
        id
        workPhoneNumber
        workEmail
        jobTitle
        companyName
        division
        businessUnit
        department
        teamName
        role
      }
    }
  }
`;

const GET_CONTACTS_RESPONSE = {
  workspaceContact: {
    contacts: [
      {
        id: '348F3948985BF3FE',
        firstName: 'Contact435',
        lastName: 'LastName1',
        mri: 'mri1',
        workPhoneNumber: 'workPhoneNumber1',
        workEmail: 'workEmail1',
        jobTitle: 'jobTitle1',
        companyName: 'companyName1',
        division: 'division1',
        businessUnit: 'businessUnit1',
        department: 'department1',
        teamName: 'teamName1',
        role: 'role1',
      },
      {
        id: '6D5B9FF9984103E9',
        firstName: 'Contact447',
        lastName: 'LastName2',
        mri: 'mri2',
        workPhoneNumber: 'workPhoneNumber2',
        workEmail: 'workEmail2',
        jobTitle: 'jobTitle2',
        companyName: 'companyName2',
        division: 'division2',
        businessUnit: 'businessUnit2',
        department: 'department2',
        teamName: 'teamName2',
        role: 'role2',
      },
    ],
  },
};

const GET_DISTRIBUTIONS = gql`
  query Distributions(
    $offset: Int!
    $limit: Int!
    $workspaceId: String!
    $predicates: DistributionFilters
  ) {
    workspaceContact(id: $workspaceId) {
      lists: distributions(
        offset: $offset
        limit: $limit
        predicates: $predicates
      ) {
        id
        name
        mri
      }
    }
  }
`;

const GET_DISTRIBUTIONS_BY_IDS = gql`
  query Distributions($ids: [String]!, $workspaceId: String!) {
    workspaceContact(id: $workspaceId) {
      distributionsByIds(ids: $ids) {
        id
        name
        mri
      }
    }
  }
`;

const GET_SINGLE_DISTRIBUTION = gql`
  query Distribution($id: String!, $workspaceId: String!) {
    workspaceContact(id: $workspaceId) {
      distribution(id: $id) {
        id
        name
        mri
      }
    }
  }
`;

const GET_DISTRIBUTIONS_RESPONSE = {
  workspaceContact: {
    lists: [
      {
        id: '753EC4A939E01DC5',
        name: 'DL01',
        mri: 'dlmri1',
      },
      {
        id: '1CBAF2BCDB9DC908',
        name: 'DL02',
        mri: 'dlmri2',
      },
      {
        id: '2XRTTHWHHW7F881',
        name: 'DL03',
        mri: 'dlmri3',
      },
    ],
  },
};

const GET_MESSAGE_OPTIONS = gql`
  query MessageOptions($workspaceId: String!) {
    workspace(id: $workspaceId) {
      templates {
        id
        messageTemplateName
        subject
        sms {
          body
        }
        email {
          body
        }
        web {
          body
        }
      }
    }
  }
`;

const GET_MESSAGE_OPTIONS_RESPONSE = {
  workspace: {
    templates: [
      {
        id: 'EB416B033F2517AA',
        messageTemplateName: 'Sparx 101',
        subject: 'mock response',
        sms: {
          body: 'sms body',
        },
        email: {
          body: 'email body',
        },
        web: {
          body: 'web body',
        },
        isSupported: true,
      },
    ],
  },
};

const GET_MESSAGE_OPTIONS_DIST = gql`
  query MessageOptionsDistribution(
    $offset: Int!
    $limit: Int!
    $workspaceId: String!
  ) {
    workspaceContact(id: $workspaceId) {
      distributions(offset: $offset, limit: $limit) {
        id
        name
        mri
      }
    }
  }
`;

const GET_MESSAGE_OPTIONS_DIST_RESPONSE = {
  workspaceContact: {
    distributions: [
      {
        id: '753EC4A939E01DC5',
        name: 'Artemis2',
        mri: 'Artemis.Sparx@list.company.whispir.com',
      },
      {
        id: '1CBAF2BCDB9DC908',
        name: 'TeamB2',
        mri: 'TeamB.Sparx@list.company.whispir.com',
      },
    ],
  },
};

export const queries = Object.freeze({
  GET_MESSAGE_TEMPLATES,
  GET_CONTACTS,
  GET_SINGLE_CONTACT,
  GET_CONTACTS_BY_IDS,
  GET_DISTRIBUTIONS,
  GET_SINGLE_DISTRIBUTION,
  GET_DISTRIBUTIONS_BY_IDS,
  GET_MESSAGE_OPTIONS,
  GET_MESSAGE_OPTIONS_DIST,
});

export const responses = Object.freeze({
  GET_MESSAGE_TEMPLATES_RESPONSE,
  GET_CONTACTS_RESPONSE,
  GET_DISTRIBUTIONS_RESPONSE,
  GET_MESSAGE_OPTIONS_RESPONSE,
  GET_MESSAGE_OPTIONS_DIST_RESPONSE,
});
