import styled, { createGlobalStyle } from 'styled-components';
import { VariableSelectorPopover, Colors, Fonts } from '@whispir/ui-lib-v2';

export const StyledVariableSelectorPopover = styled(VariableSelectorPopover)`
  .MuiPopover-paper {
    width: 448px;
    margin-left: -8px;
  }
`;

export const ExpressionInputWrapper = styled.div`
  position: relative;

  .expression-popper {
    margin-top: 1px;
    width: 100%;
  }

  .variable-selector-popover {
    left: -24px;
  }

  .expression-popper,
  .expression-popper .MuiPaper-rounded,
  .expression-popper .MuiPaper-rounded .select-panel {
    border-radius: 8px;
    z-index: 5;
  }

  .expression-chip {
    height: 24px;
    border-radius: 12px;
    background-color: ${Colors.variable_light};
    ${Fonts.bodySmall};
    color: ${Colors.voice};

    &:hover,
    &:focus {
      background-color: ${Colors.voice}50;
    }
    max-width: 169px;
  }
  .lozenge {
    font-weight: 500;
    max-width: 127px;
  }
  .MuiChip-root {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    max-width: 200px;
    color: ${Colors.variable};
  }
  .naked-chip,
  .clear-chip {
    background-color: transparent;
    ${Fonts.body};
    color: ${Colors.grey_blue_5};
    &:hover,
    &:focus {
      color: ${Colors.accent};
      background-color: transparent;
    }
  }
  .clear-chip {
    margin-left: auto;
    &:hover,
    &:focus {
      background-color: transparent;
    }
    .MuiChip-label {
      padding-left: 0px;
    }
  }
`;

export const Field = styled.div`
  padding: 8px;
  color: ${Colors.grey_blue_4};
  border-bottom: ${({ hasValidationErrors }) =>
    hasValidationErrors
      ? `2px ${Colors.error} solid`
      : '1px  transparent solid'};
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${Colors.white};
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;

  &:hover {
    color: ${Colors.accent_dark};
  }

  &.open {
    color: ${Colors.accent};
    border-bottom: 2px solid ${Colors.accent};
  }

  /* The child components are doing some align-self styling, so just setting it right. */
  & > * {
    align-self: center;
  }
`;

export const ConditionValidationText = styled.div`
  .validation {
    margin: 5px 5px 5px 0;
    text-align: left;

    ${Fonts.error};
  }

  .validation-helper-text {
    margin: 5px 5px 5px 0;

    ${Fonts.caption};
  }
`;

/*
  Material UI overwrite - This allows to see the expression-popper when overlapping another poppper
  Only update below style code if you wanted to apply same styles to all expression-popper in: 
  - Workflow Variables Menu
  - Expression Input
  
  If only need to update expression-popper style in one component, 
  update the style using expression-popper class under the component wrapper 
  inside the component style file   
*/
createGlobalStyle`
  .expression-popper {
    z-index: 99;
  }
`;
