//
import React from 'react';
// $FlowFixMe
import { validators } from '@whispir/workflow-validation';
import {
  RadioButtonGroup,
  SendWebformNode,
  BaseOnReplyNode,
} from '@whispir/ui-lib-v2';
import { trackEvent } from '@whispir/utils-js';
import {
  doesNodeHaveChildren,
  doesNodeHaveMessageContent,
  doesNodeHaveLinkContent,
} from '@whispir/workflow-data-model';
import {
  FeatureFlaggedEmbeddedMessage,
  FeatureFlaggedTime,
  FeatureFlaggedExpression,
} from '../../PropertyContent';
import {
  Time,
  Expression,
  SendMessageTask as SmsOrEmailMessage,
} from './Content';

export const DELETE_MODES = Object.freeze({
  STEP: 'step',
  BRANCH: 'branch',
});

class NodeFactory {
  // Mapping node type with popover content
  mapper = {
    task: {
      'send-sms': {
        Node: SmsOrEmailMessage,
        ConfigurationContent: FeatureFlaggedEmbeddedMessage,
        validate: validators['send-sms'],
      },
      'send-email': {
        Node: SmsOrEmailMessage,
        ConfigurationContent: FeatureFlaggedEmbeddedMessage,
        validate: validators['send-email'],
      },
      'send-webform': {
        Node: ({ node, isValid }) => {
          const recipientCount = node.data.recipients.length;
          const name = node.data.stepName;
          // Mode here is to determine what to display on canvas
          let mode = 'not-selected';

          if (
            node &&
            node.data &&
            node.data.message &&
            node.data.message.type === 'sms'
          ) {
            mode = 'sms';
          }
          if (
            node &&
            node.data &&
            node.data.message &&
            node.data.message.type === 'email'
          ) {
            mode = 'email';
          }
          return (
            <SendWebformNode
              name={name}
              recipientCount={recipientCount}
              mode={mode}
              nodeHasMessageContent={doesNodeHaveMessageContent(node)}
              nodeHasLinkContent={doesNodeHaveLinkContent(node)}
              isValid={isValid}
            />
          );
        },
        ConfigurationContent: FeatureFlaggedEmbeddedMessage,
        validate: validators['send-webform'],
      },
    },
    condition: {
      time: {
        Node: Time,
        ConfigurationContent: FeatureFlaggedTime,
        validate: validators.time,
      },
      expression: {
        Node: Expression,
        ConfigurationContent: FeatureFlaggedExpression,
        validate: validators.expression,
      },
      'on-sms-reply': {
        Node: () => <BaseOnReplyNode type="response" label="On response" />,
        ConfigurationContent: null, // If null open the parent
        validate: validators['on-sms-reply'],
      },
      'on-sms-no-reply': {
        Node: () => <BaseOnReplyNode type="no-response" label="No response" />,
        ConfigurationContent: null,
        validate: validators['on-sms-no-reply'],
      },
      'on-webform-reply': {
        Node: () => <BaseOnReplyNode type="response" label="Submitted" />,
        ConfigurationContent: null, // If null open the parent
        validate: validators['on-webform-reply'],
      },
      'on-webform-no-reply': {
        Node: () => (
          <BaseOnReplyNode type="no-response" label="Not Submitted" />
        ),
        ConfigurationContent: null,
        validate: validators['on-webform-no-reply'],
      },
    },
    start: {},
    empty: {},
  };

  deleteType = null;

  delete = ({
    node,
    nodeIndex,
    workflowContext,
    setWorkflowContext,
    userContext,
    handleClose,
  }) => () => {
    const {
      workflow,
      workflow: { deleteStep, deleteStepWithDepth },
    } = workflowContext;
    const {
      user: { rawUserId },
    } = userContext;

    switch (this.deleteType) {
      case DELETE_MODES.STEP:
        deleteStep(node.id, nodeIndex);
        break;
      case DELETE_MODES.BRANCH:
        deleteStepWithDepth(node.id, nodeIndex);
        break;

      default:
        throw new Error(`Delete mode of ${this.deleteType} is unsupported.`);
    }

    setWorkflowContext({ ...workflowContext, lightboxProps: null });
    trackEvent({
      event: 'Step Deleted',
      userId: rawUserId,
      properties: {
        workflowId: workflow.id,
        stepAction: node.action,
      },
    });
    handleClose();
  };

  openConfirmationDialogue = ({
    workflowContext,
    setWorkflowContext,
    userContext,
    handleClose,
  }) => ({ node, nodeIndex }) => () => {
    const lightboxProps = {
      onConfirm: this.delete({
        node,
        nodeIndex,
        workflowContext,
        setWorkflowContext,
        userContext,
        handleClose,
      }),
      onCancel: this.onCancelDelete({
        workflowContext,
        setWorkflowContext,
      }),
      confirm: 'Confirm',
      title: 'Delete Step?',
      children: this.deleteContent({ node }),
    };

    setWorkflowContext({ ...workflowContext, lightboxProps });
  };

  setDeleteType = (deleteMode) => {
    this.deleteType = deleteMode;
  };

  deleteContent = ({ node }) => {
    const radioButtonList = [
      {
        label: 'Delete this step only',
        value: DELETE_MODES.STEP,
      },
      {
        label: 'Delete this step and all consecutive steps',
        value: DELETE_MODES.BRANCH,
      },
    ];

    const defaultDeleteValue = radioButtonList[0].value;
    this.setDeleteType(defaultDeleteValue);
    const nodeHasChildren = doesNodeHaveChildren(node);

    if (nodeHasChildren) {
      return (
        <RadioButtonGroup
          items={radioButtonList}
          onChange={this.setDeleteType}
        />
      );
    }

    return <p>This step will be deleted.</p>;
  };

  onCancelDelete = ({ workflowContext, setWorkflowContext }) => () => {
    setWorkflowContext({ ...workflowContext, lightboxProps: null });
  };

  createNode(props) {
    const {
      node: { id, type, action },
    } = props;
    const DynamicNode = action && this.mapper[type][action].Node;
    if (!DynamicNode) {
      console.info(`No node mapping found for node of type ${type}/${action}`);
    }
    return DynamicNode && <DynamicNode key={id} {...props} />;
  }

  createPopoverContent(props) {
    const {
      node: { type, action },
      workflowContext,
      setWorkflowContext,
      userContext,
      node,
      nodeIndex,
      onCloseRequest,
      onUnmount,
    } = props;
    const nodeConfig = this.mapper[type][action];
    const { ConfigurationContent, validate } = nodeConfig;

    if (!ConfigurationContent) {
      return null;
    }

    const handleDelete = this.openConfirmationDialogue({
      workflowContext,
      setWorkflowContext,
      userContext,
      handleClose: onCloseRequest,
    })({
      node,
      nodeIndex,
    });

    const contentProps = {
      node,
      nodeIndex,
      validate,
      onCloseRequest,
      onUnmount,
      onDeleteClick: handleDelete,
    };

    /**
     * Notes about the ConfigurationContent technical debt
     *
     *
     * - The onUnmount callback is used to do a forceUpdate on Node.js because otherwise the node labels don't update until a second click.
     * - The onCloseRequest is perhaps a bit of an anti-pattern. Basically it's used to let each of these content components tell Node.js to close the SideDrawer/Popover.
     *   - A better reworking might be to declare the handlers here (or Node.js) and pass in props 'onApplyClick', 'onDeleteClick' 'onUnmount'. But that involves pulling up all of the 'save' functionality too.
     *   - At this point the configuration components look more like pure functional 'display only, no business logic' type components.
     *
     */

    /*
      A better abstraction:

      - You can see there is a lot of copy pasted logic in each of the ConfigurationContent
      (onUnmount, onSaveClick, the delete button etc).
      We could create an abstract for that stuff here, and just pass in the specific content, and it call the handlers passed in.
    */

    // nb. It is important to be providing a key!
    // see: https://codesandbox.io/s/unmounting-and-keys-16b3m?file=/src/App.js
    return <ConfigurationContent key={node.id} {...contentProps} />;
  }
}

export default NodeFactory;
