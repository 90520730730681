import styled from 'styled-components';
import { Colors, Elevations, Fonts } from '@whispir/ui-lib-v2';

const wrapperWidth = '220px';
const iconSize = '44px';

export const TriggerTypesWrapper = styled.div`
    background-color: ${Colors.white};
    border: none;
    border-radius: 8px;
    padding: 0 16px 16px;
    max-width: ${wrapperWidth};
    text-align: center;
    box-shadow: ${Elevations.elevation_1};
    transition: box-shadow 0.2s ease-in-out;

    &:hover {
      &.button {
        box-shadow: ${Elevations.elevation_2};
        cursor: pointer;
      }
    }

    .iconPosition {
      width: ${iconSize};
      margin: calc(-${iconSize} / 2) auto 8px auto;
      }
    }
`;

export const Headline = styled.span`
  display: block;
  margin-bottom: 8px;
  ${Fonts.linkSecondary};
`;

export const Description = styled.span`
  display: block;
  ${Fonts.bodySmall};
`;

export const LinkText = styled.span`
  ${Fonts.link};
  display: block;
  margin-top: 12px;
`;
