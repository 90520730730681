export const deleteWorkflowNotificationMessage = (error) => {
  const notification = {};
  if (error) {
    return {
      ...notification,
      type: 'error',
      message: `${error}`,
      id: Date.now(),
      __typename: 'Notification',
    };
  }

  return {
    ...notification,
    type: 'success',
    message: 'Workflow deleted successfully',
    id: Date.now(),
    __typename: 'Notification',
  };
};

// This is a temporary util function to extract error objects stored within the WorkflowModel instance
// so that it can be surfaced as part of the snackbar notification.
export const displaySpecificErrorMessage = (errors, errorMessage) => {
  const errorList = Object.values(errors);
  // https://stackoverflow.com/questions/10865025/merge-flatten-an-array-of-arrays
  // because array.flat isn't available on node 10
  const flattenErrorList = [].concat.apply([], errorList);
  return flattenErrorList.find((error) => error === errorMessage);
};

// Temporary workspaceId
export const getSelectedWorkspaceId = () => '4A6EF23500B2DF7E';
