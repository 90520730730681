import React from 'react';
import { useSideDrawerContext } from '@whispir/ui-lib-v2';
import { InnerWrapper as StyledInnerWrapper } from './Builder.style';

export const InnerWrapper = ({ children }) => {
  const { content, setContent } = useSideDrawerContext();

  const handleHideSideDrawer = (e) => {
    /**
     * Note: Need to check if target element is InnerWrapper
     * because even if there is a modal on top, InnerWrapper receives an onClick event
     */
    if (content && e.target.className.includes('inner-wrapper')) {
      setContent(null);
    }
  };

  return (
    <StyledInnerWrapper
      className="inner-wrapper"
      onClick={handleHideSideDrawer}
    >
      {children}
    </StyledInnerWrapper>
  );
};
