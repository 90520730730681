import { AxiosInstance } from 'axios';
import { useCallback } from 'react';
import { SAVE_WORKFLOW_MUTATION } from '../apollo/mutations/workflow-mutation';
import { useLoading } from './useLoading';

export const useSaveWorkflow = ({
  api,
  workspaceId,
}: {
  api: AxiosInstance;
  workspaceId: string;
}) => {
  const saveWorkflow = useCallback(
    async (body) => {
      const { data } = await api.request(
        {
          data: {
            operationName: 'saveWorkflow',
            variables: {
              workflow: {
                definition: body.definition,
                name: body.name,
                workspaceId,
                ...body,
              },
            },
            query: SAVE_WORKFLOW_MUTATION,
          }
        },
      );

      return data;
    },
    [api, workspaceId],
  );

  return useLoading(saveWorkflow);
};
