import { AxiosInstance } from 'axios';
import { useCallback } from 'react';
import { GET_TRIGGERED_WORKFLOW_QUERY } from '../apollo/queries/workflow-queries';
import { useLoading } from './useLoading';

// Queries the trigger history of a workflow -  returns the latest execution state of that workflow
export const useGetTriggeredWorkflow = ({ api }: { api: AxiosInstance }) => {
  const getTriggeredWorkflow = useCallback(
    async (body) => {
      const { data } = await api.request(
        {
          data: {
            operationName: 'getTriggeredWorkflow',
            variables: {
              workflowId: body.workflowId,
            },
            query: GET_TRIGGERED_WORKFLOW_QUERY,
          },
        }
      );

      return data;
    },
    [api],
  );

  return useLoading(getTriggeredWorkflow);
};
