import axios from 'axios';
import { useGetWorkflow } from './useGetWorkflow';
import { useGetWorkflows } from './useGetWorkflows';
import { useSaveWorkflow } from './useSaveWorkflow';
import { useTriggerWorkflow } from './useTriggerWorkflow';
import { useUpdateWorkflow } from './useUpdateWorkflow';
import { usePublishFormToTriggerWorkflow } from './usePublishWorkflow';
import { useDeleteWorkflow } from './useDeleteWorkflow';
import { useDeactivateWorkflow } from './useDeactivateWorkflow';
import { useGetTriggeredWorkflow } from './useGetTriggeredWorkflow';
import { useGetTriggeredWorkflows } from './useGetTriggeredWorkflows';
import { getSelectedWorkspaceId } from '../utils/helperUtils';

export const useWorkflowApi = () => {
  const api = axios.create();

  const workspaceId = getSelectedWorkspaceId();

  api.interceptors.request.use(async (config) => ({
    ...config,
    url: process.env.REACT_APP_GRAPHQL_SERVER as string,
    method: 'POST',
    headers: {
      ...config.headers,
      // @ts-ignore - TODO: use Container typings for wspAuth
      'Authorization': `Bearer ${await window.wspAuth.getAccessToken()}`,
    }
  }));

  api.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject(error.response),
  );

  const getWorkflow = useGetWorkflow({ api });
  const getWorkflows = useGetWorkflows({
    api,
    workspaceId,
  });
  const getTriggeredWorkflow = useGetTriggeredWorkflow({ api });
  const getTriggeredWorkflows = useGetTriggeredWorkflows({ api, workspaceId });
  const saveWorkflow = useSaveWorkflow({
    api,
    workspaceId,
  });
  const updateWorkflow = useUpdateWorkflow({
    api,
    workspaceId,
  });
  const triggerWorkflow = useTriggerWorkflow({
    api,
  });
  const publishWorkflow = usePublishFormToTriggerWorkflow({
    api,
  });
  const deleteWorkflow = useDeleteWorkflow({
    api,
  });
  const deactivateWorkflow = useDeactivateWorkflow({
    api,
  });

  return {
    getWorkflow: getWorkflow.callback,
    getWorkflows: getWorkflows.callback,
    getTriggeredWorkflow: getTriggeredWorkflow.callback,
    getTriggeredWorkflows: getTriggeredWorkflows.callback,
    saveWorkflow: saveWorkflow.callback,
    updateWorkflow: updateWorkflow.callback,
    triggerWorkflow: triggerWorkflow.callback,
    publishWorkflow: publishWorkflow.callback,
    deleteWorkflow: deleteWorkflow.callback,
    deactivateWorkflow: deactivateWorkflow.callback,
    // Add loading state of hook here
    isLoading:
      getWorkflow.isLoading ||
      getWorkflows.isLoading ||
      getTriggeredWorkflow.isLoading ||
      getTriggeredWorkflows.isLoading ||
      triggerWorkflow.isLoading ||
      publishWorkflow.isLoading ||
      deleteWorkflow.isLoading ||
      deactivateWorkflow.isLoading,
    isSaving: saveWorkflow.isLoading || updateWorkflow.isLoading,
  };
};
