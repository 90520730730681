export const updateChannelTitle = ({ channelName, title, nodeData }) => {
  let updatedTriggerData;

  const {
    id,
    data: {
      form: { components },
    },
  } = nodeData;

  switch (channelName) {
    case 'form':
      updatedTriggerData = {
        id,
        type: 'form',
        data: {
          [channelName]: {
            id: 'Form',
            body: '',
            title,
            components,
          },
        },
      };
      break;
    default:
      throw new Error(`Channel name not found: ${channelName}`);
  }

  return { triggers: [updatedTriggerData] };
};

export const updateChannelBody = ({ channelName, value, nodeData }) => {
  let updatedTriggerData;

  const {
    id,
    data: {
      form: { title },
    },
  } = nodeData;

  switch (channelName) {
    case 'form':
      updatedTriggerData = {
        id,
        type: 'form',
        data: {
          [channelName]: {
            id: 'Form',
            body: '',
            title,
            components: value,
          },
        },
      };
      break;
    default:
      throw new Error(`Channel name not found: ${channelName}`);
  }

  return { triggers: [updatedTriggerData] };
};
