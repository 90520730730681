import React, { PureComponent, createRef } from 'react';
import { prefix, trackEvent } from '@whispir/utils-js';
import { errors } from '@whispir/workflow-validation';
import moment from 'moment';
import { displaySpecificErrorMessage } from '../../utils/helperUtils';
import { UserContext } from '../../state';
import LightboxPanel from '../LightboxPanel/LightboxPanel';
import { HeaderWrapper, RightSideMenuWrapper } from './Header.style';
import {
  setTextFieldWidth,
  PublishConfirmationModal,
  CloseButton,
  HeaderTitle,
  HeaderActionButtons,
} from './HeaderActions';

class Header extends PureComponent {
  static contextType = UserContext;

  static defaultProps = {
    title: '',
    hasCloseButton: false,
    closePublishConfirmation: () => null,
    workflowModel: {},
    workflow: {
      id: null,
      publishHandler: null,
      triggerHandler: null,
      hasData: false,
    },
  };

  state = {
    newTitle: '',
    lightboxProps: null,
    isPublish: false,
    formLink: '',
    isWorkflowActive: false,
  };

  componentDidMount() {
    const {
      title,
      workflow: { isWorkflowActive },
    } = this.props;
    this.setState({ isWorkflowActive });
    this.updateTextFieldWidth(
      title,
      setTextFieldWidth(title, this.workflowTitleInput),
    );
  }

  workflowTitleInput = createRef();

  clickFocus = createRef();

  getNewWorkflowName = () => `Untitled ${moment().format('D MMM [-] h.mma')}`;

  setNewWorkflowName = () => {
    const { newTitle } = this.state;

    if (newTitle === '') {
      const { onChangeTitle } = this.props;

      this.setState(
        { newTitle: this.getNewWorkflowName() },
        setTextFieldWidth(this.getNewWorkflowName()),
      );
      onChangeTitle(this.getNewWorkflowName());
    }
  };

  showSaveBeforeExitLightbox = () => {
    this.setNewWorkflowName();

    const { onClickSave, history } = this.props;
    const lightboxProps = {
      confirm: 'Save & Exit',
      title: 'Save before exiting?',
      onCancel: this.closeLightbox,
      onConfirm: () => onClickSave(true),
      customButton: 'Discard Changes',
      onCustomButton: () => history.replace(prefix),
    };

    this.setState({ lightboxProps });
  };

  closeLightbox = () => this.setState({ lightboxProps: null });

  handleClickCopy = () => {
    const { showNotification } = this.props;
    showNotification({
      message: 'Webform URL has been copied to clipboard!',
      success: true,
    });
  };

  closePublishConfirmation = () => {
    this.setState({ isPublish: false, isWorkflowActive: true });
    const { closePublishConfirmation } = this.props;
    closePublishConfirmation && closePublishConfirmation();
  };

  triggerWorkflow = async () => {
    const {
      workflow: { triggerHandler },
      showNotification,
    } = this.props;

    try {
      this.setNewWorkflowName();
      await triggerHandler();
    } catch (error) {
      const {
        graphQLErrors: [graphQLError],
      } = error;
      if (
        graphQLError &&
        graphQLError.extensions &&
        graphQLError.extensions.code === 'RESOURCE_EXPIRED'
      ) {
        showNotification({
          message: 'You can’t trigger an archived Workflow',
          success: false,
        });
      } else {
        showNotification({ message: errors.apiFault, success: false });
      }
    }
  };

  publishWorkflow = async () => {
    const {
      workflow: { publishHandler, valid },
      showNotification,
      workflowModel,
    } = this.props;

    if (!valid()) {
      const errMessage = displaySpecificErrorMessage(
        workflowModel.errors,
        errors.emptyNodeError,
      );
      if (errMessage) {
        showNotification({
          message: errMessage,
          success: false,
        });
        return;
      }
      showNotification({
        message: errors.workflowValidation,
        success: false,
      });
      return;
    }

    try {
      this.setNewWorkflowName();
      const { workflowId, publishForm } = await publishHandler();
      this.setState({ isPublish: true, formLink: publishForm });
      const [userCtx] = this.context;
      const {
        user: { rawUserId },
      } = userCtx && userCtx;

      trackEvent({
        event: 'Workflow Published',
        userId: rawUserId,
        properties: {
          workflowId,
        },
      });
    } catch (error) {
      const {
        graphQLErrors: [graphQLError],
      } = error;
      if (
        graphQLError &&
        graphQLError.extensions &&
        graphQLError.extensions.code === 'RESOURCE_EXPIRED'
      ) {
        showNotification({
          message: 'You can’t publish an archived Workflow',
          success: false,
        });
      } else {
        showNotification({ message: errors.apiFault, success: false });
      }
    }
  };

  updateTextFieldWidth = (value, setTextFieldWidth) => {
    this.setState({ newTitle: value }, setTextFieldWidth);
  };

  handleSave = () => {
    const {
      workflow: { id: workflowId },
      onClickSave,
    } = this.props;
    const [userCtx] = this.context;
    const {
      user: { rawUserId },
    } = userCtx && userCtx;
    this.setNewWorkflowName();

    trackEvent({
      event: 'Workflow Saved',
      userId: rawUserId,
      properties: {
        workflowId,
      },
    });
    onClickSave(false);
  };

  handleChange = (event) => {
    const { onChangeTitle } = this.props;
    const {
      currentTarget: { value },
    } = event;

    this.updateTextFieldWidth(
      value,
      setTextFieldWidth(value, this.workflowTitleInput),
    );
    onChangeTitle(value);
  };

  handleInputFocus = () => {
    const workflowTitleInputRef = this.workflowTitleInput.current;
    workflowTitleInputRef && workflowTitleInputRef.focus();
  };

  handleClickAwayElement = () => {
    const clickAway = this.clickFocus.current;
    if (clickAway) clickAway.style.pointerEvents = 'all';
  };

  handleClickAwayFocus = () => {
    const clickAway = this.clickFocus.current;
    if (clickAway) clickAway.style.pointerEvents = 'none';
  };

  render() {
    const {
      newTitle,
      lightboxProps,
      isPublish,
      formLink,
      isWorkflowActive,
    } = this.state;
    const { hasCloseButton, isSavingInProgress, workflow } = this.props;
    const { hasData, startNodeType } = workflow;

    return (
      <HeaderWrapper>
        <HeaderTitle
          workflowTitleInputRef={this.workflowTitleInput}
          newTitle={newTitle}
          onChangeTitle={this.handleChange}
          onClickAwayElement={this.handleClickAwayElement}
          onInputFocus={this.handleInputFocus}
          onClickFocus={this.clickFocus}
          onClickAwayFocus={this.handleClickAwayFocus}
          updateTextFieldWidth={this.updateTextFieldWidth}
        />
        <RightSideMenuWrapper>
          <HeaderActionButtons
            buttonDisabled={!hasData}
            manualTrigger={this.triggerWorkflow}
            publishWorkflow={this.publishWorkflow}
            isWorkflowActive={isWorkflowActive}
            onSave={this.handleSave}
            isSavingInProgress={isSavingInProgress}
            startNodeType={startNodeType}
          />
          <CloseButton
            hasCloseButton={hasCloseButton}
            onClick={this.showSaveBeforeExitLightbox}
          />
          <PublishConfirmationModal
            isPublish={isPublish}
            formLink={formLink}
            handleClose={this.closePublishConfirmation}
            onClickCopy={this.handleClickCopy}
          />
        </RightSideMenuWrapper>
        {lightboxProps && (
          <LightboxPanel {...lightboxProps}>
            <p>
              Save changes to <strong>{newTitle}</strong> before closing.
            </p>
          </LightboxPanel>
        )}
      </HeaderWrapper>
    );
  }
}

export default Header;
