const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

// This is not going to be a sustainable way or formatting errors going forward.
// If we find outselves writing a lot of functions like this, lets rethink how we are doing validation.

const parseDotSeparatedError = (error) => {
  const dotSeparatedMessage = error.split('.');
  const errorMessage = dotSeparatedMessage[dotSeparatedMessage.length - 1];
  const capitalizedErrorMessage = capitalizeFirstLetter(errorMessage);
  return capitalizedErrorMessage;
};

export const getFormattedErrors = (error) => {
  if (!error || error.length <= 1) {
    return null;
  }

  // The first error returned by the validator is discarded, as it only
  // provides general information on the error.
  // The remaining errors in the array provide specific validation errors,
  // and are useful for displaying in the UI.
  const [, ...remainingErrors] = error;
  const parsedErrors = remainingErrors.map(parseDotSeparatedError);

  return parsedErrors;
};

const pluralizeTime = function (text, num) {
  if (!num) return '';
  const time = `${num} ${text}`;
  return num > 1 ? `${time}s` : `${time}`;
};

export const getDurationDisplayValues = (duration, label) => {
  const { days, hours, minutes } = duration;
  const schedule = [
    pluralizeTime('day', days),
    pluralizeTime('hr', hours),
    pluralizeTime('min', minutes),
  ].filter(Boolean);

  return `${label} ${schedule.join(', ')}`;
};
