import React, { Fragment } from 'react';
import {
  Spinner,
  Button,
  ConfirmationModal,
  Illustrations,
  CopyText,
  Icons,
} from '@whispir/ui-lib-v2';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  CloseLinkWrapper,
  TitleWrapper,
  ModalBodyWrapper,
} from './Header.style';

const { Close, EditPen } = Icons;

export const setTextFieldWidth = (title, workflowTitleInputRef) => {
  const { length } = title;
  const inputElWidth = workflowTitleInputRef
    ? workflowTitleInputRef.current
    : null;

  if (inputElWidth) {
    const { style } = inputElWidth;
    switch (true) {
      case length <= 7:
        style.width = '125px';
        break;
      case length <= 10:
        style.width = '160px';
        break;
      case length <= 12:
        style.width = '195px';
        break;
      case length <= 15:
        style.width = '230px';
        break;
      case length <= 18:
        style.width = '265px';
        break;
      case length <= 21:
        style.width = '300px';
        break;
      case length <= 24:
        style.width = '335px';
        break;
      case length <= 27:
        style.width = '370px';
        break;
      case length <= 30:
        style.width = '400px';
        break;
      case length <= 34:
        style.width = '435px';
        break;
      default:
        break;
    }
  }
};

export const HeaderTitle = ({
  workflowTitleInputRef,
  newTitle,
  onChangeTitle,
  onClickAwayElement,
  onInputFocus,
  onClickFocus,
  onClickAwayFocus,
  updateTextFieldWidth,
}) => (
  <TitleWrapper>
    <TextField
      inputRef={workflowTitleInputRef}
      id="workflow-title"
      margin="normal"
      variant="outlined"
      value={newTitle}
      onChange={onChangeTitle}
      placeholder="Untitled..."
      onMouseUp={onClickAwayElement}
      autoComplete="off"
      InputProps={{
        endAdornment: (
          <InputAdornment
            onClick={onInputFocus}
            className="edit-icon"
            position="start"
          >
            <EditPen />
          </InputAdornment>
        ),
      }}
    />
    <div
      contentEditable
      ref={onClickFocus}
      id="click-focus"
      onFocus={onClickAwayFocus}
    />
  </TitleWrapper>
);

export const PublishConfirmationModal = ({
  isPublish,
  formLink,
  handleClose,
  onClickCopy,
}) => {
  const renderModal = (
    <ModalBodyWrapper>
      <p className="message-body">
        Your workflow will be triggered every time someone submits the form.
        Copy the link to the form below and share it with the people responsible
        for completing the form
      </p>
      <CopyText
        text={formLink}
        helperText="Trigger Form URL"
        onClick={onClickCopy}
        successMessage="Link copied successfully!"
        warningText="Please note: This is a public link. Anyone you share it with will be able to trigger the workflow"
      />
    </ModalBodyWrapper>
  );
  return (
    <ConfirmationModal
      logo={<Illustrations.Worklfow />}
      title="Your Workflow is Live!"
      content={renderModal}
      actionButtonText="Done"
      exitButtonText=""
      size="extra-small"
      isOpen={isPublish}
      handleExit={handleClose}
      handleAction={handleClose}
    />
  );
};

export const CloseButton = ({ hasCloseButton, onClick }) => {
  if (!hasCloseButton) return null;
  return (
    <CloseLinkWrapper>
      <button
        type="button"
        title="Go to Workflow Dashboard"
        data-link="close"
        onClick={onClick}
      >
        <Close />
      </button>
    </CloseLinkWrapper>
  );
};

export const SaveButton = ({ isSavingInProgress, handleSave }) => {
  return (
    <div className="button-list">
      {isSavingInProgress ? (
        <Spinner size="medium" />
      ) : (
        <Button
          variant="link"
          text="Save"
          data-button="save"
          onClick={handleSave}
        />
      )}
    </div>
  );
};

// The button to either trigger a manual workflow or publish a trigger form workflow
export const TriggerOrPublishButton = ({
  buttonDisabled,
  publishWorkflow,
  manualTrigger,
  buttonLabel,
}) => {
  const isManualTrigger = buttonLabel === 'Trigger';
  const activateWorkflow = isManualTrigger ? manualTrigger : publishWorkflow;
  const startIcon = isManualTrigger ? 'DefaultTrigger' : 'Publish';

  return (
    <div className="button-list">
      <Button
        text={buttonLabel}
        data-button="trigger"
        disabled={buttonDisabled}
        onClick={activateWorkflow}
        startIcon={startIcon}
      />
    </div>
  );
};

export const SaveAndPublishButton = ({
  buttonDisabled,
  publishWorkflow,
  buttonLabel,
}) => {
  return (
    <div className="button-list">
      <Button
        text={buttonLabel}
        data-button="trigger"
        disabled={buttonDisabled}
        onClick={publishWorkflow}
        startIcon="Launch"
      />
    </div>
  );
};

// display combined save and publish when workflow is TF and active

export const HeaderActionButtons = ({
  buttonDisabled,
  publishWorkflow,
  isWorkflowActive,
  isSavingInProgress,
  startNodeType,
  manualTrigger,
  onSave,
}) => {
  const isStartNodeForm = startNodeType === 'form';
  const determineButtonLabel = isStartNodeForm ? 'Publish' : 'Trigger';
  if (isStartNodeForm && isWorkflowActive) {
    return (
      <div className="button-list">
        <Button
          text="Save & Publish"
          data-button="trigger"
          disabled={buttonDisabled}
          onClick={publishWorkflow}
          startIcon="Launch"
        />
      </div>
    );
  }

  return (
    <Fragment>
      <SaveButton isSavingInProgress={isSavingInProgress} handleSave={onSave} />
      <TriggerOrPublishButton
        buttonDisabled={buttonDisabled}
        manualTrigger={manualTrigger}
        publishWorkflow={publishWorkflow}
        buttonLabel={determineButtonLabel}
      />
    </Fragment>
  );
};
