//
import createNotification from './notifications/createNotification';
import removeNotification from './notifications/removeNotification';

const resolvers = {
  Mutation: {
    createNotification,
    removeNotification,
  },
};

export default resolvers;
