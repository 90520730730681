import styled from 'styled-components';
import { Colors, Fonts } from '@whispir/ui-lib-v2';

export const ContentWrapper = styled.div`
  height: 100%;

  p {
    ${Fonts.body};
  }
`;

export const HomepageBody = styled.div`
  background-color: ${Colors.background};
  /* Subtract the height of the HomePageBanner */
  height: ${(props) => (props.isLoading ? 'calc(100vh - 300px)' : 'initial')};
  width: 100%;
  box-sizing: border-box;

  .homepage-body-header {
    padding-left: 32px;
    padding-top: 24px;
    ${Fonts.headline};
    margin-bottom: 24px;
  }

  a {
    text-decoration: none;
  }
`;

export const LoaderWrapper = styled.div`
  margin-top: 56px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
`;

export const WorkflowCardWrapper = styled.div`
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  animation-name: fade-in;
  animation-fill-mode: both;
  animation-duration: 0.5s;
`;

export const WorkflowTableWrapper = styled.div`
  transition: all 0.3s ease-in-out;
  overflow: hidden;

  &.loading {
    .add-filter {
      pointer-events: none;
    }

    .MuiTable-root.spinner-wrapper {
      height: 700px;
    }
  }

  .MuiTableContainer-root .MuiTable-root.spinner-wrapper {
    height: 700px;
  }

  .status-cell {
    width: 20%;
  }

  .publishDate-cell,
  .name-cell {
    width: 20%;
  }

  .MuiSelect-icon {
    fill: ${Colors.grey_blue};
    width: 20px;
  }

  .MuiTablePagination-root {
    color: ${Colors.grey_blue};
  }

  .status-wrapper {
    display: flex;
    padding: 8px 8px 8px 0;
    align-items: center;

    .active-status-icon {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      border: 4px solid ${Colors.success}50;
      background-color: ${Colors.success};
      margin-right: 8px;
      background-clip: padding-box;
    }

    .draft-status-icon {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      border: 4px solid ${Colors.accent_5}50;
      background-color: ${Colors.accent};
      margin-right: 8px;
      background-clip: padding-box;
    }

    .archived-status-icon {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      border: 4px solid ${Colors.grey_blue_2}50;
      background-color: ${Colors.grey_blue_4};
      margin-right: 8px;
      background-clip: padding-box;
    }
  }

  .workflow-name-wrapper {
    display: flex;

    .workflow-icon {
      background-color: ${Colors.background}
      fill: ${Colors.accent_5};
      border-radius: 50%;
      height: 32px;
      width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;

      svg {
        width: 20px;
        height: 20px;
      }

      &.disabled { 
        fill: ${Colors.grey_blue_4};
      }
    }

    .workflow-name {
      padding-left: 4px;
      margin: auto 0;
    }
  }
`;
