import React from 'react';
import { prefix } from '../../router/routerConstants';
import { Illustrations, ErrorScreen, Button } from '@whispir/ui-lib-v2';

export const ApiError = (props) => {
  const {
    error: { graphQLErrors },
    message: errorMessage,
    history,
  } = props;

  if (errorMessage) {
    return <ErrorScreen />;
  }

  if (graphQLErrors.length) {
    const [errorInfo] = graphQLErrors;
    const {
      extensions: { code: errorCode },
    } = errorInfo;

    switch (errorCode) {
      case 'FORBIDDEN':
        return (
          <ErrorScreen
            title="Oops! You Don’t Have Access"
            banner={<Illustrations.Climber />}
            errorMessage="Sorry, it looks like this workflow was created under a different account. You don’t have the right access permissions. "
            backButton={
              <Button
                variant="link"
                text="Back to My Workflows"
                onClick={() => history.push(prefix)}
              />
            }
          />
        );

      case 'NOT_FOUND':
        return (
          <ErrorScreen
            title="Hmmm … We Can’t Find That Workflow!"
            banner={<Illustrations.Climber />}
            errorMessage="Sorry, it looks like that Workflow is no longer available. Please check if it’s been archived. "
            backButton={
              <Button
                variant="link"
                text="Back to My Workflows"
                onClick={() => history.push(prefix)}
              />
            }
          />
        );

      default:
        return <ErrorScreen />;
    }
  }
  return <ErrorScreen />;
};
