import styled from 'styled-components';
import { Modal, Colors, Fonts } from '@whispir/ui-lib-v2';

export const SMSBuilderWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 162px);
  background-color: ${Colors.white};

  .sms-panel-wrapper {
    margin: 0 auto;
    max-width: 648px;
    right: 200px;
    top: 70px;
    position: relative;
  }

  .sms-preview {
    height: calc(100vh - 144px);
    top: 106px;
    right: 32px;
  }

  .variable-info-wrapper {
    z-index: 0;
    right: 200px;
    top: 100px;
    position: relative;
    margin: 0 auto;

    .warning {
      display: ${({ hasVariable }) => !hasVariable && 'none'};
    }
  }
`;

export const SmsPanelWrapper = styled.div`
  width: 648px;
  margin: 80px auto 0;
`;

export const StyledModal = styled(Modal)`
  .MuiDialog-paper {
    padding: 0;
  }
  .exit-button-wrapper {
    display: none;
  }

  .modal-body {
    /* 64px - height of modal-header */
    height: calc(100% - 64px);
  }
`;

export const StyledModalBody = styled.div`
  ${Fonts.body};
`;
