import React, { PureComponent, Fragment } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { Snackbar as SnackbarContent } from '@whispir/ui-lib-v2';
import { SnackbarWrapper } from './Notification.style';

export class Notification extends PureComponent {
  static defaultProps = {
    notifications: [],
  };

  state = {
    open: false,
  };

  componentDidMount() {
    const { notifications } = this.props;

    const hasNotifications = notifications.length > 0;
    hasNotifications && this.checkIfIsOpen();
  }

  componentDidUpdate(prevProps) {
    const { notifications } = this.props;

    const hasNotifications =
      notifications.length > 0 &&
      notifications.length !== prevProps.notifications.length;
    hasNotifications && this.checkIfIsOpen();
  }

  checkIfIsOpen = () => {
    const { open } = this.state;
    if (open) {
      // immediately begin dismissing current message
      // to start showing new one
      this.closeSnackbar();
    } else {
      this.processQueue();
    }
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.closeSnackbar();
  };

  handleExited = () => {
    this.processQueue();
  };

  openSnackbar = () => {
    this.setState({
      open: true,
    });
  };

  closeSnackbar = () => {
    const { onClose, notifications } = this.props;
    this.setState(
      {
        open: false,
      },
      onClose(notifications[0].id),
    );
  };

  processQueue = () => {
    const { notifications } = this.props;
    if (notifications.length > 0) {
      this.openSnackbar();
    } else {
      this.closeSnackbar();
    }
  };

  render() {
    const { notifications } = this.props;
    const { open } = this.state;
    const isVisible = open;
    const hasNotifications = notifications.length;

    return (
      <Fragment>
        {hasNotifications && (
          <SnackbarWrapper>
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              open={isVisible}
              autoHideDuration={6000}
              onClose={this.handleClose}
              onExited={this.handleExited}
              className="wrapper"
              key={notifications[0].id}
            >
              <SnackbarContent
                severity={notifications[0].type}
                message={
                  <span id="message-id">{notifications[0].message}</span>
                }
                onClose={this.handleClose}
              />
            </Snackbar>
          </SnackbarWrapper>
        )}
      </Fragment>
    );
  }
}

export default Notification;
