import React, { Component } from 'react';
import { ApolloProvider } from 'react-apollo';
import { CachePersistor } from 'apollo-cache-persist';
import createClient from './createClient';
import cache from './cache';

const SCHEMA_VERSION = '3'; // Must be a string.
const SCHEMA_VERSION_KEY = 'apollo-schema-version';

const graphQLServer = process.env.REACT_APP_GRAPHQL_SERVER;
class ApolloProviderWrapper extends Component {
  state = {
    client: null,
    loaded: false,
  };

  async componentWillMount() {
    try {
      await this.initApolloClient();
    } catch (error) {
      console.error('Error mounting ApolloProviderWrapper', error);
    }
  }

  async initApolloClient() {
    const client = createClient(graphQLServer);
    const persistor = new CachePersistor({
      cache,
      storage: localStorage,
    });

    // Read the current schema version from localStorage.
    const currentVersion = localStorage.getItem(SCHEMA_VERSION_KEY);

    if (currentVersion === SCHEMA_VERSION) {
      // If the current version matches the latest version,
      // we're good to go and can restore the cache.
      await persistor.restore();
    } else {
      // Otherwise, we'll want to purge the outdated persisted cache
      // and mark ourselves as having updated to the latest version.
      await persistor.purge();
      localStorage.setItem(SCHEMA_VERSION_KEY, SCHEMA_VERSION);
    }

    client.initQueryManager();

    this.setState({
      client,
      loaded: true,
    });
  }

  render() {
    const { client, loaded } = this.state;
    const { children } = this.props;

    if (!loaded) {
      return <div>Loading...</div>;
    }

    return <ApolloProvider client={client} children={children} />;
  }
}

export default ApolloProviderWrapper;
