import React from 'react';
import { Route } from 'react-router';

const getRoute = (route, prefix = '') => (
  <Route
    path={route.path}
    key="fixed"
    exact={route.exact}
    prefix={prefix}
    component={route.component}
    test={null}
  />
);

export { getRoute };
