import styled from 'styled-components';
import {
  WorkflowCanvasNode,
  Fonts,
  Colors,
  transitionStyle,
} from '@whispir/ui-lib-v2';
import { NodeWidth, WithError } from '../Node/Node.style';

export const StyledSplitWrapper = styled(WorkflowCanvasNode)`
  margin: 0px;
  z-index: 3;

  :hover {
    cursor: grab;
  }

  .title {
    ${Fonts.capsHeading};
    color: ${Colors.grey_blue_4};
    margin: 0px;
  }

  .description {
    ${Fonts.body};
    margin: 0px;
  }

  .state {
    ${Fonts.body};
    padding: 0 16px;
    max-width: ${NodeWidth};
    transition: color ${transitionStyle};

    &.dirty {
      color: ${Colors.accent};
    }

    &.error {
      ${WithError}
      color: ${Colors.error};
    }
  }
`;
