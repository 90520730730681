import { AxiosInstance } from 'axios';
import { useCallback } from 'react';
import { DEACTIVATE_WORKFLOW_MUTATION } from '../apollo/mutations/workflow-mutation';
import { useLoading } from './useLoading';

/**a
 * This API endpoint deletes a dormant workflow record. 
 * Workflows that have been deactivated or have never been triggered before
 */
export const useDeactivateWorkflow = ({ api }: { api: AxiosInstance }) => {
  const deactivateWorkflow = useCallback(
    async (body) => {
      const { data } = await api.request({
        data: {
          operationName: 'deactivateWorkflow',
          variables: {
            id: body.id
          },
          query: DEACTIVATE_WORKFLOW_MUTATION
        }
      })

      return data;

    },
    [api],
  );

  return useLoading(deactivateWorkflow);
};
