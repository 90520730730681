//
// $FlowFixMe
import gql from 'graphql-tag';

export const GET_TRIGGERED_WORKFLOW_QUERY = `
  query getTriggeredWorkflow($workflowId: String!) {
    getTriggeredWorkflow(workflowId: $workflowId) {
      workflowEndDate
    }
  }
`;

export const GET_TRIGGERED_WORKFLOWS_QUERY = `
  query getTriggeredWorkflows($workspaceId: String!) {
    getTriggeredWorkflows(workspaceId: $workspaceId) {
      workflowId
      workflowEndDate
    }
  }
`;

export const GET_WORKFLOW_QUERY = `
  query getWorkflow($id: String!) {
    getWorkflow(id: $id) {
      id
      name
      modified
      created
      creatorUserName
      creatorUserId
      companyId
      workspaceId
      modifierUserName
      modifierUserId
      definition
      version
      clonedFrom
      clonedDate
      archiverUserName
      archiverUserId
      archivedDate
    }
  }
`;
export const GET_WORKFLOWS_QUERY = `
  query getUserWorkflows(
    $pageSize: Int!
    $offset: Int
  ) {
    getUserWorkflows(
      pageSize: $pageSize
      offset: $offset
    ) {
      id
      name
      modified
      created
      creatorUserName
      creatorUserId
      companyId
      workspaceId
      modifierUserName
      modifierUserId
      definition
      clonedFrom
      clonedDate
      archiverUserName
      archiverUserId
      archivedDate
    }
  }
`;

export const GET_PAGINATED_WORKFLOWS = `
query getPaginatedUserWorkflows(
  $pageSize: Int!
  $offset: Int
) {
  getPaginatedUserWorkflows(
    pageSize: $pageSize
    offset: $offset
  ) {
    data {
      id
      name
      modified
      created
      creatorUserName
      creatorUserId
      companyId
      workspaceId
      modifierUserName
      modifierUserId
      definition
      clonedFrom
      clonedDate
      archiverUserName
      archiverUserId
      archivedDate
    }
    hasNextPage 
    totalCount
  }
}
`;

// These are for apollo client usage
export const GET_TRIGGERED_WORKFLOWS = gql(GET_TRIGGERED_WORKFLOWS_QUERY);
export const GET_TRIGGERED_WORKFLOW = gql(GET_TRIGGERED_WORKFLOW_QUERY);
export const GET_WORKFLOW = gql(GET_WORKFLOW_QUERY);
export const GET_WORKFLOWS = gql(GET_WORKFLOWS_QUERY);

export const GET_USER_WORKSPACES = gql`
  query getWorkspaces($pageSize: Int!, $pageNo: Int!) {
    user {
      userId
      rawUserId
      companyId
      userName
      details {
        companyName
        companyRole
        createdAt
        firstName
        lastName
        productTier
        userRole
        workEmailAddress1
        salesForceBillingId
      }
    }
    workspaces(pageSize: $pageSize, pageNo: $pageNo) {
      workspaceId
      workspaceName
    }
    defaultWorkspace {
      workspaceId
    }
  }
`;

export const GET_NOTIFICATIONS_LOCAL = gql`
  {
    notifications @client {
      id
      type
      message
    }
  }
`;

export const GET_USER = gql`
  query getUser {
    user {
      userId
      rawUserId
      companyId
      userName
      details {
        companyName
        companyRole
        createdAt
        firstName
        lastName
        productTier
        userRole
        workEmailAddress1
        salesForceBillingId
      }
    }
  }
`;

export const STRIPE_CUSTOMER = gql`
  query stripeSelfServePortal($returnUrl: String!) {
    stripeSelfServePortal(returnUrl: $returnUrl) {
      status
      errorText
      stripePortalUrl
    }
  }
`;
