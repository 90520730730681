//
import React from 'react';
import { Router, Route } from 'react-router-dom';
import {
  createIsToggleEnabled,
  FeatureToggleProvider,
  SegmentContextProvider,
} from '@whispir/ui-lib-v2';
import { UserProvider } from '../state/User.context';
import { NotificationsProvider } from '../components/Notification';
import routeConfigs from './routeConfig';
import Layout from '../components/Layout';

// This is needed because hook can't be called inside Routes
const FTProviderWrapper = ({ children }) => {
  return (
    <FeatureToggleProvider
      isToggleEnabled={createIsToggleEnabled(process.env.REACT_APP_REGION)}
    >
      {children}
    </FeatureToggleProvider>
  );
};

export const Routes = (history) => {
  return (
    <Router history={history}>
      <Route path="/workflows">
        <Route
          component={({ location }) => (
            <UserProvider
              initialState={{
                user: {
                  details: {},
                },
              }}
            >
              <NotificationsProvider>
                <SegmentContextProvider>
                  <FTProviderWrapper>
                    <Layout>{routeConfigs(location)}</Layout>
                  </FTProviderWrapper>
                </SegmentContextProvider>
              </NotificationsProvider>
            </UserProvider>
          )}
        />
      </Route>
    </Router>
  );
};

export default Routes;
