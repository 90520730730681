//
// $FlowFixMe
import { expressionGenerators } from '@whispir/expression-helper';
import { defaultExpression } from '@whispir/workflow-data-model';

const {
  generateExpression,
  generateAndExpression,
  generateOrExpression,
} = expressionGenerators;

const createAndExpression = (index = 0, expressionState = {}) => {
  let clonedExpression = JSON.parse(JSON.stringify(expressionState));
  const { operands, operator } = clonedExpression;
  let hasNestedAndOperand = Boolean(
    operands && operands.length && operands[index].operator === 'and',
  );
  const hasOperands = Boolean(operands && operands.length);
  const hasOrOperand = Boolean(
    operands && operands.length && operator === 'or',
  );

  if (!hasOperands) {
    clonedExpression = generateAndExpression({
      operands: [expressionState],
    });
  } else if (hasOrOperand && !hasNestedAndOperand) {
    clonedExpression.operands[index] = generateAndExpression({
      operands: [expressionState.operands[index]],
    });

    hasNestedAndOperand = true;
  }

  if (hasNestedAndOperand)
    clonedExpression.operands[index].operands.push(defaultExpression());
  else clonedExpression.operands.push(defaultExpression());

  return clonedExpression;
};

const createOrExpression = (expressionState = {}) => {
  let clonedExpression = JSON.parse(JSON.stringify(expressionState));
  const { operands, operator } = clonedExpression;
  const hasOrOperand = Boolean(operator === 'or' && operands);

  if (!hasOrOperand) {
    clonedExpression = generateOrExpression({
      operands: [expressionState],
    });
  }

  clonedExpression.operands.push(defaultExpression());

  return clonedExpression;
};

const deleteExpression = (parentIndex = 0, id = null, expressionState) => {
  const clonedExpression = JSON.parse(JSON.stringify(expressionState || {}));
  const { operands, operator } = clonedExpression;
  const hasOrOperand = Boolean(operator === 'or' && operands);
  const hasAndInOrOperand = Boolean(
    hasOrOperand &&
      parentIndex !== null &&
      operands[parentIndex].operator === 'and',
  );

  if (hasAndInOrOperand) {
    const nestedOperands = operands[parentIndex].operands;

    if (nestedOperands && nestedOperands.length > 1) {
      deleteExpressionById(nestedOperands, id);

      return clonedExpression;
    } else if (nestedOperands.length === 1) {
      const [expressionItem] = deleteExpressionById(nestedOperands, id);
      operands[parentIndex] = expressionItem;
    }
  }

  if (operands && operands.length > 1) deleteExpressionById(operands, id);

  return clonedExpression;
};

const replaceExpression = (
  parentIndex = 0,
  expressionState,
  newExpressionItem,
) => {
  let clonedExpression = JSON.parse(JSON.stringify(expressionState || {}));
  const { operands, operator } = clonedExpression;
  const hasOrOperand = Boolean(operator === 'or' && operands);
  const hasAndInOrOperand = Boolean(
    hasOrOperand &&
      parentIndex !== null &&
      operands[parentIndex].operator === 'and',
  );

  if (hasAndInOrOperand) {
    const nestedOperands = operands[parentIndex].operands;
    updateExpressionValuesById(nestedOperands, newExpressionItem);
  } else if (operands) {
    updateExpressionValuesById(operands, newExpressionItem);
  } else {
    clonedExpression = newExpressionItem;
  }

  return clonedExpression;
};

const deleteExpressionById = (operands = [], id) => {
  if (id)
    return operands.splice(
      operands.findIndex((item) => item.id === id),
      1,
    );

  return operands;
};

const updateExpressionValuesById = (expressionList = [], newExpressionItem) => {
  const { operator, left = {}, right = {}, id = null } = newExpressionItem;
  const index = id ? expressionList.findIndex((item) => item.id === id) : null;

  if (index !== null) {
    expressionList[index] = generateExpression({
      id,
      operator,
      left,
      right,
    });
  }

  return expressionList;
};

export {
  createAndExpression,
  createOrExpression,
  deleteExpression,
  replaceExpression,
};
