/* eslint-disable max-lines */
import React, { Fragment } from 'react';
import { Popper, Paper, Chip as MUIChip } from '@material-ui/core';
import {
  expressionGenerators,
  variableGenerators,
} from '@whispir/expression-helper';
import {
  VariableChip,
  createExpressionLabel,
  ConditionPicker,
} from '@whispir/ui-lib-v2';

import {
  ExpressionInputWrapper,
  ConditionValidationText,
  Field,
  StyledVariableSelectorPopover,
} from './ExpressionInput.style';

const { generateExpression } = expressionGenerators;
const { generateVariable } = variableGenerators;

const emptyValueMessage =
  '<p class="validation condition__validation-message">Missing value</p>';

export const selectVariableText = '+ Select Variable';

export const findVariableForExpression = (expression, variableGroups) => {
  const { left } = expression;
  if (left) {
    const { variableId, stepId } = left;
    const flattenedVariables = variableGroups.reduce(
      (acc, cur) => [...acc, ...cur.variables],
      [],
    );
    const foundVariable = flattenedVariables.find((v) => {
      // intentional type-coercion - compare number strings and numbers
      // eslint-disable-next-line eqeqeq
      return v.variableId === variableId && v.stepId == stepId;
    });

    if (!foundVariable) {
      console.warn(
        'You have a variable, but that variable was not found in the variables list',
      );
    }

    return foundVariable;
  }

  return undefined;
};

class ExpressionInput extends React.PureComponent {
  anchorEl;

  constructor(props) {
    super(props);
    const { variables, expressionValue } = this.props;
    const { operator, right } = expressionValue;

    const foundVariable = findVariableForExpression(expressionValue, variables);

    this.state = {
      variable: foundVariable,
      condition: {
        operator,
        operand: right,
      },
      mode: 'variables',
      open: false,
    };

    this.anchorEl = React.createRef(null);
  }

  componentDidMount = () => {
    document.addEventListener('keydown', this.handleKeyDown, false);
  };

  componentDidUpdate(prevProps, prevState) {
    const { variable, condition } = this.state;
    if (prevState.variable !== variable || prevState.condition !== condition) {
      this.handleChange();
    }
  }

  componentWillUnmount = () => {
    document.removeEventListener('keydown', this.handleKeyDown, false);
  };

  createVariableLabel = (chipClass) => {
    const { variable } = this.state;
    let label = selectVariableText;
    if (variable && variable.variableName) {
      label = variable.variableName;
    }

    return variable ? (
      <VariableChip
        variable={variable}
        onClick={() => this.handleOpen('variables')}
      />
    ) : (
      label
    );
  };

  createConditionLabel = () => {
    const { condition } = this.state;
    return createExpressionLabel(condition);
  };

  handleOpen = (mode) => {
    const { open } = this.state;
    this.setState({
      open: !open,
      mode,
    });
  };

  handleClear = () => {
    this.setState(
      {
        variable: null,
        condition: {
          operator: null,
          operand: null,
        },
        open: false,
        hasValidationErrors: false,
      },
      () => {
        this.validationMessage.innerHTML = null;
      },
    );
  };

  handleValidationMessages = () => {
    const {
      expressionValue: { right, left },
    } = this.props;

    const hasInvalidValue =
      left && left.variableId ? !(right && right.value) : false;

    if (hasInvalidValue) {
      this.validationMessage.innerHTML = emptyValueMessage;
      this.setState({ hasValidationErrors: true });
    } else {
      this.validationMessage.innerHTML = null;
      this.setState({ hasValidationErrors: false });
    }
  };

  handleClose = () => {
    this.handleValidationMessages();
    this.setState({ open: false });
    this.handleChange();
  };

  handleKeyDown = (e) => {
    if (e.keyCode === 27) {
      this.handleClose();
    }
  };

  conditionChangeHandler = (condition) => {
    this.setState({
      condition,
    });
  };

  variableChangeHandler = (variable) => {
    this.setState({
      variable,
      mode: 'condition',
    });
  };

  handleChange = () => {
    const { expressionValue, onChange } = this.props;
    const { variable, condition } = this.state;
    const { id } = expressionValue;

    let value;
    if (condition && variable) {
      const { operator, operand } = condition;

      const left = generateVariable({
        variableId: variable.variableId,
        // the workflow validaton expects this prop value to be string instead of number
        stepId: variable.stepId.toString(),
      });

      value = generateExpression({
        id,
        operator,
        right: operand,
        left,
      });
    } else {
      value = generateExpression({
        id,
        operator: null,
        right: null,
        left: null,
      });
    }

    onChange && onChange(value);
  };

  renderConditionsPicker = (condition, anchorEl) => {
    const { variable } = this.state;
    if (!variable) {
      return null;
    }

    const {
      variable: { type, options },
      open,
    } = this.state;

    const jsonOptions =
      typeof options === 'string' ? JSON.parse(options) : options;
    if (typeof options === 'string') {
      console.warn(
        'The options object that is coming from state, is coming as a string!',
      );
    }

    const modifiedOptions = (jsonOptions || []).map((option) => ({
      // TODO: remove fallback when fully migrated using content builder
      label: option.text || option.label,
      value: option.text || option.label,
    }));

    return (
      <Popper
        disablePortal
        anchorEl={anchorEl}
        open={open}
        onClose={this.handleInputsClose}
        placement="bottom"
        className="expression-popper"
        modifiers={{
          // The configuration is to deal with the scenario where the popper will overflow the parent.
          // It forces the popper to always appear in the same place relative to the anchorEl
          // And does a little bit of a scroll if it needs to.
          preventOverflow: {
            enabled: true,
            boundariesElement: 'scrollParent',
          },
          hide: {
            enabled: false,
          },
        }}
      >
        <Paper elevation={5} style={{ cursor: 'pointer' }}>
          <ConditionPicker
            defaultValues={condition}
            formControlType={type}
            onChange={this.conditionChangeHandler}
            onClose={this.handleClose}
            operandOptions={modifiedOptions}
          />
        </Paper>
      </Popper>
    );
  };

  renderVariablesMenu = (variables, anchorEl) => {
    const { open } = this.state;
    return (
      <StyledVariableSelectorPopover
        onClose={this.handleClose}
        open={open}
        anchorEl={anchorEl}
        variableGroups={variables}
        onVariableSelect={this.variableChangeHandler}
      />
    );
  };

  renderContent = (anchorEl) => {
    const { variables } = this.props;
    const { mode, condition } = this.state;
    const content =
      mode === 'variables'
        ? this.renderVariablesMenu(variables, anchorEl)
        : this.renderConditionsPicker(condition, anchorEl);
    return content;
  };

  render() {
    const { open, variable, condition, hasValidationErrors } = this.state;
    const showConditionChip = variable;
    const showClearButton =
      (variable && variable.variableName) || (condition && condition.operator);

    const chipClass =
      variable && variable.variableName
        ? 'expression-chip lozenge'
        : 'expression-chip naked-chip';
    return (
      <Fragment>
        <ExpressionInputWrapper>
          <Field
            hasValidationErrors={hasValidationErrors}
            className={`${open ? 'open' : ''} variable-selector`}
            ref={this.anchorEl}
            onKeyDown={this.handleKeyDown}
            onClick={() => !variable && this.handleOpen('variables')}
            aria-label={variable ? null : selectVariableText}
          >
            {this.createVariableLabel(chipClass)}
            {showConditionChip && (
              <MUIChip
                label={this.createConditionLabel()}
                onClick={() => this.handleOpen('condition')}
                classes={{ root: 'expression-chip naked-chip' }}
              />
            )}
            {showClearButton && (
              <MUIChip
                label=""
                onDelete={() => this.handleClear()}
                classes={{ root: 'clear-chip' }}
              />
            )}
          </Field>
          {this.anchorEl.current && this.renderContent(this.anchorEl.current)}
        </ExpressionInputWrapper>
        <ConditionValidationText className="validation-text">
          <div
            ref={(validationMessage) =>
              (this.validationMessage = validationMessage)
            }
          />
        </ConditionValidationText>
      </Fragment>
    );
  }
}

ExpressionInput.displayName = 'ExpressionInput';

export default ExpressionInput;
