/* eslint-disable react/no-unused-prop-types*/
// Somethign weird going on with flow and eslint
import React from 'react';
import { Icons, useFeatureToggle } from '@whispir/ui-lib-v2';
import { StyledOptionsWrapper } from './OptionsSideDrawer.style';

const FancyOptionButton = ({
  isComingSoon,
  title,
  dataButtonType,
  actionType,
  icon,
  description,
  heading,
  onActionSelect,
}) => {
  return (
    <button
      type="button"
      className={isComingSoon ? 'coming-soon' : 'large-icon-button'}
      disabled={isComingSoon}
      title={title}
      data-button={dataButtonType}
      onClick={() => onActionSelect(actionType)}
    >
      <div className="icon-wrapper">{icon}</div>
      {isComingSoon ? (
        <div className="coming-soon-text">coming soon</div>
      ) : null}
      <div className="heading">{heading}</div>
      <span className="description">{description}</span>
    </button>
  );
};

const SideDrawerOptions = ({ onActionSelect, previousNode }) => {
  const { isToggleEnabled } = useFeatureToggle();
  const { type: nodeType } = previousNode;
  const isSplitNode = previousNode.next && previousNode.next.length > 1;

  // This needs to live insde the component
  // So that 'isToggleEnabled' will work
  const optionList = {
    controls: [
      {
        title: 'Expression',
        dataButtonType: 'expression',
        actionType: 'expression',
        icon: <Icons.Condition />,
        description: 'Add a decision point to your workflow',
        heading: 'If Condition',
      },
      {
        title: 'Time',
        dataButtonType: 'time',
        actionType: 'time',
        icon: <Icons.Processing />,
        description: 'Pause this path for a specified period',
        heading: 'Wait',
      },
    ],
    connectors: [
      {
        title: 'Split',
        dataButtonType: 'split',
        actionType: 'split',
        icon: <Icons.Branch />,
        description: 'Split this workflow into multiple paths',
        heading: 'Branch Out',
      },
      // This option is temporarily hidden
      // {
      //   title: 'Go To',
      //   dataButtonType: 'goto',
      //   actionType: 'split',
      //   icon: <Icons.GoTo />,
      //   description: 'Connect this part to another',
      //   heading: 'Go To'
      // }
    ],
    communication: [
      {
        title: 'Send SMS',
        dataButtonType: 'send-sms-message',
        actionType: 'send-sms',
        icon: <Icons.Message />,
        description: 'Send a text message',
        heading: 'SMS',
      },
      {
        title: 'Send Email',
        dataButtonType: 'send-email-message',
        actionType: 'send-email',
        icon: <Icons.Email />,
        description: 'Send an email message',
        heading: 'Email',
      },
      ...(isToggleEnabled('webform-responses')
        ? [
            {
              title: 'Send Webform',
              dataButtonType: 'send-webform-message',
              actionType: 'send-webform',
              icon: <Icons.WebForm />,
              description: 'Send a form and capture data',
              heading: 'Web Form',
            },
          ]
        : []),
    ],
  };

  return (
    <StyledOptionsWrapper>
      {Object.keys(optionList).map((type, index) => {
        if ((nodeType === 'empty' || isSplitNode) && type === 'connectors') {
          return '';
        }
        return (
          <div key={type} className="option-group-wrapper">
            <div className="option-type-title">{type}</div>
            {optionList[type].map(
              ({
                title,
                dataButtonType,
                actionType,
                icon,
                description,
                heading,
              }) => (
                <FancyOptionButton
                  key={description}
                  title={title}
                  dataButtonType={dataButtonType}
                  actionType={actionType}
                  icon={icon}
                  description={description}
                  onActionSelect={onActionSelect}
                  heading={heading}
                  isComingSoon={title === 'Go To'}
                />
              ),
            )}
          </div>
        );
      })}
    </StyledOptionsWrapper>
  );
};

export const Options = ({ onActionSelect, previousNode }) => {
  return (
    <SideDrawerOptions
      onActionSelect={onActionSelect}
      previousNode={previousNode}
    />
  );
};

export default Options;
