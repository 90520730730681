//
import React from 'react';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from '@whispir/ui-lib-v2';
import { StylesProvider, jssPreset } from '@material-ui/core';
import { StyleSheetManager } from 'styled-components';
import { create } from 'jss';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/core';
import ApolloProviderWrapper from './apollo/ApolloProviderWrapper';
import { default as router } from './router/Router';

const environmentVariablesKey = [
  'REACT_APP_GRAPHQL_SERVER',
  'REACT_APP_REGION',
  'REACT_APP_SEGMENT_ENVIRONMENT',
  'REACT_APP_CONTENT_API_URL',
  'REACT_APP_VIEWER_API_URL',
  'REACT_APP_GOOGLE_MAP_API_KEY',
  'REACT_APP_SASS_COMPILER',
];

const CUSTOM_ELEMENT_TAG = 'wsp-microfrontend-workflows';

class MicrofrontendWorkflows extends HTMLElement {
  constructor() {
    super();
    this.root = this.attachShadow({ mode: 'closed' });
  }

  connectedCallback() {
    const stylesMountPoint = document.createElement('section');

    const jss = create({
      ...jssPreset(),
      insertionPoint: stylesMountPoint,
    });

    const emotionCache = createCache({
      key: 'wsp-microfrontend-workflows',
      container: stylesMountPoint,
    })

    ReactDOM.render(
      // Taken from:
      // - https://www.wpeform.io/blog/render-react-app-shadow-dom-styled-components/
      // - https://stackoverflow.com/a/57128971
      // Inject the the MUI styles and styled-components styles into the shadowRoot node
      <CacheProvider value={emotionCache}>
        <StylesProvider jss={jss}>
          <StyleSheetManager target={stylesMountPoint}>
            <ErrorBoundary>
              {environmentVariablesKey.forEach((key) => {
                if (!(key in process.env)) {
                  throw new Error(`Environment variable "${key}" is undefined`);
                }
              })}
              <ApolloProviderWrapper>{router(window.wspHistory)}</ApolloProviderWrapper>
            </ErrorBoundary>
          </StyleSheetManager>
        </StylesProvider>
      </CacheProvider>,
      this.root
    );
    this.root.appendChild(stylesMountPoint);
  }

  disconnectedCallback() {
    ReactDOM.unmountComponentAtNode(this.root);
  }
}

if (customElements.get(CUSTOM_ELEMENT_TAG) === undefined) {
  customElements.define(CUSTOM_ELEMENT_TAG, MicrofrontendWorkflows);
}
