import { Colors } from '@whispir/ui-lib-v2';
import styled from 'styled-components';

export const MainWrapper = styled.div`
  background-color: ${Colors.background};
  display: flex;
  max-width: 100%;
  height: 100%;
`;

export const ContentWrapper = styled.div`
  height: inherit;
  position: relative;
  flex-grow: 2;
  transition: all 0.3s ease-in-out;
  overflow-y: auto;
`;

export const LoadingWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
