import React from 'react';
import { ConfirmationModal, Icons } from '@whispir/ui-lib-v2';
import { StyledTitle } from './DeleteVariableWarningModal.style';

export const DeleteWarningModalTitle = () => {
  return (
    <StyledTitle>
      <Icons.Warning />
      <span>Are you sure?</span>
    </StyledTitle>
  );
};

export const DeleteWarningModalBody = (props) => {
  const { isUsingMayLanguage = false } = props;

  return (
    <React.Fragment>
      <p>
        The item you are trying to delete{' '}
        {isUsingMayLanguage
          ? 'might be in use elsewhere in your workflow.'
          : 'is being used in another part of your workflow.'}
      </p>
      <p>
        <strong>Deleting it may cause your workflow to break.</strong>
      </p>
      <p>
        {isUsingMayLanguage
          ? 'Are you sure you want to delete this component?'
          : 'Please remove it from your workflow first, then try deleting it again.'}
      </p>
    </React.Fragment>
  );
};

const DeleteVariableWarningModal = ({ onConfirm, onCancel, open }) => (
  <ConfirmationModal
    title={<DeleteVariableWarningModal />}
    content={<DeleteWarningModalBody isUsingMayLanguage={false} />}
    handleAction={onConfirm}
    handleExit={onCancel}
    isOpen={open}
    actionButtonText="Delete It Anyway"
    exitButtonText="Cancel"
  />
);

export default DeleteVariableWarningModal;
