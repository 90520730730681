//
import React, { PureComponent } from 'react';
// $FlowFixMe
import withNamedMutation from '../../apollo/hoc/withNamedMutation';
import { CREATE_NOTIFICATION_LOCAL } from '../../apollo/mutations/workflow-mutation';

export class CreateNotification extends PureComponent {
  static defaultProps = {
    children: <div />,
    addNotification: {
      submit: () => null,
      isSuccess: false,
      isLoading: false,
      error: '',
    },
  };

  componentDidMount() {
    this.createNotification();
  }

  createNotification = () => {
    const { addNotification, notification } = this.props;
    addNotification.submit({ notification });
  };

  render() {
    const {
      children,
      addNotification: { isSuccess },
    } = this.props;
    return isSuccess && children;
  }
}

export default withNamedMutation(CREATE_NOTIFICATION_LOCAL, {
  name: 'addNotification',
})(CreateNotification);
