import styled from 'styled-components';
import { Colors, transitionStyle, Fonts } from '@whispir/ui-lib-v2';
import { IconSize } from '../TreeDiagram/Node/Node.style';

export const NodeWrapper = styled.div`
  button {
    cursor: pointer;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: solid 2px ${Colors.accent_3};
    background-color: transparent;
    width: ${IconSize};
    height: ${IconSize};
    border-radius: 50%;
    transition: border ${transitionStyle};

    svg {
      fill: ${Colors.accent_3};
      transition: fill ${transitionStyle};
    }

    &:hover,
    &:focus,
    &.active {
      border: solid 2px ${Colors.accent};
      .plusWrapper {
        border: solid 2px ${Colors.accent};
      }
        svg {
          fill: ${Colors.accent};
        }
      }
    }
  }

  .vertical-connector {
    border-left: ${Colors.grey_blue_5} solid 1px;
    display: block;
    margin: 0 auto;
    width: 1px;
    position: relative;
  }

  .arrow-icon {
    fill: ${Colors.grey_blue_5};
    margin-top: -4px;
    width: 9px;
    height: 6px;
  }

  /* Style overrides for between nodes */
  &.between-node-button {
    button {
      width: 50px;
      height: 60px;
      border-color: transparent;
    }

    .vertical-connector {
      height: 60px;
    }

  }

  /* Style overrides for leaf nodes */
  &.leaf-node-button {
    & button {
      width: ${IconSize};
      height: ${IconSize};
    }

    & .vertical-connector {
      border-color: ${Colors.accent_3};
      height: 32px;
    }
  }


  /* The plus that lives inside the between-node button */
  /* Also has a tooltip */
  .plusWrapper {
    display: flex;
    align-items: center;
    border: solid 2px transparent;
    background-color: ${Colors.background};
    margin: -45px 0 16px 0;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    transition: border ${transitionStyle};
    z-index: 2;

    div {
      padding-top: 2px;
    }

    .tooltip-wrapper {
      padding: 8px;
      top: -4px;
      min-width: 80px;

      p {
        ${Fonts.caption};
        color: ${Colors.white};
      }

      &:before {
        top: 12px;
        left: -5px;
      }
    }

    svg {
      fill: ${Colors.accent_3};
      transition: fill ${transitionStyle};
      width: 100%;
    }
  }
`;

export const StyledCloseButton = styled.div`
  .sticky-cancel-button {
    overflow: hidden;
    padding: 0px 24px 10px 2px;
    bottom: 0;
    background-color: white;
    .divider-form-control {
      padding: 0 0 18px;
    }
  }
`;
