/* eslint-disable react/no-array-index-key */
//
import React, { Component, Fragment } from 'react';
import cx from 'classnames';
import { findParentById } from '@whispir/workflow-data-model';
import { ACTION_TYPES } from '@whispir/workflow-factory';
import { WorkflowContext } from '../../state';
import { AddLeafNodeButton, AddBetweenNodeButton } from '../AddNodeButton';
import RemoveBranchButton from '../RemoveBranchButton';
import Node from './Node';
import Split from './Split';
import { OuterWrapper, ChildWrapper } from './TreeDiagram.style';
import { CollectResponsesSplit } from './CollectResponsesSplit/CollectReponsesSplit';

const {
  ON_SMS_REPLY,
  ON_SMS_NO_REPLY,
  ON_WEBFORM_REPLY,
  ON_WEBFORM_NO_REPLY,
} = ACTION_TYPES;

class TreeDiagram extends Component {
  static contextType = WorkflowContext;

  renderSplitUI = (step, index) => {
    const hasChildren = step.next && step.next.length > 1;
    const [ctx] = Array.isArray(this.context) ? this.context : [{}];
    const { workflow } = ctx;

    const isCollectResponses =
      hasChildren &&
      [ON_SMS_REPLY, ON_WEBFORM_REPLY].includes(step.next[0].action);
    return (
      hasChildren && (
        <Fragment>
          {!isCollectResponses && (
            <AddBetweenNodeButton
              previousNode={step}
              index={index}
              isDirectlyFollowedByABranch
            />
          )}
          {isCollectResponses ? (
            <CollectResponsesSplit
              node={step.next[0]}
              isValid={workflow && !workflow.hasErrorForNode(step.next[0].id)}
            />
          ) : (
            <Split previousNode={step} />
          )}
        </Fragment>
      )
    );
  };

  renderArrowAfterStep = (step, parentStep, index) => {
    const { data } = this.props;

    const collectResponsesNodes = [
      ON_SMS_REPLY,
      ON_SMS_NO_REPLY,
      ON_WEBFORM_REPLY,
      ON_WEBFORM_NO_REPLY,
    ];
    const isCollectResponses = collectResponsesNodes.includes(step.action);

    return (
      step.type !== 'start' && (
        <Fragment>
          {data && data.length > 1 && !isCollectResponses && (
            <RemoveBranchButton step={step} index={index} />
          )}
          {!(data[index].type === 'empty') && !isCollectResponses && (
            <AddBetweenNodeButton previousNode={parentStep} index={index} />
          )}
        </Fragment>
      )
    );
  };

  renderNodes = () => {
    const { data } = this.props;
    const [ctx] = Array.isArray(this.context) ? this.context : [{}];
    const { activeNodesId = [], workflow: { structure = [] } = {} } = ctx;

    return data && data.length ? (
      data.map((step, index) => {
        const deletingSplit = activeNodesId.length > 1;
        const isActiveNode = activeNodesId.includes(step.id);
        const isRootDeletedNode = activeNodesId.indexOf(step.id) === 0;
        const parentStep = findParentById(structure[0], step.id) || 0;
        const { length } = data;
        const hasSiblings = data && length > 1;
        const wrapperClasses = cx({
          'will-be-deleted': isActiveNode,
          'is-split': deletingSplit && isActiveNode && isRootDeletedNode,
          'has-siblings': hasSiblings,
          'is-first-branch': hasSiblings && index === 0,
          'is-last-branch': hasSiblings && index === length - 1,
          'is-edge-branch':
            hasSiblings && (index === 0 || index === length - 1),
          'is-vertex-branch':
            hasSiblings && index !== 0 && index !== length - 1,
        });

        return (
          <OuterWrapper key={`${step.id}-${index}`} className={wrapperClasses}>
            {this.renderArrowAfterStep(step, parentStep, index)}
            <Node node={step} nodeIndex={index} key={`${step.id}-${index}-b`} />
            {this.renderSplitUI(step, index)}
            {step.next ? (
              <ChildWrapper>
                <TreeDiagram data={step.next} />
              </ChildWrapper>
            ) : (
              <AddLeafNodeButton previousNode={step} />
            )}
          </OuterWrapper>
        );
      })
    ) : (
      <OuterWrapper>
        <AddLeafNodeButton />
      </OuterWrapper>
    );
  };

  render() {
    return this.renderNodes();
  }
}

export default TreeDiagram;
