import React from 'react';
// $FlowFixMe
import { Spinner, useFeatureToggle } from '@whispir/ui-lib-v2';
import { ApiError } from '../../components/ApiError/ApiError';
import { PageWrapper, LoaderWrapper } from './Builder.style';
import { BuilderContent } from './BuilderContent';
import { useWorkflowApi } from '../../hooks/useWorkflowApi';

const Builder = (props) => {
  const { error } = props;

  const workflowApis = useWorkflowApi();

  const { isToggleEnabled } = useFeatureToggle();
  const combinedProps = { ...props, ...workflowApis };

  if (error) {
    return <ApiError {...props} />;
  }

  return (
    <>
      <LoaderWrapper>
        <Spinner />
      </LoaderWrapper>
      <PageWrapper>
        <BuilderContent {...combinedProps} isToggleEnabled={isToggleEnabled} />
      </PageWrapper>
    </>
  );
};

export default Builder;
