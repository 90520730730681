import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { ColoredIcons } from '@whispir/ui-lib-v2';
import { prefix } from '../../router/routerConstants';
import {
  extractComponentContentVariables,
  getComponents,
} from '@whispir/workflow-data-model';
import WorkflowTriggerButton from '../WorkflowTriggerButton';
import { useWorkflowContext } from '../../state/Workflow.context';
import { useForceUpdate } from '../../state/utils';
import { EmbeddedChannelModal } from '../EmbeddedChannelModal';
import DeleteVariableWarningModal from '../DeleteVariableWarningModal/DeleteVariableWarningModal';
import { updateChannelBody, updateChannelTitle } from './utils';

const { FormTrigger, ManualTrigger } = ColoredIcons;

const START_NODE_ID = 0;

export const TriggerButton = (props) => {
  /**
   * forceUpdate updates state to force a re-render.
   * This is required because we are mutating the context, and React does not detect the changes.
   *  */
  const forceUpdate = useForceUpdate();
  const [workflowContext, setWorkflowContext] = useWorkflowContext();
  const {
    workflow,
    workflow: { title: workflowTitle },
  } = workflowContext;
  const [startNode] = workflow.structure;
  const {
    data: { triggers },
  } = startNode;
  const [workflowTrigger] = triggers;
  const { data: workflowTriggerData } = workflowTrigger;

  const setActiveChannel = (channelType) => {
    setWorkflowContext({ ...workflowContext, activeChannel: channelType });
  };

  const updateFormComponents = (channelName, components) => {
    const updatedTriggerData = updateChannelBody({
      channelName,
      value: components,
      nodeData: workflowTrigger,
    });
    workflow.updateNodeData(START_NODE_ID, updatedTriggerData);
    forceUpdate();
  };

  const updateTitle = (channelName, title) => {
    const updatedTriggerData = updateChannelTitle({
      channelName,
      title,
      nodeData: workflowTrigger,
    });

    workflow.updateNodeData(START_NODE_ID, updatedTriggerData);
    forceUpdate();
  };

  const isChannelModalOpened = () => {
    const {
      match: {
        params: { stepId, modalType },
      },
    } = props;

    return Boolean(
      parseInt(stepId, 10) === START_NODE_ID &&
        modalType &&
        modalType === 'form',
    );
  };

  const onModalOpen = () => {
    const {
      history: { push },
      match: {
        params: { flowId },
      },
    } = props;

    // only open modal when type is form trigger
    if (workflowTrigger.type === 'form') {
      push(`${prefix}/${flowId}/${workflowTrigger.id}/${workflowTrigger.type}`);
      setActiveChannel(workflowTrigger.type);
    }
  };

  const onModalClose = () => {
    const {
      history: { push },
      match: {
        params: { flowId },
      },
    } = props;
    const components = getComponents({
      nodeData: workflowTriggerData,
      channelType: 'form',
    });
    const extractedVariables = extractComponentContentVariables({
      components,
    });
    workflow.replaceOutputVariables(START_NODE_ID, extractedVariables);
    push(`${prefix}/${flowId}`);
    setActiveChannel(null);
  };

  // Return a modal if want to confirm before delete
  // Otherwise return null to delete immediately
  const onDeleteComponentClick = (selectedComponentId) => {
    const foundFormComponent = getComponents({
      nodeData: workflowTriggerData,
      channelType: 'form',
    }).find((component) => component.id === selectedComponentId);

    if (foundFormComponent.componentType !== 'form') {
      // We only care about displaying a modal for form types
      return null;
    }

    const uniqueNameProperty = foundFormComponent.properties.find(
      (prop) => prop.name === 'uniqueName',
    );

    if (!uniqueNameProperty) {
      throw new Error('Form item did not contain a uniqueName property');
    }

    const { value: variableId } = uniqueNameProperty;
    const { workflow } = workflowContext;

    const stepsFound = workflow.getWorkflowStepsByVariableId(variableId);
    if (stepsFound.length > 0) {
      return DeleteVariableWarningModal;
    }

    return null;
  };

  const renderWorkflowTriggerStep = () => {
    if (workflowTrigger.type === 'manual') {
      return (
        <WorkflowTriggerButton
          icon={<ManualTrigger />}
          headline="Manual Trigger"
          description="Click 'Trigger' in top right menu to start the workflow"
          linkText=""
        />
      );
    }
    return (
      <WorkflowTriggerButton
        icon={<FormTrigger />}
        headline="Trigger Form"
        description="The workflow runs every time this form is submitted"
        linkText="Edit Form"
        action={onModalOpen}
      />
    );
  };

  return (
    <Fragment>
      {renderWorkflowTriggerStep()}
      {isChannelModalOpened() && (
        <EmbeddedChannelModal
          defaultTitle={workflowTitle}
          onUpdateSubject={updateTitle}
          onClose={onModalClose}
          onUpdateChannelObj={updateFormComponents}
          nodeData={workflowTriggerData}
          getDeleteConfirmationModal={onDeleteComponentClick}
          node={workflowTrigger}
        />
      )}
    </Fragment>
  );
};

export default withRouter(TriggerButton);
