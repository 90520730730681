/* eslint-disable react/require-default-props */
//
import React, { useContext, useState, useEffect, Fragment } from 'react';
import {
  ButtonGroup,
  Button,
  Divider,
  useSegmentContext,
} from '@whispir/ui-lib-v2';
import { SideDrawerContent } from '@whispir/ui-lib-v2/lib/Molecules/SideDrawer/SideDrawerContent';
import { IconButton } from '@whispir/ui-lib-v2/lib/Atoms/Navigations/IconButton/IconButton';
import { DurationSelector } from '@whispir/ui-lib-v2/lib/Molecules/DurationSelector';
import { createSchedule } from '../../../../apollo/dto';

import { WorkflowContext, UserContext } from '../../../../state';
import { getFormattedErrors } from '../../../TreeDiagram/utils/timeFormatterUtils';
import { FormWrapper } from './Time.style';

export const Time = ({
  isReadOnly = false,
  validate,
  onDeleteClick,
  onCloseRequest,
  node,
  onUnmount,
}) => {
  const [workflowContext] = useContext(WorkflowContext);
  const {
    workflow,
    workflow: { updateNodeData },
  } = workflowContext;
  const [userContext] = useContext(UserContext);
  const { trackEvent } = useSegmentContext();
  const {
    user: { rawUserId },
  } = userContext;

  const [state, setState] = useState({
    days: 0,
    hours: 0,
    minutes: 1,
  });

  useEffect(() => {
    const { data } = node;

    if (data && data.delay) {
      const {
        delay: { days, hours, minutes },
      } = data;

      setState({ days, hours, minutes });
    }
    return () => {
      onUnmount();
    };
  }, [node, onUnmount]);

  const saveUpdates = () => {
    const { days, hours, minutes } = state;

    updateNodeData(
      node.id,
      createSchedule({
        ...node.data.delay,
        days,
        hours,
        minutes,
      }),
    );

    trackEvent({
      event: 'Wait Time Saved',
      userId: rawUserId,
      properties: {
        workflowId: workflow.id,
        waitTime: {
          days,
          hours,
          minutes,
        },
      },
    });
  };

  const handleSaveClick = () => {
    saveUpdates();
    onCloseRequest();
  };

  const handleChange = (newDuration) => {
    setState(newDuration);
  };

  const { days, hours, minutes } = state;
  const {
    data: { stepName },
  } = node;
  const error = validate({ stepName, delay: { days, hours, minutes } });
  const hasErrors = !!error;
  const formattedErrors = getFormattedErrors(error);
  const updateEnabled = !(hasErrors || isReadOnly);

  return (
    <SideDrawerContent
      title="Wait"
      onCloseClick={onCloseRequest}
      header={
        <IconButton size="medium" icon="Delete" onClick={onDeleteClick} />
      }
      footer={
        <Fragment>
          <Divider isHorizontal dividerType="solid" />
          <ButtonGroup>
            <Button
              primary={updateEnabled}
              disabled={!updateEnabled}
              onClick={handleSaveClick}
              text="Save &amp; Close"
            />
          </ButtonGroup>
        </Fragment>
      }
    >
      <FormWrapper>
        <div className="time-title">Pause this path for a specified period</div>
        <DurationSelector
          selectedDuration={state}
          onChange={handleChange}
          readOnly={isReadOnly}
          errors={formattedErrors}
        />
      </FormWrapper>
    </SideDrawerContent>
  );
};

export const FeatureFlaggedTime = (props) => {
  return <Time {...props} />;
};
