/* eslint-disable react/prop-types */
import React, { useCallback, useContext } from 'react';
import { useSegmentContext } from '@whispir/ui-lib-v2';
import { WorkflowContext, UserContext } from '../../state';
import { BaseAddNodeButton } from './BaseAddNodeButton';
import { getSelectedWorkspaceId } from '../../utils/helperUtils';

export const AddBetweenNodeButton = (props) => {
  const [workflowContext, setWorkflowContext] = useContext(WorkflowContext);
  const [userContext] = useContext(UserContext);
  const { trackEvent } = useSegmentContext();
  const {
    user: { rawUserId },
  } = userContext;

  if (
    !workflowContext ||
    !workflowContext.workflow ||
    !workflowContext.workflow.addStepInBetween
  ) {
    throw new Error(
      "Created an AddBetweenNodeButton, but workflow context didn't exist",
    );
  }
  const { previousNode, index, isDirectlyFollowedByABranch } = props;

  const { workflow } = workflowContext;
  const { addStepInBetween } = workflow;

  const handleAddNode = useCallback(
    (actionType) => {
      addStepInBetween(
        previousNode,
        actionType,
        index,
        isDirectlyFollowedByABranch,
        getSelectedWorkspaceId(),
      );
      setWorkflowContext({ ...workflowContext });
      trackEvent({
        event: 'Step Added',
        userId: rawUserId,
        properties: {
          workflowId: workflow.id,
          stepAction: actionType,
        },
      });
    },
    [
      addStepInBetween,
      previousNode,
      index,
      isDirectlyFollowedByABranch,
      setWorkflowContext,
      workflowContext,
      workflow.id,
      rawUserId,
      trackEvent,
    ],
  );

  return (
    <BaseAddNodeButton
      isBetweenNode
      onAddNode={handleAddNode}
      previousNode={previousNode}
    />
  );
};
