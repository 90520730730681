/* eslint-disable max-lines */
import React, { PureComponent, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { errors } from '@whispir/workflow-validation';
import { WorkflowModel, startTemplate } from '@whispir/workflow-data-model';
import { parse } from 'qs';
import { CreateNotification } from '../../components/Notification';
import { WorkflowProvider } from '../../state';
import { Debug } from '../../components/Debug/Debug';
import { Canvas } from './Canvas';
import { saveContent } from './BuilderContentUtils';
import { prefix } from '../../router/routerConstants';
import { getSelectedWorkspaceId } from '../../utils/helperUtils';

export class BuilderContent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      workflow: {
        id: '0',
        title: '',
        description: 'Workflow',
        structure: [],
      },
      isWorkflowActive: false,
      showConfirmation: false,
      notification: null,
      isSavingInProgress: false,
      success: false,
      redirect: false,
      activeNodesId: [],
    };
  }

  componentDidMount = async () => {
    const {
      match: {
        params: { flowId },
      },
      location: { search },
    } = this.props;

    const queryParams = parse(search, { ignoreQueryPrefix: true });

    const workflowExists = flowId && flowId !== '0';
    const workflow = new WorkflowModel();
    let isWorkflowActive = false;
    let newSchema;

    let workflowTrigger;
    let workflowRecord;

    if (workflowExists) {
      try {
        const { getWorkflow, getTriggeredWorkflow } = this.props;
        workflowTrigger = await getTriggeredWorkflow({ workflowId: flowId });
        workflowRecord = await getWorkflow({ workflowId: flowId });
      } catch (e) {
        console.error(e);
      }

      if (workflowRecord) {
        const {
          data: {
            getWorkflow: { name: title, id, definition, version },
          },
        } = workflowRecord;

        newSchema = {
          id,
          title,
          version,
          structure: JSON.parse(definition),
        };
      }
      if (workflowTrigger && workflowTrigger.length) {
        const [mostRecentWorkflowTrigger] = workflowTrigger;
        const { workflowEndDate } = mostRecentWorkflowTrigger;

        if (workflowEndDate > Date.now()) {
          isWorkflowActive = true;
        }
      }
    } else {
      const { template } = queryParams;
      const { REACT_APP_CONTENT_API_URL } = process.env;
      const workspaceId = getSelectedWorkspaceId();

      const structure = await startTemplate(
        template,
        workspaceId,
        async (data) => saveContent(REACT_APP_CONTENT_API_URL, data),
      );

      newSchema = {
        id: '0',
        title: '',
        description: 'Workflow',
        structure,
      };
    }
    workflow.setWorkflowSchema(newSchema);

    this.setState({
      workflow,
      isWorkflowActive,
    });
  };

  startWorkflow = (workflow) => async () => {
    const { triggerWorkflow } = this.props;
    const { id, version } = await this.saveWorkFlow();
    const triggerPayload = {
      workflowId: id,
      workflowVersion: version,
    };

    try {
      await triggerWorkflow(triggerPayload);
      workflow.started = true;
      this.showNotification({
        message: 'Your workflow is now active',
        success: true,
        redirect: true,
      });
    } catch (err) {
      this.showNotification({
        message: errors.apiFault,
        success: false,
        redirect: false,
      });
    }
  };

  closePublishConfirmation = () => {
    const {
      match: {
        params: { flowId: workflowId },
      },
    } = this.props;
    const { workflow } = this.state;
    const { id } = workflow;

    if (workflowId === '0') {
      const { history } = this.props;

      history.replace(`${prefix}/${id}`);
    }
  };

  handlePublishForm = async () => {
    const { workflow } = this.state;
    const { publishWorkflow, getWorkflow } = this.props;
    const { id, version } = await this.saveWorkFlow();
    const publishPayload = {
      workflowId: id,
      workflowVersion: version,
    };

    const { data } = await publishWorkflow({
      ...publishPayload,
      inputValues: [],
    });

    // refetching the workflow
    const workflowRefetchResult = await getWorkflow({ workflowId: id });
    const workflowData = workflowRefetchResult.data.getWorkflow;

    workflow.setWorkflowSchema({
      id: workflowData.id,
      title: workflowData.title,
      version: workflowData.version,
      structure: JSON.parse(workflowData.definition),
    });

    this.setState({
      workflow,
    });

    return { workflowId: id, publishForm: data.publishActivation };
  };

  saveWorkFlow = async () => {
    const { saveWorkflow: saveMutation, updateWorkflow } = this.props;
    const { workflow } = this.state;
    const { id, title, structure } = workflow;
    const payload = {
      id: id !== '0' ? id : null,
      definition: JSON.stringify(structure),
      name: title || '',
      workspaceId: getSelectedWorkspaceId(),
    };

    const submitMutation = async (submit, payload) => {
      const { name: workflowTitle } = payload;
      let response;

      try {
        this.setState({ isSavingInProgress: true });

        this.showNotification({
          message: `Workflow saved: ${workflowTitle}`,
          success: true,
        });
        response = await submit(payload);
      } catch (err) {
        this.showNotification({
          message: errors.apiFault,
          success: false,
        });
      }

      this.setState({
        isSavingInProgress: false,
      });

      return response;
    };

    if (id === '0') {
      const res = await submitMutation(saveMutation, payload);
      const {
        data: {
          saveWorkflow: { id: savedWorkflowId, version: savedWorkflowVersion },
        },
      } = res;

      this.setState({
        workflow: {
          ...workflow,
          id: savedWorkflowId,
          version: savedWorkflowVersion,
        },
      });

      return { id: savedWorkflowId, version: savedWorkflowVersion };
    }

    const res = await submitMutation(updateWorkflow, payload);
    const {
      data: {
        updateWorkflow: { version: updatedWorkflowVersion },
      },
    } = res;

    this.setState({
      workflow: { ...workflow, version: updatedWorkflowVersion },
    });

    return { id, version: updatedWorkflowVersion };
  };

  handleSaveWorkflow = async (exit) => {
    const {
      match: {
        params: { flowId: workflowId },
      },
      history,
    } = this.props;
    const { id: savedWorkflowId } = await this.saveWorkFlow();

    //  do save mutation
    if (exit) {
      history.replace(prefix);
    } else if (workflowId === '0') {
      history.replace(`${prefix}/${savedWorkflowId}`);
    }
  };

  renderNotification() {
    const { success, notification: message, redirect } = this.state;

    if (!message) return null;

    const notification = {
      type: success ? 'success' : 'error',
      message,
    };

    // Remove notification from state after render
    setTimeout(() => this.setState({ notification: null }));

    return (
      <CreateNotification notification={notification}>
        {redirect && <Redirect to={prefix} push />}
      </CreateNotification>
    );
  }

  showNotification = ({ message, success, redirect = false }) => {
    this.setState({
      notification: message,
      success,
      redirect,
    });
  };

  setActiveNodes = ({ nodeIds }) => {
    this.setState({ activeNodesId: nodeIds });
  };

  render() {
    const {
      workflow,
      isSavingInProgress,
      activeNodesId,
      isWorkflowActive,
    } = this.state;
    const {
      templates = [],
      distributions = [],
      history,
      match,
      isToggleEnabled,
    } = this.props;
    return (
      <Fragment>
        {this.renderNotification()}
        {workflow && workflow.structure.length ? (
          <WorkflowProvider
            initialState={{
              templates,
              distributions,
              lightboxProps: null,
              workflow,
              activeNodesId,
              setActiveNodes: this.setActiveNodes,
              activeChannel: match.params.modalType,
            }}
          >
            {isToggleEnabled('dev-mode') && <Debug />}
            <React.Fragment>
              <Canvas
                publishWorkflow={this.handlePublishForm}
                startWorkflow={this.startWorkflow}
                showNotification={this.showNotification}
                handleSaveWorkflow={this.handleSaveWorkflow}
                closePublishConfirmation={this.closePublishConfirmation}
                history={{ ...history, ...match }}
                isSavingInProgress={isSavingInProgress}
                isWorkflowActive={isWorkflowActive}
              />
            </React.Fragment>
          </WorkflowProvider>
        ) : null}
      </Fragment>
    );
  }
}
