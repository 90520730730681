import styled from 'styled-components';
import { Colors } from '@whispir/ui-lib-v2';

export const ExpressionText = styled.span`
  &.error {
    color: ${Colors.error};
    &:after {
      content: '';
      height: 8px;
      width: 8px;
      border-radius: 50%;
      display: inline-block;
      background-color: ${Colors.error};
      margin-left: 8px;
    }
  }
`;
