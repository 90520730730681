import { AxiosInstance } from 'axios';
import { useCallback } from 'react';
import { PUBLISH_ACIVATION_MUTATION } from '../apollo/mutations/workflow-mutation';
import { useLoading } from './useLoading';

/**a
 * This API endpoint publishes a web form that becomes available for the public.
 * (p.s. the published form triggers a workflow execution upon submission)
 *
 * This is a different way to start a workflow execution.
 */
export const usePublishFormToTriggerWorkflow = ({
  api,
}: {
  api: AxiosInstance;
}) => {
  const publishActivation = useCallback(
    async (body) => {
      const { data } = await api.request(
        {
          data: {
            operationName: 'publishActivation',
            variables: {
              // https://bitbucket.org/whispirdevops/workflow-engine/src/47f81aa12ff36d20cc16042ce7d880e18f03d7f4/sparx/workflow-studio-api/src/schema/workflowPublish/workflowPublish.resolvers.js#lines-31
              input: {
                workflowId: body.workflowId,
                workflowVersion: body.workflowVersion,
                inputValues: body.inputValues,
              },
            },
            query: PUBLISH_ACIVATION_MUTATION,
          }
        },
      );

      return data;
    },
    [api],
  );

  return useLoading(publishActivation);
};
