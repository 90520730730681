import { AxiosInstance } from 'axios';
import { useCallback } from 'react';
import { DELETE_WORKFLOW_MUTATION } from '../apollo/mutations/workflow-mutation';
import { useLoading } from './useLoading';

/**a
 * This API endpoint deletes a dormant workflow record.
 * Workflows that have been deactivated or have never been triggered before
 *
 */
export const useDeleteWorkflow = ({ api }: { api: AxiosInstance }) => {
  const deleteWorkflow = useCallback(
    async (body) => {
      const { data } = await api.request(
        {
          data: {
            operationName: 'deleteWorkflow',
            variables: {
              id: body.id,
            },
            query: DELETE_WORKFLOW_MUTATION,
          },
        }
      );

      return data;
    },
    [api],
  );

  return useLoading(deleteWorkflow);
};
