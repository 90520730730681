import React, { Fragment } from 'react';
import { validators } from '@whispir/workflow-validation';
import { ACTION_TYPES } from '@whispir/workflow-factory';
import { getDurationDisplayValues } from '../utils/timeFormatterUtils';
import { StyledSplitWrapper } from './CollectResponsesSplit.styles';

const { ON_WEBFORM_REPLY } = ACTION_TYPES;
const DURATION_ERROR_MESSAGE = 'Duration is invalid';

export const CollectResponsesSplit = ({ node, isValid }) => {
  const {
    data: {
      collectResponses: { duration },
    },
    data,
  } = node;

  let title = 'Wait For Response';
  let description = 'for up to';

  if (node.action === ON_WEBFORM_REPLY) {
    title = 'Web Form Expires';
    description = 'after';
  }

  const durationSelectionErrors = validators[node.action]({ ...data });
  const hasErrors = !!durationSelectionErrors;
  const displayLabel =
    !hasErrors && getDurationDisplayValues(duration, description);

  return (
    <StyledSplitWrapper
      contentOverride={
        <Fragment>
          <p className="title">{title}</p>
          <p className="description">{displayLabel}</p>
          {!isValid && node.isDirty && (
            <p className="state error">{DURATION_ERROR_MESSAGE}</p>
          )}
        </Fragment>
      }
    />
  );
};
