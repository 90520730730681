//
import React from 'react';
import { Button } from '@whispir/ui-lib-v2';
// $FlowFixMe
import { operatorIds } from '@whispir/expression-helper';

import ExpressionItem from './ExpressionItem';
import {
  createAndExpression,
  createOrExpression,
  deleteExpression,
  replaceExpression,
} from './Utils';
import {
  ExpressionFieldset,
  ConditionTitle,
  StyledExpressionComposite,
} from './Expression.style';

const { AND, OR } = operatorIds;

const ExpressionComposite = ({
  expressionState,
  expressionState: { operator, operands },
  updateExpressionState,
  nodeId,
}) => {
  const handleCreateExpression = (operatorType, index) => {
    let newExpressionState = null;
    if (operatorType === AND)
      newExpressionState = createAndExpression(index, expressionState);
    if (operatorType === OR)
      newExpressionState = createOrExpression(expressionState);
    updateExpressionState(newExpressionState);
  };

  const handleDeleteExpression = (parentIndex, id) => {
    const newExpressionState = deleteExpression(
      parentIndex,
      id,
      expressionState,
    );
    updateExpressionState(newExpressionState);
  };

  const handleUpdateExpression = (parentIndex, newExpressionItem) => {
    const newExpressionState = replaceExpression(
      parentIndex,
      expressionState,
      newExpressionItem,
    );
    updateExpressionState(newExpressionState);
  };

  const renderAndButton = (index) => (
    <Button
      variant="link"
      data-button="add-and"
      onClick={() => handleCreateExpression(AND, index)}
      className="and-button"
      text="+ AND"
      type="secondary"
    />
  );

  const renderOrButton = () => (
    <Button
      variant="link"
      data-button="add-or"
      onClick={() => handleCreateExpression(OR)}
      className="or-button"
      text="+ OR"
      type="secondary"
    />
  );

  const renderHeadline = (index) =>
    index > 0 ? (
      <ConditionTitle className="headline">
        <span>or</span>
      </ConditionTitle>
    ) : null;

  const hasOperands = Boolean(operands && operands.length);

  const renderExpressionItem = (
    index,
    parentIndex,
    expression,
    isDeletable = true,
  ) => (
    <ExpressionItem
      nodeId={nodeId}
      index={index}
      isDeletable={isDeletable}
      expression={expression}
      handleDelete={() => handleDeleteExpression(parentIndex, expression.id)}
      handleUpdate={(newExpressionItem) =>
        handleUpdateExpression(parentIndex, newExpressionItem)
      }
      key={expression.id}
    />
  );

  return (
    <StyledExpressionComposite>
      {hasOperands ? (
        operator === OR ? (
          operands &&
          operands.map((childExp, parentIndex) => (
            <React.Fragment key={childExp.id}>
              {renderHeadline(parentIndex)}

              <ExpressionFieldset key={childExp.id}>
                {childExp.operands
                  ? childExp.operands.map((expression, index) => {
                      const isDeletable = !(
                        operands &&
                        operands.length === 1 &&
                        childExp.operands &&
                        childExp.operands.length === 1
                      );
                      return renderExpressionItem(
                        index,
                        parentIndex,
                        expression,
                        isDeletable,
                      );
                    })
                  : renderExpressionItem(
                      0,
                      null,
                      childExp,
                      Boolean(operands && operands.length > 1),
                    )}
                {renderAndButton(parentIndex)}
              </ExpressionFieldset>
            </React.Fragment>
          ))
        ) : (
          operator === AND && (
            <ExpressionFieldset>
              {operands &&
                operands.map((expression, index) =>
                  renderExpressionItem(
                    index,
                    null,
                    expression,
                    Boolean(operands && operands.length > 1),
                  ),
                )}
              {renderAndButton()}
            </ExpressionFieldset>
          )
        )
      ) : (
        <ExpressionFieldset>
          {renderExpressionItem(0, null, expressionState, false)}
          {renderAndButton()}
        </ExpressionFieldset>
      )}
      {renderOrButton()}
    </StyledExpressionComposite>
  );
};

export default ExpressionComposite;
