import styled from 'styled-components';
import { Fonts, Colors, transitionStyle } from '@whispir/ui-lib-v2';

export const StyledOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: space-between;
  padding-right: 16px;
  position: relative;

  .coming-soon {
    ${Fonts.body};
    color: ${Colors.grey_blue_3};
    background-color: ${Colors.background};
    transition: background-color ${transitionStyle};
    text-align: left;
    position: relative;
    padding: 12px 24px 12px 62px;
    width: 360px;
    min-height: 88px;
    margin-bottom: 8px;
    border: 1px ${Colors.grey_blue_2} solid;
    border-radius: 8px;
    display: block;
    cursor: pointer;
    .icon-wrapper {
      position: absolute;
      left: 24px;
      top: 50%;
      transform: translateY(-50%);

      svg {
        fill: ${Colors.grey_blue_3};
      }
    }

    .coming-soon-text {
      ${Fonts.caption};
      text-transform: capitalize;
      color: ${Colors.accent_dark};
      position: absolute;
      font-weight: 500;
      right: 0;
      top: 0;
      padding-top: 8px;
      padding-right: 16px;
    }

    .heading {
      ${Fonts.subheadSmall};
      text-align: left;
      margin: 0 0 2px 0;
    }

    .description {
      display: inline-block;
      margin-bottom: 4px;
      ${Fonts.body};
    }
  }

  .custom-divider {
    .divider-form-control {
      padding: 8px 0 0;
      .divider-line {
        margin: 0 0 -8px;
      }
    }
  }

  .option-type-title {
    ${Fonts.caption};
    font-weight: bold;
    text-transform: uppercase;
    color: ${Colors.grey_blue_4};
    margin-bottom: 8px;
  }

  .side-drawer-title {
    ${Fonts.subhead};
    padding-top: 32px;
  }

  div:first-child {
    padding-top: 4px;
  }

  .option-group-wrapper {
    padding-top: 32px;
  }

  .large-icon-button {
    ${Fonts.body};
    text-align: left;
    color: ${Colors.grey_blue_4};
    background-color: ${Colors.white};
    transition: background-color ${transitionStyle};
    padding: 12px 24px 12px 62px;
    width: 100%;
    min-height: 88px;
    margin-bottom: 8px;
    border: 1px ${Colors.grey_blue_2} solid;
    border-radius: 8px;
    display: block;
    cursor: pointer;

    .description {
      display: inline-block;
      ${Fonts.body};
    }

    &:hover {
      background: ${(props) =>
        props.isComingSoon ? 'red' : `${Colors.grey_blue_1}`};
    }

    position: relative;

    .icon-wrapper {
      position: absolute;
      left: 24px;
      top: 50%;
      transform: translateY(-50%);

      svg {
        fill: ${Colors.grey_blue_4};
      }
    }

    .heading {
      ${Fonts.subheadSmall};
      color: ${Colors.grey_blue};
      text-align: left;
      margin: 0 0 2px 0;
    }
  }
`;
