/**
 * Create a schedule payload
 */
export const createSchedule = ({ days, hours, minutes }) => ({
  delay: {
    days: parseInt(days, 10) || 0,
    hours: parseInt(hours, 10) || 0,
    minutes: parseInt(minutes, 10) || 0,
  },
});
