import styled from 'styled-components';
import { Colors, Elevations, Fonts, transitionStyle } from '@whispir/ui-lib-v2';

export const ControlsContainer = styled.div`
  position: fixed;
  display: flex;
  bottom: 24px;
  margin-left: 32px;
  z-index: 1;
  border-radius: 8px;
  overflow: hidden;
  background-color: ${Colors.white};
  box-shadow: ${Elevations.elevation_1};

  button  {
    ${Fonts.linkSecondary};
    background-color: ${Colors.white};
    padding: 12px 16px;;
    cursor: pointer;
    user-select: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color ${transitionStyle}, fill ${transitionStyle};

    svg {
      fill: ${Colors.grey_blue_4};
    }

    &:hover {
      color: ${Colors.black};

      svg {
        fill: ${Colors.accent};
      }
    }
  }

  .percentage {
    padding: 0 16px;
    ${Fonts.bodySmall};
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
  }
}
`;

export const ContentWrapper = styled.div`
  margin-top: 48px;
  opacity: 0;
  transform: scale(1);
  animation: fadein ${transitionStyle} .25s forwards;

  @keyframes fadein {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to  {
      opacity: 1;
      transform: scale(1);
    }
  }
}
`;
