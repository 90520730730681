import { Icons, Illustrations } from '@whispir/ui-lib-v2';

const { ManualTrigger, WebformTrigger } = Icons;
const {
  WebToSms,
  StaffFeedback,
  CallCentre,
  WorkLocation,
  SatSurvey,
  MotivationSurvey,
  PMFSurvey,
  NewHire,
  LeaveRequest,
  EventConfirmation,
} = Illustrations;

export const startFromScratchTemplates = [
  {
    image: ManualTrigger,
    title: 'Manual Trigger',
    text: 'The workflow triggers every time you push the Trigger button.',
    templateId: 'manual-trigger',
  },
  {
    image: WebformTrigger,
    title: 'New Form Entry',
    text:
      'Create and Publish a form. Triggers when someone fills out your form.',
    templateId: null,
  },
];

export const prebuiltTemplates = [
  {
    image: WebToSms,
    title: 'Web to SMS Portal',
    text:
      'Send SMS messages quickly and easily from a simple-to-use, customisable webform.',
    templateId: 'web-to-sms',
  },
  {
    image: CallCentre,
    title: 'Call Centre Deflection',
    text:
      'Send preformatted, brand-approved templates and content from an easy-to-use form.',
    templateId: 'call-centre',
  },
  {
    image: StaffFeedback,
    title: 'Staff Feedback Form',
    text:
      'Capture and route staff feedback based on rules, such as type of feedback and department.',
    templateId: 'staff-feedback',
  },
  {
    image: WorkLocation,
    title: 'Work Location Check In',
    text:
      'Check-in via SMS and keep staff safe, regardless of their work location.',
    templateId: 'work-location',
  },
  {
    image: LeaveRequest,
    title: 'Leave Requests',
    text: 'Submit, manage and approve staff leave requests with ease. ',
    templateId: 'leave-request',
  },
  {
    image: EventConfirmation,
    title: 'Event Confirmation',
    text:
      'Confirm guest attendance and dietary requirements with automated SMS messages and forms.',
    templateId: 'event-confirmation',
  },
];

export const surveyTemplates = [
  {
    image: SatSurvey,
    title: 'Employee Satisfaction Survey',
    text:
      'Ensure your employees are happy and enable your organisation’s success.',
    templateId: 'employee-satisfaction-feedback',
  },
  {
    image: NewHire,
    title: 'New Hire Onboarding Survey',
    text:
      'Make sure new hires have everything they need to hit the ground running.',
    templateId: 'new-hire-onboarding-survey',
  },
  {
    image: MotivationSurvey,
    title: 'Employee Motivation Survey',
    text:
      'Find out how motivated employees are to perform at their best every day.',
    templateId: 'employee-motivation-survey',
  },
  {
    image: PMFSurvey,
    title: 'PMF Survey',
    text: 'Test and measure your organisation’s product/market fit for growth.',
    templateId: 'pmf-survey',
  },
];
