import React, { createContext, useState } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children, initialState }) => {
  const [state, setState] = useState(initialState);
  return (
    <UserContext.Provider value={[state, setState]}>
      {children}
    </UserContext.Provider>
  );
};
