import { useState, useCallback } from 'react';

/**
 * This force update function updates state to force a re-render.
 * This can be used whenever state is not updating automatically,
 * such as when we mutate the context.
 *
 * see here for resource: https://stackoverflow.com/a/53215514
 *
 */
export const useForceUpdate = () => {
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  return forceUpdate;
};

/**
 * This function can be used as a hook to fire only the last invocation of
 * a function after a specified delay.
 * It can also be thought of as a reverse debounce.
 */
export const useDelayedInvoke = ({ delayedFunction, delay = 1000 }) => {
  const [timeoutIndex, setTimeoutIndex] = useState(-1);

  const queueTrigger = (...args) => {
    clearTimeout(timeoutIndex);
    const newTimeoutIndex = setTimeout(() => {
      delayedFunction(...args);
    }, delay);
    setTimeoutIndex(newTimeoutIndex);
  };

  return queueTrigger;
};
