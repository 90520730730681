import React, { createContext, useState, useContext, useEffect } from 'react';

export const WorkflowContext = createContext();

export const useWorkflowContext = () => useContext(WorkflowContext);

const hasActivationId = (structure) =>
  structure &&
  !!structure.length &&
  structure[0] &&
  structure[0].data &&
  structure[0].data.activationId;

export const WorkflowProvider = ({ children, initialState }) => {
  const [state, setState] = useState(initialState);

  // This block is need because any update in initialState will be ignored
  // because it is only used as initial value for the state
  useEffect(() => {
    if (state.workflow && initialState.workflow) {
      // Update workflow structure when new structure is not equal old structure
      // (new structure will contain the activationId)

      if (
        !hasActivationId(state.workflow.structure) &&
        hasActivationId(initialState.workflow.structure)
      ) {
        setState((prevState) => ({
          ...prevState,
          workflow: initialState.workflow,
        }));
      }
    }
    // Only check if initialState.workflow changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialState.workflow]);

  return (
    <WorkflowContext.Provider value={[state, setState]}>
      {children}
    </WorkflowContext.Provider>
  );
};
