import React from 'react';
import { Switch, Route } from 'react-router';
import { Redirect } from 'react-router-dom';
import { NotFound, Dashboard, Builder, TemplateSelectorPage } from '../routes';
import { getRoute } from '../components/Container/containers';
import { prefix } from './routerConstants';

export const routes = [
  {
    path: `${prefix}`,
    component: Dashboard,
    isPublic: true,
    exact: true,
  },
  {
    path: `${prefix}/404`,
    component: NotFound,
    isPublic: true,
  },
  {
    path: `${prefix}/templates`,
    component: TemplateSelectorPage,
    isPublic: true,
    exact: true,
  },
  {
    path: `${prefix}/:flowId/:stepId?/:modalType?`,
    component: Builder,
    exact: true,
    isPublic: true,
  },
];

const getRoutes = (routeList) => {
  const res = [];
  routeList.map((route) => res.push(getRoute(route, prefix)));
  res.push(<Redirect key="redirect" exact from="/" to={`${prefix}`} />);
  res.push(<Route key="404" component={NotFound} />);
  return res;
};

const routeConfigs = (location) => {
  const res = <Switch location={location}>{getRoutes(routes)}</Switch>;

  return res;
};

export default routeConfigs;
