/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import {
  ModalHeader,
  Alert,
  Button,
  useFeatureToggle,
} from '@whispir/ui-lib-v2';
import {
  convertUiVariableGroupsToUnresolvedVariables,
  sendMessageVariablesAsVariableGroup,
} from '@whispir/variables';
import { MEDIA_UPLOAD_REGION_TO_URL } from '@whispir/utils-js';
import { ON_WEBFORM_REPLY_STATIC_VARIABLES } from '@whispir/workflow-data-model';
import { Builder } from '@whispir/content-builder';
import { withRouter } from 'react-router-dom';
import { usePanZoomContext } from '../PanZoomControls/PanZoom.context';
import { useWorkflowContext } from '../../state/Workflow.context';
import { updateChannelBody } from '../TreeDiagram/Node/utils';
import {
  DeleteWarningModalBody,
  DeleteWarningModalTitle,
} from '../DeleteVariableWarningModal/DeleteVariableWarningModal';
import { constructEmbeddedChannels } from './utils';
import { StyledModal, StyledModalBody } from './EmbeddedChannelModal.style';
import { SmsPanelLayout } from './SmsPanelLayout';
import { getSelectedWorkspaceId } from '../../utils/helperUtils';

const IncompatibleContentAlert = ({ onClose }) => (
  <Alert
    type="banner"
    alert="error"
    title="Something went wrong!"
    content={
      <div>
        <div>Content is not compatible with the Builder</div>
        <Button style={{ marginTop: '10px' }} text="Close" onClick={onClose} />
      </div>
    }
  />
);

const EmbeddedChannelModal = ({
  defaultTitle,
  onUpdateChannelObj,
  nodeData,
  onClose,
  node,
  nodeId,
  history,
}) => {
  const { isToggleEnabled } = useFeatureToggle();
  const panZoomContext = usePanZoomContext();
  const [workflowContext] = useWorkflowContext();

  useEffect(() => {
    const { setIsPanEnable } = panZoomContext;
    setIsPanEnable && setIsPanEnable(false);
    return () => {
      const { setIsPanEnable } = panZoomContext;
      const popover = document.getElementsByClassName('popover-no-tabindex');
      setIsPanEnable && setIsPanEnable(!popover.length);
    };
  }, [panZoomContext]);

  const { activeChannel, workflow } = workflowContext;

  const [embeddedChannels, setEmbeddedChannels] = useState({});
  const [isSmsChannel, setIsSmsChannel] = useState(false);

  useEffect(() => {
    switch (activeChannel) {
      case 'form':
        setEmbeddedChannels(nodeData);
        break;
      case 'sms':
      case 'email':
      case 'web':
      case 'webform':
        setIsSmsChannel(activeChannel === 'sms');
        setEmbeddedChannels(constructEmbeddedChannels(nodeData, node));
        break;
      case null:
        break;
      default:
        throw new Error(`channelType ${activeChannel} is not supported`);
    }
  }, [activeChannel, nodeData, node]);

  const variableMenuData = [
    ...workflow.getWorkflowVariableGroupsByStepId(nodeId, 'contentBuilder'),
    sendMessageVariablesAsVariableGroup,
  ];

  // For Content Builder
  const handleUpdateContent = ({ contentId, outputVariables, isSaveOnly }) => {
    const variables = outputVariables.map((outputVariables) => ({
      type: outputVariables.inputType,
      variableId: outputVariables.id,
      variableName: outputVariables.label,
      variableType: outputVariables.valueType,
      options: outputVariables.options,
    }));

    const updatedEmbeddedChannel = {
      [activeChannel]: {
        ...embeddedChannels[activeChannel],
        // Keeping components for now as this is causing errors
        type: 'builder-content',
        contentId,
      },
    };

    if (activeChannel === 'form') {
      workflow.updateNodeData(node.id, {
        triggers: [
          {
            id: node.id,
            type: 'form',
            data: {
              form: {
                id: 'Form',
                type: 'builder-content',
                contentId,
              },
            },
          },
        ],
      });
      workflow.replaceOutputVariables(node.id, variables);

      // Keep modal open if Save only
      if (isSaveOnly) {
        return;
      }

      onClose();
      return;
    }

    const updatedNodeData = updateChannelBody({
      channelType: activeChannel,
      value: updatedEmbeddedChannel,
      nodeData,
      // hard-coding this one to be always true as this function would only ever be called by content-builder
      isContentBuilderEnabled: true,
    });

    if (activeChannel === 'webform') {
      const [onReplyNode] = node.next;
      // the `variables` returned from CB are extracted from the total number of form components exist in the canvas
      const updatedVariables = [
        // eslint-disable-next-line new-cap
        ...ON_WEBFORM_REPLY_STATIC_VARIABLES(node.id),
        ...variables,
      ];
      workflow.replaceOutputVariables(onReplyNode.id, updatedVariables);
    }

    workflow.updateNodeData(node.id, updatedNodeData);

    // Keep modal open if Save only
    if (isSaveOnly) {
      return;
    }

    onClose();
  };

  const isContentBuilderIncompatible =
    embeddedChannels &&
    embeddedChannels[activeChannel] &&
    embeddedChannels[activeChannel].components &&
    ((embeddedChannels[activeChannel].components[activeChannel] &&
      embeddedChannels[activeChannel].components[activeChannel].components
        .length) ||
      embeddedChannels[activeChannel].components.length);

  const getModalBodyContent = () => {
    const contentId =
      embeddedChannels &&
      embeddedChannels[activeChannel] &&
      embeddedChannels[activeChannel].contentId;

    if (isSmsChannel) {
      return (
        <SmsPanelLayout
          onUpdateChannelObj={onUpdateChannelObj}
          messageType={nodeData.message.type}
          body={nodeData.message.content.body}
          variableList={variableMenuData}
          node={node}
        />
      );
    }

    if (isContentBuilderIncompatible) {
      return <IncompatibleContentAlert onClose={onClose} />;
    }

    const {
      variables,
      variableGroups,
    } = convertUiVariableGroupsToUnresolvedVariables(variableMenuData);

    // passing content-api url as an env var, the env var is passed down from buildkite 'edit steps'
    const {
      REACT_APP_CONTENT_API_URL,
      REACT_APP_SASS_COMPILER,
      REGION,
    } = process.env;
    const isTriggerForm = activeChannel === 'form';

    return (
      activeChannel && (
        <Builder
          contentMode={
            isToggleEnabled('content-builder-test-mode')
              ? 'test'
              : activeChannel === 'webform'
              ? 'form'
              : activeChannel
          }
          contentId={contentId}
          inputVariables={isTriggerForm ? [] : variables}
          inputVariableGroups={isTriggerForm ? [] : variableGroups}
          workspaceId={getSelectedWorkspaceId()}
          contentVersion=""
          onSaveContent={handleUpdateContent}
          contentType="html"
          activate={false}
          apis={{
            apiUrl: REACT_APP_CONTENT_API_URL,
            cssCompilerApi: REACT_APP_SASS_COMPILER,
            mediaApi:
              // Checking if it is development is only temp,
              // I am going to work with Bo to allow staging media upload url to allow CORS from development as well
              process.env.NODE_ENV === 'development'
                ? 'https://media-upload.whispirdev.com/media'
                : MEDIA_UPLOAD_REGION_TO_URL[REGION],
          }}
          defaultSubject={
            activeChannel === 'form' ? defaultTitle : nodeData.stepName
          }
          deleteContentHandler={{
            modalHeader: <DeleteWarningModalTitle />,
            confirmText: 'Delete anyway',
            getConfirmationModalContent: (_contentItem, variables) => {
              if (variables.length > 0) {
                return (
                  <StyledModalBody>
                    <DeleteWarningModalBody isUsingMayLanguage />
                  </StyledModalBody>
                );
              }
              return null;
            },
            onCancel: () => {},
            onContinue: () => {},
          }}
          backButtonHandler={{
            onButtonClick: () => history.goBack(),
            buttonText: 'Back',
          }}
          isLayoutDnd={isToggleEnabled('content-builder-layout-dnd')}
        />
      )
    );
  };

  return (
    <StyledModal
      handleExit={onClose}
      disableEscapeKeyDown
      isOpen
      isFullScreen
      headerContent={
        !isSmsChannel && !isContentBuilderIncompatible ? null : (
          // nb! this isn't actually displaying!
          <ModalHeader
            title=""
            onCloseClick={onClose}
            closeButtonText="Save & Close"
          />
        )
      }
      bodyContent={getModalBodyContent()}
    />
  );
};

const EmbeddedChannelModalWithRouter = withRouter(EmbeddedChannelModal);

export default EmbeddedChannelModalWithRouter;
