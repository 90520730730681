import styled, { css } from 'styled-components';
import { Colors, Elevations, Fonts, transitionStyle } from '@whispir/ui-lib-v2';

export const SplitWrapper = styled.div`
  background-color: ${Colors.white};
  border: none;
  border-radius: 8px;
  padding: 30px 16px 16px 16px;
  cursor: pointer;
  margin: 30px 0 -7px 0;
  text-align: center;
  position: relative;
  box-shadow: ${Elevations.elevation_1};
  z-index: 1;

  button {
    border: none;
    background: none;
    ${Fonts.link};
    cursor: pointer;
    transition: color ${transitionStyle};
    &:hover {
      color: ${Colors.black};
    }
  }
`;

export const TopWrapper = styled.div`
  .icon-wrapper {
    margin: -56px auto 0 auto;
    svg {
      width: 60px;
      height: 60px;
      position: relative;
      left: -28px;
      top: 3px;
    }

    .svg-container {
      left: 32px;
      fill: white;
    }
  }
`;

export const BodyWrapper = styled.div`
  width: 416px;
`;

export const Headline = styled.span`
  display: block;
  ${Fonts.subheadSmall};
  font-weight: bold;
  margin: 0 0 8px 0;
`;

export const SvgWrapper = styled.div`
  display: flex;
  margin: auto;
  justify-content: center;
  svg {
    width: 120px;
    height: 120px;
  }
`;

export const TextWrapper = styled.div`
  ${Fonts.subheadSmall};
`;

export const TextHeading = styled.div`
  padding: 0 0 32px 0;
  ${Fonts.headline};
`;

export const TextBody = styled.div``;

export const ModalActionsWrapper = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: flex-end;

  button[aria-label='Cancel'] {
    margin-right: 16px;
  }
`;

export const LinkStyles = css`
  padding: 0;
  ${Fonts.link};
`;

export const PopoverPanelcontent = styled.div`
  max-width: 350px;

  p {
    ${Fonts.body}
  }
`;
