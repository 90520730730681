//
const typeDefs = `
  type Contacts {
    contacts: [Contact]
  }

  type MessageTemplate {
    id: String
    subject: String
    messageTemplateName: String
    sms : SMSType
    email : EmailType
    web : WebType
    selectedChannels : SelectedChannels
  }

  type Notification {
    id: ID
    type: String
    message: String
  }

  type Workspace {
    contacts(offset: Int, limit: Int): [Contact]
    templates(offset: Int, limit: Int): [MessageTemplate]
    template(id: String!): MessageTemplate
  }

  type Workflow {
    id : String
    name : String
    modified : String
    created : String
    creatorUserName : String
    creatorUserId : Int
    companyId : Int
    workspaceId : String
    modifierUserName : String
    modifierUserId : Int
    definition : String
    clonedFrom : String
    clonedDate : String
  }

  type User {
    userId : String
    rawUserId: String
    companyId: String
    userName: String
    details: UserDetail
  }

  type UserDetail {
    firstName: String,
    lastName: String,
    companyRole: String,
    companyName: String,
    createdAt: String,
    userRole: String,
    productTier: String,
    workEmailAddress1: String
  }

  type Query {
      workspace(id: String!): Workspace,
      getUserWorkflows(pageSize: Int!, pageNo: Int!): [Workflow]
      getWorkflow(id: String!): Workflows,
      getUser(): User,
  }
`;

export default typeDefs;
