import styled, { createGlobalStyle } from 'styled-components';
import { Fonts, Colors } from '@whispir/ui-lib-v2';

export const PopoverPanelcontent = styled.div`
  max-width: 350px;

  p {
    ${Fonts.body}
  }

  .cancel-button {
    cursor: pointer;
    margin-right: 8px;
    background: transparent;
    color: ${Colors.accent};
    ${Fonts.bodyLarge}
    text-decoration: none;
    border: none;
  }
`;

/*
  Lightbox overwrite
  TODO: remove as soon as the lightbox is swapped by the one coming from UI lib
*/
createGlobalStyle`
  div[class^="PopoverPanelcontent"] {
    div[class^="ButtonWrapper"] {
      justify-content: flex-end;
    }
  }
`;
