//
import { InMemoryCache } from 'apollo-cache-inmemory';

export const dataIdFromObject = (object) => {
  // eslint-disable-next-line
  switch (object.__typename) {
    // User is whatever type "me" query resolves to
    case 'Draft':
      return object.name;
    default:
      // eslint-disable-next-line
      return object.id || object._id;
  }
};

const cache = new InMemoryCache({
  dataIdFromObject,
});

export default cache;
