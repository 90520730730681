//
import React, { PureComponent } from 'react';
// $FlowFixMe
import ReactPanZoom from '@ajainarayanan/react-pan-zoom';
import { Icons } from '@whispir/ui-lib-v2';
import { PanZoomContext } from './PanZoom.context';
import { ControlsContainer, ContentWrapper } from './PanZoomControls.style';

const { Add, Remove } = Icons;

export class PanZoomControls extends PureComponent {
  static defaultProps = {
    children: <div />,
  };

  static contextType = PanZoomContext;

  state = {
    dx: 0,
    dy: 0,
    zoom: 1,
    isPanEnable: true,
    setIsPanEnable: (isPanEnable) => {
      this.setState({ isPanEnable });
    },
  };

  onWheel = (event) => {
    if (event.deltaY < 0) {
      this.zoomIn();
    } else {
      this.zoomOut();
    }
  };

  onPan = (dx, dy) => {
    this.setState({ dx, dy });
  };

  zoomIn = (increment = 0.1) => {
    const { zoom } = this.state;
    this.setState({
      zoom: zoom < 1.5 ? Math.round((zoom + increment) * 100) / 100 : zoom,
    });
  };

  zoomOut = (increment = 0.1) => {
    const { zoom } = this.state;
    this.setState({
      zoom: zoom > 0.5 ? Math.round((zoom - increment) * 100) / 100 : zoom,
    });
  };

  zoomReset = () => {
    this.setState({ zoom: 1 });
  };

  renderPanZoomControls = () => {
    const { zoom } = this.state;

    return (
      <ControlsContainer>
        <div className="percentage" data-controls="zoom-percentage">
          {`${Math.round(zoom * 100)}%`}
        </div>
        <button
          onClick={() => this.zoomOut()}
          onKeyPress={() => this.zoomOut()}
          type="button"
          tabIndex="0"
          data-controls="zoom-out"
        >
          <Remove />
        </button>
        <button
          onClick={() => this.zoomIn()}
          onKeyPress={() => this.zoomIn()}
          type="button"
          tabIndex="0"
          data-controls="zoom-in"
        >
          <Add />
        </button>
        <button
          onClick={this.zoomReset}
          onKeyPress={this.zoomReset}
          type="button"
          tabIndex="0"
          data-controls="zoom-reset"
        >
          Reset
        </button>
      </ControlsContainer>
    );
  };

  render() {
    const { children } = this.props;
    const { zoom, dx, dy, isPanEnable } = this.state;

    return (
      <PanZoomContext.Provider value={this.state}>
        {this.renderPanZoomControls()}
        <ReactPanZoom zoom={zoom} pandx={dx} pandy={dy} enablePan={isPanEnable}>
          <ContentWrapper onWheel={isPanEnable ? this.onWheel : undefined}>
            {children}
          </ContentWrapper>
        </ReactPanZoom>
      </PanZoomContext.Provider>
    );
  }
}

export default PanZoomControls;
