import styled from 'styled-components';
import { Colors } from '@whispir/ui-lib-v2';

export const ConnectorRadius = '20px';

export const ConnectorBeforeHeight = `${parseInt(ConnectorRadius, 10) - 1}px`;

export const ChildWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .is-type-task,
  .is-type-placeholder,
  .is-type-start,
  .is-type-condition {
    & + .step-header {
      .btn-delete-branch {
        &:before {
          height: 20px;
          top: 0;
        }
      }
    }
  }

  .btn-delete-branch {
    position: relative;
    border: none;
    background: none;
    cursor: pointer;
    margin-top: 4px;
    margin-bottom: 4px;
    padding-top: 24px;

    &:before {
      content: '';
      height: 26px;
      width: 1px;
      position: absolute;
      left: 49%;
      top: 0;
      background-color: ${Colors.grey_blue_5};
    }

    &.active,
    &:hover {
      svg {
        fill: ${Colors.error};
      }
    }
  }

  /* Horizontal line */
  &.has-siblings {
    border-top: solid 1px ${Colors.grey_blue_5};
    min-width: 384px; // NOTE: quick fix, design of canvas will change soon
    position: relative;
    bottom: 18px;

    .btn-delete-branch {
      padding-top: 24px;
      margin-top: 0;
    }

    &:before {
      height: ${ConnectorBeforeHeight};
      color: ${Colors.grey_blue_4};
    }

    /* Is not the first or last branch */
    &.is-vertex-branch {
      &:before {
        height: 19px;
      }

      .btn-delete-branch {
        padding-top: 44px;

        &:before {
          height: 46px;
        }
      }
    }

    /* Is the first or last branch */
    &.is-edge-branch {
      border-top: 0;

      &:before {
        content: '';
        width: 50%;
        height: ${ConnectorRadius};
        border-top: ${Colors.grey_blue_5} solid 1px;
      }

      .step-header {
        .btn-delete-branch {
          &:before {
            height: 20px;
            top: 0;
          }
        }
      }
    }
  }

  /* Left side corner */
  &.is-first-branch {
    &:before {
      content: '';
      margin-left: 50%;
      border-left: ${Colors.grey_blue_5} solid 1px;
      border-radius: ${ConnectorRadius} 0 0 0;
    }
  }

  /* Right side corner */
  &.is-last-branch {
    &:before {
      content: '';
      border-right: ${Colors.grey_blue_5} solid 1px;
      border-radius: 0 ${ConnectorRadius} 0 0;
      right: 25%;
      position: relative;
      top: 0;
    }
  }
`;
