//
import React, { Fragment } from 'react';
import cx from 'classnames';
import { ColoredIcons } from '@whispir/ui-lib-v2';

const { Web, Sms, Email, Send } = ColoredIcons;

const Icons = {
  sms: Sms,
  email: Email,
  web: Web,
};

const Task = ({ node, isValid }) => {
  const { embeddedChannels } = node.data;

  const classes = cx({ dirty: node.isDirty, error: !isValid });
  let label = 'Create message';

  if (isValid && node.isDirty) {
    label = null;
  }

  const renderChannelIcon = (name) => {
    const channel = embeddedChannels[name];

    const isIconDisplayed =
      (channel && channel.body) || (channel && channel.components.length);

    try {
      if (isIconDisplayed) {
        return (
          <div
            data-icontype={name}
            className={`${name} icon-shadow`}
            key={name}
          >
            {Icons[name]}
          </div>
        );
      }
    } catch (err) {}

    return null;
  };

  const renderGroupIcon = () => {
    const icons = Object.keys(Icons).map(renderChannelIcon).filter(Boolean);

    if (!icons.length) {
      return (
        <Fragment>
          <div className="icon-shadow icon-pos-send">{[Send]}</div>
          <div className={`label ${classes}`}>Send</div>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <div className={`${icons.length !== 1 ? 'group-icon' : 'single-icon'}`}>
          {icons}
        </div>
        <div className="label">Send</div>
      </Fragment>
    );
  };

  return (
    <Fragment>
      {renderGroupIcon()}
      <div className={`state ${classes}`} title={label}>
        {label}
      </div>
    </Fragment>
  );
};

Task.defaultProps = {
  id: 0,
  data: {},
  type: '',
  action: '',
  isDirty: false,
  next: [],
};

export default Task;
