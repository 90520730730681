import React from 'react';
import {
  SmsContentPanel,
  SmsPreviewDisplay,
  InfoPanel,
} from '@whispir/ui-lib-v2';
import { doesNodeContainContentButNoWeblink } from '@whispir/workflow-data-model';
import { SMSBuilderWrapper } from './EmbeddedChannelModal.style';

type SmsPanelLayoutProps = {
  onUpdateChannelObj: Function,
  messageType: string,
  body: string,
  variableList: Array,
};

export const SmsPanelLayout = ({
  onUpdateChannelObj,
  messageType,
  body,
  variableList,
  node,
}: SmsPanelLayoutProps) => {
  const hasVariable = body.includes('</span>');

  const handleUpdate = (...data) => {
    onUpdateChannelObj(...data);
  };

  return (
    <SMSBuilderWrapper hasVariable={hasVariable}>
      <div className='sms-panel-wrapper'>
        <SmsContentPanel
          hasCharacterCount
          onUpdate={handleUpdate}
          channelName={messageType}
          draft={body}
          variableMenuData={variableList}
        />
      </div>

      <div className='variable-info-wrapper'>
        {node.action === 'send-webform' &&
          doesNodeContainContentButNoWeblink(node) && (
            <InfoPanel
              type='alert'
              message='The link to your web form hasn’t been added. Insert Weblink from the available variables.'
            />
          )}

        <InfoPanel
          message='Due to dynamic content, the message preview and character
      count are approximate only.'
        />
      </div>
      <SmsPreviewDisplay message={body} type='sms' />
    </SMSBuilderWrapper>
  );
};
