//
import React, { PureComponent } from 'react';
import { Icons } from '@whispir/ui-lib-v2';
import { PanZoomContext } from '../PanZoomControls/PanZoom.context';
import { StyledPopover } from './PopoverPanel.style';

export class PopoverPanel extends PureComponent {
  static contextType = PanZoomContext;

  static defaultProps = {
    popoverposition: '',
    noCloseButton: false,
  };

  componentWillMount() {
    const { setIsPanEnable } = this.context;
    setIsPanEnable && setIsPanEnable(false);
  }

  componentWillUnmount() {
    const { setIsPanEnable } = this.context;
    setIsPanEnable && setIsPanEnable(true);
  }

  determinePopoverPosition() {
    const { popoverposition } = this.props;
    switch (popoverposition) {
      case 'split':
        return '-8px 0 0 24px';
      default:
        return '-8px 0 0 -8px';
    }
  }

  render() {
    const { domElement, handleClose, children, noCloseButton } = this.props;

    return (
      <StyledPopover
        popoverposition={this.determinePopoverPosition()}
        open={Boolean(domElement)}
        anchorEl={domElement}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {!noCloseButton && (
          <button type="button" className="close" onClick={handleClose}>
            <Icons.Close />
          </button>
        )}
        {children}
      </StyledPopover>
    );
  }
}

export default PopoverPanel;
