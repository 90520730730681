import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { Modal, Button } from '@whispir/ui-lib-v2';
import { useWorkflowContext } from '../../state/Workflow.context';

// Copied from https://stackoverflow.com/a/7220510
const syntaxHighlight = (json) => {
  let prettyPrintedJson;
  if (typeof json !== 'string') {
    prettyPrintedJson = JSON.stringify(json, null, 2);
  } else {
    prettyPrintedJson = json;
  }

  const escapedJson = prettyPrintedJson
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;');

  const highlightedJsonHtml = escapedJson.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
    (match) => {
      let cls = 'number';
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = 'key';
        } else {
          cls = 'string';
        }
      } else if (/true|false/.test(match)) {
        cls = 'boolean';
      } else if (/null/.test(match)) {
        cls = 'null';
      }
      return `<span class="${cls}">${match}</span>`;
    },
  );

  return highlightedJsonHtml;
};

const StyledDefinition = styled.div`
  pre {
    .string {
      color: green;
    }
    .number {
      color: darkorange;
    }
    .boolean {
      color: blue;
    }
    .null {
      color: magenta;
    }
    .key {
      color: red;
    }
  }
`;

export const Debug = () => {
  const [wfState] = useWorkflowContext();
  const [isOpen, setIsOpen] = useState(false);
  const definitionTextAreaRef = useRef(null);

  const definition = wfState.workflow.structure;

  const highlightedDefinition = syntaxHighlight(definition, null, 2);

  const copyDefinitionToClipboard = () => {
    definitionTextAreaRef.current.select();
    document.execCommand('copy');
  };

  // Generate an render time error
  const [, setValue] = useState({});

  const handleThrowError = () => {
    setValue(null);
  };

  return (
    <React.Fragment>
      <button
        style={{
          width: '60px',
          height: '60px',
          position: 'fixed',
          right: 0,
          bottom: 0,
          fontSize: 10,
          zIndex: 99,
        }}
        className="debug-button"
        type="button"
        onClick={() => setIsOpen(true)}
      >
        debug
      </button>

      <Modal
        isOpen={isOpen}
        handleExit={() => setIsOpen(false)}
        isFullScreen
        bodyContent={
          <StyledDefinition>
            <Button
              text="Copy to Clipboard"
              onClick={copyDefinitionToClipboard}
            />
            <Button onClick={handleThrowError} text="Throw Error" />

            <pre dangerouslySetInnerHTML={{ __html: highlightedDefinition }} />
            {/* this textarea exists just so we can copy the contents to the clipboard */}
            <textarea ref={definitionTextAreaRef}>
              {JSON.stringify(definition, null, 2)}
            </textarea>
          </StyledDefinition>
        }
      />
    </React.Fragment>
  );
};
