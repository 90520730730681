//
import React from 'react';
import cx from 'classnames';
import { Icons } from '@whispir/ui-lib-v2';

import { getDurationDisplayValues } from '../../utils/timeFormatterUtils';

const Time = ({ node, isValid }) => {
  const { delay } = node.data;
  const classes = cx('content', {
    error: !isValid,
  });
  let label = 'Set your wait time';

  // Only display schedule if valid and changed
  if (node.isDirty && isValid) {
    label = getDurationDisplayValues(delay, 'Wait');
  }

  return (
    <div className="condition">
      <span className={classes}>
        <Icons.Processing />
        {label}
      </span>
    </div>
  );
};

export default Time;
