/* eslint-disable max-lines */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
//
import React, { useState, useEffect } from 'react';
import { Spinner, RecipientSelector } from '@whispir/ui-lib-v2';
import {
  queries,
  useLoadMoreContactsFunction,
  useLoadMoreDistributionsFunction,
} from '@whispir/message-studio-graphql';
import { sendMessageFactory, RECIPIENT_TYPES } from '@whispir/workflow-factory';
import { partition } from '../../../utils/generalFunctions';

const { recipientFactory } = sendMessageFactory;
const { ADHOC, VARIABLE, DISTRIBUTION_LIST, CONTACT } = RECIPIENT_TYPES;
const {
  generateAdhocRecipient,
  generateContactRecipient,
  generateDistributionListRecipient,
  generateVariableRecipient,
} = recipientFactory;

/**
 * This component takes care of converting to/from the list of workflow recipients and the format the recipient selector expects
 * It also sets up the apollo queries for loading the contacts/distributions lists
 *
 * @param {} props
 */

export const RecipientSelectorConvenienceWrapper = (props) => {
  const {
    client,
    onRecipientsChange,
    availableVariables,
    recipients,
    workspaceId,
    messageType,
  } = props;

  // Loading at first while we fetch the contacts and distribution lists.
  const [contactsIsLoading, setContactsIsLoading] = useState(true);
  const [distributionsIsLoading, setDistributionsIsLoading] = useState(true);

  const initialDataIsLoading = contactsIsLoading || distributionsIsLoading;

  // These are the full versions (ie. with names etc) - what the recipient selector expects
  const [selectedLongVariables, setSelectedLongVariables] = useState([]);
  const [selectedLongContacts, setSelectedLongContacts] = useState([]);
  const [selectedLongDistributions, setSelectedLongDistributions] = useState(
    [],
  );

  // These are async functions passed into react-select to do the lazy loading.
  const loadMoreContacts = useLoadMoreContactsFunction(client, workspaceId);
  const loadMoreDistributions = useLoadMoreDistributionsFunction(
    client,
    workspaceId,
  );

  // onMount
  useEffect(() => {
    if (!recipients) {
      throw new Error(
        'Recipients does not exist - you have probably loaded an invalid workflow',
      );
    }

    // Sort the recipients into their types
    const [
      adhocs,
      variables,
      contacts,
      distributionLists,
    ] = partition(recipients, [
      (recipient) => recipient.type === ADHOC,
      (recipient) => recipient.type === VARIABLE,
      (recipient) => recipient.type === CONTACT,
      (recipient) => recipient.type === DISTRIBUTION_LIST,
    ]);

    // Convert plain ol' variables and adhocs into the full versions
    const flattenedAvailableVariables = availableVariables.reduce(
      (acc, cur) => {
        return [...acc, ...cur.variables];
      },
      [],
    );

    const foundVariableRecipients = flattenedAvailableVariables.filter(
      (availableVariable) =>
        variables.some(
          (variableRecipient) =>
            variableRecipient.variableId === availableVariable.variableId &&
            // variableRecipient.stepId is saved with type `string`,
            // whilst the availableVariable.stepId is saved with type `number`.
            // We convert the availableVariable.stepId to type `string` for a same-type comparison.
            variableRecipient.stepId === availableVariable.stepId.toString(),
        ),
    );

    const foundAdhocRecipients = adhocs.map((adhocRecipient) => {
      return {
        value: adhocRecipient.value,
        isAdhoc: true,
      };
    });

    // Set them as selected.
    setSelectedLongVariables([
      ...foundVariableRecipients,
      ...foundAdhocRecipients,
    ]);

    // Fetch the full versions of the contacts and distribution lists
    // And set them as selected.
    const { query } = client;

    async function loadInitialContacts() {
      const { data } = await query({
        query: queries.GET_CONTACTS_BY_IDS,
        variables: {
          ids: contacts.map((contact) => contact.iuxId),
          workspaceId,
        },
      });
      setSelectedLongContacts(data.workspaceContact.contactsByIds);
      setContactsIsLoading(false);
    }

    async function loadInitialDistributionLists() {
      const { data } = await query({
        query: queries.GET_DISTRIBUTIONS_BY_IDS,
        variables: {
          ids: distributionLists.map(
            (distributionList) => distributionList.iuxId,
          ),
          workspaceId,
        },
      });
      setSelectedLongDistributions(data.workspaceContact.distributionsByIds);
      setDistributionsIsLoading(false);
    }

    loadInitialContacts();
    loadInitialDistributionLists();
    // on mount only please.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Every time any of the long recipients change, we convert all of them to the short recipients, and call the onChange handler.
   * This is a little inefficient, but I doubt that the user is going to be selecting huge numbers.
   * The alternative is that you need to do individual handlers for each recipient type and store the short versions in state.
   */
  useEffect(() => {
    const [adhocs, variables] = partition(selectedLongVariables, [
      (variable) => variable.isAdhoc,
      (variable) => !variable.isAdhoc,
    ]);

    const adhocRecipients = adhocs.map((adhocRecipient) =>
      generateAdhocRecipient({ value: adhocRecipient.value }),
    );
    const variableRecipients = variables.map(({ variableId, stepId }) =>
      generateVariableRecipient({ variableId, stepId: `${stepId}` }),
    );

    const contactRecipients = selectedLongContacts.map(
      (selectedLongContactRecipient) =>
        generateContactRecipient({ iuxId: selectedLongContactRecipient.id }),
    );
    const distributionListRecipients = selectedLongDistributions.map(
      (selectedLongDistributionRecipient) =>
        generateDistributionListRecipient({
          iuxId: selectedLongDistributionRecipient.id,
        }),
    );

    onRecipientsChange([
      ...adhocRecipients,
      ...variableRecipients,
      ...contactRecipients,
      ...distributionListRecipients,
    ]);
  }, [
    selectedLongVariables,
    selectedLongContacts,
    selectedLongDistributions,
    onRecipientsChange,
  ]);

  return initialDataIsLoading ? (
    <Spinner />
  ) : (
    <RecipientSelector
      variableEnabled
      mode={messageType}
      availableVariables={availableVariables}
      selectedVariables={selectedLongVariables}
      onChangeVariables={setSelectedLongVariables}
      loadMoreContactsFunction={loadMoreContacts}
      selectedContacts={selectedLongContacts}
      onChangeContacts={setSelectedLongContacts}
      loadMoreDistributionListsFunction={loadMoreDistributions}
      selectedDistributionLists={selectedLongDistributions}
      onChangeDistributionLists={setSelectedLongDistributions}
    />
  );
};
