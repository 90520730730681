import React from 'react';
import { ErrorScreen, Button, Illustrations } from '@whispir/ui-lib-v2';
import { prefix } from '../../router/routerConstants';

const NotFound = (props) => {
  const { history } = props;
  return (
    <ErrorScreen
      title="404 … Page Not Found!"
      errorMessage="Sorry, the page you requested has gone missing."
      banner={<Illustrations.Climber />}
      backButton={
        <Button
          variant="link"
          text="Go Back"
          onClick={() => history.push(prefix)}
        />
      }
    />
  );
};

export default NotFound;
