/* eslint-disable react/prop-types */
import React, { useCallback, useContext } from 'react';
import { useSegmentContext } from '@whispir/ui-lib-v2';
import { WorkflowContext, UserContext } from '../../state';
import { BaseAddNodeButton } from './BaseAddNodeButton';
import { getSelectedWorkspaceId } from '../../utils/helperUtils';

export const AddLeafNodeButton = (props) => {
  const { previousNode } = props;

  const [workflowContext, setContext] = useContext(WorkflowContext);
  const [userContext] = useContext(UserContext);
  const { trackEvent } = useSegmentContext();
  const {
    user: { rawUserId },
  } = userContext;

  if (
    !workflowContext ||
    !workflowContext.workflow ||
    !workflowContext.workflow.addStep
  ) {
    throw new Error(
      "Created an AddLeafNodeButton, but workflow context didn't exist",
    );
  }

  const { workflow } = workflowContext;
  const { addStep } = workflow;

  const handleAddNode = useCallback(
    (actionType) => {
      addStep(previousNode, actionType, getSelectedWorkspaceId());
      setContext({ ...workflowContext });
      trackEvent({
        event: 'Step Added',
        userId: rawUserId,
        properties: {
          workflowId: workflow.id,
          stepAction: actionType,
        },
      });
    },
    [
      addStep,
      previousNode,
      rawUserId,
      setContext,
      workflow.id,
      workflowContext,
      trackEvent,
    ],
  );

  return (
    <BaseAddNodeButton onAddNode={handleAddNode} previousNode={previousNode} />
  );
};
