// Provides an 'error', 'loading' and 'submit' properties for a given graphql mutation.

// USE:
// import withNamedMutation from '.../apollo/withNamedMutation';
// import { graphql, compose } from 'react-apollo';
// import { MY_MUTATION } from '.../apollo/mutations';
//
// MyComponent...
//
// export default withNamedMutation(MY_MUTATION, { name: 'myMutation')(
//   MyComponent
// );
//
// Or using compose for multiple HOCs:
//
// export default compose(
//  withNamedMutation(MY_MUTATION, { name: 'myMutation')
//  withNamedMutation(MY_OTHER_MUTATION, { name: 'myOtherMutation')
// )(MyComponent);

//
import React, { Component } from 'react';
import { graphql } from 'react-apollo';

export default function withNamedMutation(operation, { name } = {}) {
  // some initial render-cycles omit these props
  const hasMutation = !!operation && !!name;

  return (SourceComponent) => {
    class WithNamedMutation extends Component {
      state = {
        error: '',
        isLoading: false,
        isSuccess: false,
      };

      submit = async (variables) => {
        const { mutate } = this.props;

        this.setState({ isLoading: true });

        try {
          await mutate({ variables });
          this.setState({ isLoading: false, isSuccess: true });
        } catch ({ message }) {
          this.setState({
            isLoading: false,
            isSuccess: false,
            error: message,
          });
        }
      };

      render() {
        // Note: extract mutate to avoid passing it to wrapped component
        const { mutate, ...props } = this.props;
        const {
          submit,
          state: { error, isLoading, isSuccess },
        } = this;
        const newProps = {
          ...props,
        };

        if (hasMutation) {
          newProps[name] = {
            error,
            isSuccess,
            isLoading,
            submit,
          };
        }

        return <SourceComponent {...newProps} />;
      }
    }

    if (hasMutation) {
      return graphql(operation)(WithNamedMutation);
    }

    return WithNamedMutation;
  };
}
