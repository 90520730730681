import React, { Fragment, useContext, useEffect } from 'react';
import { errors } from '@whispir/workflow-validation';
import { SideDrawerProvider, useSegmentContext } from '@whispir/ui-lib-v2';
import { getRootStep } from '@whispir/workflow-definition';
import StartConfirmation from '../../components/StartConfirmation';
import PanZoomControls from '../../components/PanZoomControls';
import TreeDiagram from '../../components/TreeDiagram';
import LightboxPanel from '../../components/LightboxPanel';
import Header from '../../components/Header';
import TriggerButton from '../../components/TriggerButton';
import { WorkflowContext, UserContext } from '../../state';
import { displaySpecificErrorMessage } from '../../utils/helperUtils';
import { ContentWrapper, OuterWrapper } from './Builder.style';
import { InnerWrapper } from './InnerWrapper';

const { emptyNodeError } = errors;

export const Canvas = ({
  startWorkflow,
  showNotification,
  handleSaveWorkflow,
  isSavingInProgress,
  closePublishConfirmation,
  publishWorkflow,
  isWorkflowActive,
  history,
}) => {
  const [workflowContext, setWorkflowContext] = useContext(WorkflowContext);
  const [userContext] = useContext(UserContext);
  const { trackEvent } = useSegmentContext();
  const {
    user: { rawUserId },
  } = userContext;
  const {
    workflow,
    workflow: { title, structure, id },
    lightboxProps,
  } = workflowContext;

  const startNode = getRootStep(structure);

  const hasData =
    (startNode && startNode.type !== 'start') ||
    (startNode && startNode.type === 'start' && startNode.next);

  const startNodeType = startNode.data.triggers[0].type;

  const isManualTrigger = startNodeType === 'manual';

  useEffect(() => {
    if (id === '0') {
      trackEvent({
        event: 'Workflow Created',
        userId: rawUserId,
        properties: {
          workflowId: '0',
          triggerType: isManualTrigger ? 'Manual trigger' : 'Publish trigger',
        },
      });
    } else {
      trackEvent({
        event: 'Workflow Opened',
        userId: rawUserId,
        properties: {
          workflowId: id,
        },
      });
    }
  }, [workflow, userContext, id, rawUserId, isManualTrigger, trackEvent]);

  const close = () => {
    setWorkflowContext({ ...workflowContext, lightboxProps: null });
  };

  const activateWorkflow = () => {
    startWorkflow(workflow)();
    const lightboxProps = {
      onCancel: close,
      title: 'Trigger This Workflow?',
      confirm: '',
      children: <StartConfirmation />,
    };
    setWorkflowContext({ ...workflowContext, lightboxProps });
  };

  const triggerButtonHandler = () => {
    const {
      user: { rawUserId },
    } = userContext && userContext;
    workflow.started = false;
    if (!isWorkflowValid()) {
      setWorkflowContext({ ...workflowContext });
      // This is a bit of edge case to get a more specific error when
      // the workflow fails to trigger due to an empty node exists.
      const errMessage = displaySpecificErrorMessage(
        workflow.errors,
        emptyNodeError,
      );

      if (errMessage) {
        showNotification({
          message: errMessage,
          success: false,
        });
        return;
      }

      showNotification({ message: errors.workflowValidation, success: false });
      return;
    }

    const lightboxProps = {
      onConfirm: activateWorkflow,
      onCancel: close,
      confirm: 'Trigger',
      title: 'Trigger This Workflow?',
      children: <StartConfirmation />,
    };
    setWorkflowContext({ ...workflowContext, lightboxProps });

    trackEvent({
      event: 'Workflow Published',
      userId: rawUserId,
      properties: {
        workflowId: id,
      },
    });
  };

  const isWorkflowValid = () => workflow.validate();
  const handleWorkflowTitleChange = (title) => {
    workflow.title = title;
  };

  return (
    <Fragment>
      <Header
        history={history}
        hasCloseButton
        isSavingInProgress={isSavingInProgress}
        onChangeTitle={handleWorkflowTitleChange}
        onClickSave={handleSaveWorkflow}
        closePublishConfirmation={closePublishConfirmation}
        title={title}
        showNotification={showNotification}
        /**
         * We need to clean this file when we have the chance
         *
         * the reason I am passing down the workflow instance is to get access to 'workflow.errors'
         * which will only be populated once workflow.validate() is called and an error is found
         */
        workflowModel={workflow}
        workflow={{
          id,
          valid: isWorkflowValid,
          triggerHandler: triggerButtonHandler,
          publishHandler: publishWorkflow,
          hasData: Boolean(hasData),
          startNodeType,
          isWorkflowActive,
        }}
      />
      <OuterWrapper>
        <SideDrawerProvider fullScreen>
          <PanZoomControls>
            <InnerWrapper>
              <ContentWrapper>
                <TriggerButton />
                <TreeDiagram data={structure} />
              </ContentWrapper>
            </InnerWrapper>
          </PanZoomControls>
        </SideDrawerProvider>
      </OuterWrapper>
      {lightboxProps && <LightboxPanel {...lightboxProps} />}
    </Fragment>
  );
};

export default Canvas;
