import { doCustomValidation } from '@whispir/workflow-data-model';

export const getSmsContent = (nodeData) => {
  const {
    message: {
      content: { body },
    },
  } = nodeData;
  return body;
};

export const constructEmbeddedChannels = (nodeData, node) => {
  const {
    message: { content, type },
    link,
  } = nodeData;

  let embeddedChannels = {};

  if (type === 'sms') {
    embeddedChannels = {
      ...embeddedChannels,
      [type]: {
        id: type.toUpperCase(),
        body: content.body,
        components: [],
        errorMessage: doCustomValidation(node),
      },
    };
  }

  if (type === 'email') {
    embeddedChannels = {
      ...embeddedChannels,
      [type]: {
        id: 'Email',
        title: content.subject,
        components: content.components,
        errorMessage: doCustomValidation(node),
        contentId: content.contentId,
      },
    };
  }

  const webChannelKey =
    node && node.action === 'send-webform' ? 'webform' : 'web';
  const webChannelLabel =
    node && node.action === 'send-webform' ? 'Web Form' : 'Web';
  if (link && link.type === 'web') {
    // added a ternary operation to display the right ordering. not a pretty solution but will do for now
    webChannelLabel === 'Web Form'
      ? (embeddedChannels = {
          [webChannelKey]: {
            id: webChannelLabel,
            title: link.content.subject,
            components: link.content.components,
            contentId: link.content.contentId,
          },
          ...embeddedChannels,
        })
      : (embeddedChannels = {
          ...embeddedChannels,
          [webChannelKey]: {
            id: webChannelLabel,
            title: link.content.subject,
            components: link.content.components,
            contentId: link.content.contentId,
          },
        });
  } else {
    // added a ternary operation to display the right ordering. not a pretty solution but will do for now
    webChannelLabel === 'Web Form'
      ? (embeddedChannels = {
          [webChannelKey]: {
            id: webChannelLabel,
            title: '',
            components: [],
          },
          ...embeddedChannels,
        })
      : (embeddedChannels = {
          ...embeddedChannels,
          [webChannelKey]: {
            id: webChannelLabel,
            title: '',
            components: [],
          },
        });
  }

  return embeddedChannels;
};
