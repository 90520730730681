import React, { Fragment } from 'react';

const StartConfirmation = () => (
  <Fragment>
    <p>You’re about to start this workflow.</p>
    <p>
      <b>
        Once triggered, you can’t stop messages until the steps are completed.
      </b>
    </p>
  </Fragment>
);
export default StartConfirmation;
