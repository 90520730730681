import React, { useContext, useEffect } from 'react';
import {
  useSegmentContext,
} from '@whispir/ui-lib-v2';

import { UserContext } from '../../state';
import { MainWrapper, ContentWrapper } from './Layout.style';
import { getSelectedWorkspaceId } from '../../utils/helperUtils';

const Layout = (props) => {
  const userContextArray = useContext(UserContext);

  const segmentContext = useSegmentContext();
  if (!userContextArray) {
    throw new Error('User context not provided');
  }

  if (!segmentContext) {
    throw new Error('Segment context not provided');
  }

  const { trackGroup, trackIdentity, trackPage, getUserAgent } = segmentContext;

  const {
    REACT_APP_SEGMENT_ENVIRONMENT: segmentEnv,
    REACT_APP_REGION: region,
  } = process.env;
  const [userContext] = userContextArray;

  const { children, workflowCount, ...otherProps } = props;

  useEffect(() => {
    const {
      user: { companyId, details, userId, rawUserId, userName },
    } = userContext;
    const {
      companyName,
      companyRole,
      createdAt,
      firstName,
      lastName,
      productTier,
      workEmailAddress1,
      salesForceBillingId,
    } = details;
    const groupId = `${segmentEnv}${companyId}`;

    trackGroup({
      userId: rawUserId,
      groupId,
      traits: {
        id: groupId,
        name: companyName,
        plan: productTier,
      },
    });

    if (firstName) {
      trackIdentity({
        userId: rawUserId,
        traits: {
          createdAt,
          firstName,
          lastName,
          name: `${firstName} ${lastName}`,
          productTier,
          userRole: companyRole,
          email: workEmailAddress1,
          userId: rawUserId,
          userName,
          userAgent: getUserAgent(),
          workspaceId: getSelectedWorkspaceId(),
          groupId,
          region,
          companyId: groupId,
          companyName,
          plan: productTier,
          accountExternalId: salesForceBillingId,
          contactExternalId: workEmailAddress1,
        },
      });
    }

    trackPage({
      page: 'Workflows',
      userId,
    });
  }, [
    getUserAgent,
    region,
    segmentEnv,
    trackGroup,
    trackIdentity,
    trackPage,
    userContext,
  ]);

  return (
    <MainWrapper>
      <ContentWrapper {...otherProps}>{children}</ContentWrapper>
    </MainWrapper>
  );
};

Layout.defaultProps = {
  children: <div />,
};

export default Layout;
