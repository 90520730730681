/* eslint-disable max-lines */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
//
import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useRef,
  Fragment,
} from 'react';

import {
  SideDrawerContent,
  IconButton,
  CollectResponses,
  ChannelSelector,
  Button,
  ButtonGroup,
  Divider,
  DurationSelector,
  useSegmentContext,
  useFeatureToggle,
} from '@whispir/ui-lib-v2';
import { ACTION_TYPES } from '@whispir/workflow-factory';
import { validators } from '@whispir/workflow-validation';
import { withRouter } from 'react-router-dom';
import { prefix } from '../../../router/routerConstants';
import { withApollo } from 'react-apollo';
import { validateStepName } from '@whispir/workflow-data-model';
import { useWorkflowContext } from '../../../state/Workflow.context';
import { useDelayedInvoke } from '../../../state/utils';
import { UserContext } from '../../../state';
import { constructEmbeddedChannels } from '../../EmbeddedChannelModal/utils';
import { getFormattedErrors } from '../../TreeDiagram/utils/timeFormatterUtils';
import { WebformChannelSelection } from './WebformChannelSelection';
import { initialiseWebformNode } from './utils';
import {
  StyledAccordianStepper,
  StyledEditableTitle,
} from './SendMessageLayout.styles';
import { RecipientSelectorConvenienceWrapper } from './RecipientSelectorConvenienceWrapper';

const SAVE_TRIGGER_DELAY = 100;

const { SEND_SMS, SEND_WEBFORM } = ACTION_TYPES;

const COLLECT_RESPONSE_ACTION_TYPES = [SEND_SMS, SEND_WEBFORM];

const stepActionToEventMap = {
  'send-sms': 'Sms Response Capture',
  'send-webform': 'Webform Response Capture',
};

export const SendMessageLayout = ({
  mode,
  history,
  match,
  node,
  onCloseRequest,
  onUnmount,
  client, // the apollo client
}) => {
  /**
   * forceUpdate updates state to force a re-render.
   * This is required because we are mutating the context, and React does not detect the changes.
   *  */
  const { isToggleEnabled } = useFeatureToggle();
  const { push } = history;
  const { params } = match;
  const { workspaceId } = node.data;

  const [workflowContext, setWorkflowContext] = useWorkflowContext();

  const { workflow } = workflowContext;
  const { updateNodeData } = workflow;
  const [userContext] = useContext(UserContext);
  const { trackEvent } = useSegmentContext();
  const {
    user: { rawUserId },
  } = userContext;

  useEffect(() => {
    return () => {
      onUnmount();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateUrlParams = (channelType) => {
    if (!push || !params) return;
    setWorkflowContext({ ...workflowContext, activeChannel: channelType });
    push(`${prefix}/${params.flowId}/${node.id}/${channelType}`);
  };

  const [recipients, setRecipients] = useState(node.data.recipients);
  const availableVariables = workflow.getWorkflowVariableGroupsByStepId(
    node.id,
    'recipient',
  );

  const saveUpdates = useCallback(() => {
    updateNodeData(node.id, { recipients });
    setWorkflowContext({ ...workflowContext });

    const {
      data: { message, link },
    } = node;

    const webPageAdded = link !== null;
    trackEvent({
      event: 'Send Message Saved',
      userId: rawUserId,
      properties: {
        workflowId: workflow.id,
        channel: message.type,
        webPageAdded,
      },
    });
  }, [
    updateNodeData,
    node,
    recipients,
    setWorkflowContext,
    workflowContext,
    rawUserId,
    workflow.id,
    trackEvent,
  ]);

  const saveUpdatesDelayed = useDelayedInvoke({
    delayedFunction: saveUpdates,
    delay: SAVE_TRIGGER_DELAY,
  });

  useEffect(() => {
    saveUpdatesDelayed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipients]); // adding saveUpdatesDelayed to this array  is causing the tests not to run.

  const isUsingSmsCollectResponses =
    isToggleEnabled('collect-responses') && node.action === SEND_SMS;
  const isUsingWebformCollectResponses =
    isToggleEnabled('webform-responses') && node.action === SEND_WEBFORM;
  const isSmsTask = mode === SEND_SMS;

  const currentCollectResponseDuration =
    (isUsingSmsCollectResponses || isUsingWebformCollectResponses) &&
    node.data.collectResponses
      ? node.next[0].data.collectResponses.duration
      : null;
  const [duration, setDuration] = useState(currentCollectResponseDuration);
  const [collectResponsesEnabled, setCollectResponsesEnabled] = useState(
    node.data.collectResponses,
  );

  const handleCollectResponsesDurationChange = useCallback(
    (duration) => {
      // When the user tries to remove existing collectResponses on SMS, display warning modal
      if (!duration && workflow.doesNodeHaveChildren(node.id)) {
        setCollectResponsesEnabled(false);
        const lightboxProps = {
          onConfirm: () => {
            setCollectResponsesEnabled(false);
            workflow.setCollectResponsesForNode(node.id, duration);
            setWorkflowContext({ ...workflowContext, lightboxProps: null });
          },
          onCancel: () => {
            setCollectResponsesEnabled(true);
            setWorkflowContext({ ...workflowContext, lightboxProps: null });
          },
          confirm: 'Confirm',
          title: 'Are you sure you want to stop collecting responses?',
          children: (
            <div>
              Turning this off will remove all steps below this step. This
              cannot be undone.{' '}
            </div>
          ),
        };

        setWorkflowContext({
          ...workflowContext,
          lightboxProps,
        });
      } else {
        setCollectResponsesEnabled(true);
        setDuration(duration);
        workflow.setCollectResponsesForNode(node.id, duration);
        setWorkflowContext({ ...workflowContext });

        const event = stepActionToEventMap[node.action];

        if (event) {
          trackEvent({
            event,
            userId: rawUserId,
            properties: {
              captureDuration: duration,
              workflowId: workflow.id,
            },
          });
        } else {
          console.error(
            `No event mapping found for step action: '${node.action}'`,
          );
        }
      }
    },
    [
      workflow,
      node.id,
      node.action,
      setWorkflowContext,
      workflowContext,
      trackEvent,
      rawUserId,
    ],
  );

  const [durationErrorMessages, setDurationErrorMessages] = useState();

  useEffect(() => {
    if (node.data.collectResponses && duration) {
      // That validator doesn't actually belong to the send-message step, it belongs to the child.
      // This is a bit messy, but it's what we've got for now.
      const [responseReceivedNode] = node.next;
      const error = getFormattedErrors(
        validators[responseReceivedNode.action](responseReceivedNode.data),
      );

      setDurationErrorMessages(error);
    }
  }, [
    node.data.collectResponses,
    duration,
    node.data.stepName,
    node.id,
    node.next,
  ]);

  const defaultDurationValue = {
    minutes: 0,
    hours: 0,
    days: 1,
  };

  return (
    <Fragment>
      <StyledAccordianStepper
        nonLinear
        showStepButtons={false}
        steps={[
          {
            title: 'To',
            description:
              'Choose your recipients by using some or all of the options below.',
            isValid: true,
            content: (
              <RecipientSelectorConvenienceWrapper
                messageType={node.data.message.type}
                workspaceId={workspaceId}
                availableVariables={availableVariables}
                onRecipientsChange={setRecipients}
                recipients={recipients}
                client={client}
              />
            ),
          },
          {
            title: 'Content',
            description:
              'Create your message, personalise it with variable content and optionally add a linked web page.',
            isValid: true,
            content: (
              <ChannelSelector
                action={updateUrlParams}
                channels={constructEmbeddedChannels(node.data, node)}
              />
            ),
          },
          ...(isUsingSmsCollectResponses && isSmsTask
            ? [
                {
                  title: 'Collect Responses',
                  description:
                    'Collect responses to conditionally trigger other parts of your Workflow.',
                  isValid: true,
                  content: (
                    <CollectResponses
                      selectedDuration={currentCollectResponseDuration}
                      enabled={collectResponsesEnabled}
                      onChange={handleCollectResponsesDurationChange}
                      errors={durationErrorMessages}
                      defaultDuration={defaultDurationValue}
                    />
                  ),
                },
              ]
            : []),

          ...(node.action === SEND_WEBFORM
            ? [
                {
                  title: 'Settings',
                  description: 'How long will the form be available for?',
                  isValid: true,
                  content: (
                    <DurationSelector
                      selectedDuration={currentCollectResponseDuration}
                      onChange={handleCollectResponsesDurationChange}
                      description="Web Form Expires After:"
                      defaultDuration={defaultDurationValue}
                      errors={durationErrorMessages}
                    />
                  ),
                },
              ]
            : []),
        ]}
      />
    </Fragment>
  );
};

const SendMessageLayoutWithRouter = withRouter(withApollo(SendMessageLayout));

export const FeatureFlaggedEmbeddedMessage = (props) => {
  const wfContext = useWorkflowContext();
  const [workflowContext, setWorkflowContext] = wfContext;
  const { workflow } = workflowContext;
  const { updateNodeData } = workflow;
  const { onDeleteClick, onCloseRequest, node, validate } = props;
  const {
    data: { stepName },
    action,
  } = node;

  const titleRef = useRef(null);
  const [errors, setErrors] = useState({ data: false });
  const [initialStepName] = useState(stepName);
  const [temporaryStepName, setTemporaryStepName] = useState(stepName);
  const [isEditingStepName, setIsEditingStepName] = useState(false);
  const [isStepNameValid, setIsStepNameValid] = useState(true);
  const handleEditTitleClick = useCallback(
    (v) => {
      setIsEditingStepName(true);
    },
    [setIsEditingStepName],
  );

  // Everytime the data changes
  useEffect(() => {
    const hasErrors = validate(node.data);
    setErrors((prev) => ({ ...prev, data: !!hasErrors }));
  }, [node.data, validate]);

  const handleSaveAndClose = useCallback(() => {
    updateNodeData(node.id, node.data);
    onCloseRequest();
  }, [updateNodeData, node.id, node.data, onCloseRequest]);

  const handleUpdateNodeStepName = useCallback(
    (stepName) => {
      const {
        id: nodeId,
        data: {
          message: { content },
          message,
        },
      } = node;
      updateNodeData(nodeId, { stepName });
      if (COLLECT_RESPONSE_ACTION_TYPES.includes(node.action)) {
        updateNodeData(nodeId, {
          message: {
            ...message,
            // this needs to be wrapped inside a <p> tag for replaceMessageVariables
            // in workflow engine to work
            content: { ...content, subject: `<p>${stepName}</p>` },
          },
        });

        // If we're collecting responses, also update the child on-sms-reply and on-sms-no-reply `stepName`'s
        if (node.data.collectResponses) {
          const [onReplyNode, onNoReplyNode] = node.next;

          updateNodeData(onReplyNode.id, {
            stepName: `Replies for: ${stepName}`,
          });
          updateNodeData(onNoReplyNode.id, {
            stepName: `No Replies for: ${stepName}`,
          });
        }
      }
      setWorkflowContext({ ...workflowContext });
    },
    [node, setWorkflowContext, updateNodeData, workflowContext],
  );

  useEffect(() => {
    if (isEditingStepName) {
      titleRef.current.focus();
    }
  }, [isEditingStepName]);

  const handleEditTitleSubmit = useCallback(() => {
    setIsEditingStepName(false);
  }, []);

  const handleTitleChange = useCallback(
    (event) => {
      const newStepName = event.target.value;
      if (validateStepName(newStepName, workflow.structure)) {
        handleUpdateNodeStepName(newStepName);
        setIsStepNameValid(true);
      } else {
        handleUpdateNodeStepName(initialStepName);
        setIsStepNameValid(false);
      }

      setTemporaryStepName(event.target.value);
    },
    [handleUpdateNodeStepName, initialStepName, workflow.structure],
  );

  // The currently selected webform mode (e.g. 'not-selected', SEND_SMS, SEND_EMAIL)
  const [selectedWebformMode, setSelectedWebformMode] = useState(
    'not-selected',
  );

  const handleSubmitWebformMode = () => {
    const { data: webformNodeData } = initialiseWebformNode({
      webformMode: selectedWebformMode,
      node,
    });
    updateNodeData(node.id, webformNodeData);
    setWorkflowContext({ ...workflowContext });
  };

  // The actual mode that comes from the node data.
  let actualWebformMode = 'not-selected';
  if (node.data.message.type) {
    actualWebformMode = `send-${node.data.message.type}`;
  }

  const mode = action === SEND_WEBFORM ? actualWebformMode : action;
  const webformDefaultMode =
    action === SEND_WEBFORM && actualWebformMode === 'not-selected';

  return (
    <SideDrawerContent
      title={
        <StyledEditableTitle onClick={handleEditTitleClick}>
          <Fragment>
            {isEditingStepName ? (
              <form onSubmit={handleEditTitleSubmit}>
                <input
                  className="title-input"
                  ref={titleRef}
                  onChange={handleTitleChange}
                  onBlur={handleEditTitleSubmit}
                  placeholder="Enter a name..."
                  value={temporaryStepName}
                />
              </form>
            ) : (
              <span className="send-step-title" role="button" tabIndex="-1">
                {temporaryStepName}
              </span>
            )}
            {!isStepNameValid && (
              <div className="error">This step name is already in use.</div>
            )}
          </Fragment>
        </StyledEditableTitle>
      }
      onCloseClick={onCloseRequest}
      header={
        <Fragment>
          <IconButton
            size="medium"
            icon="EditPen"
            onClick={handleEditTitleClick}
            className="edit-button"
          />
          <IconButton size="medium" icon="Delete" onClick={onDeleteClick} />
        </Fragment>
      }
      // We don't want to show "Save & Close" button when the user opens up the selections for webform channel delivery
      footer={
        !webformDefaultMode && (
          <Fragment>
            <Divider isHorizontal dividerType="solid" />
            <ButtonGroup>
              <Button
                disabled={Object.values(errors).some((hasError) => hasError)}
                onClick={handleSaveAndClose}
                text="Save &amp; Close"
                className="apply-button"
              />
            </ButtonGroup>
          </Fragment>
        )
      }
    >
      {webformDefaultMode ? (
        <WebformChannelSelection
          onWebformChannelSelection={setSelectedWebformMode}
          selectedChannel={selectedWebformMode}
          onCloseRequest={onCloseRequest}
          onSubmitClick={handleSubmitWebformMode}
        />
      ) : (
        <SendMessageLayoutWithRouter {...props} mode={mode} />
      )}
    </SideDrawerContent>
  );
};
