import { queries } from './queries';

/**
 *  PLEASE DON'T COPY PASTE THIS CODE.
 *  The conversion stuff here is too complicated to want to have everywhere.
 *  Instead, if we find that we need to use this else where, we should look at creating an HoC or similar to do it for you. *
 */

const getOptions = async (query, type) => {
  let options = [];
  let hasError = false;

  try {
    const { data } = await query();

    options = data.workspaceContact[type];
  } catch (err) {
    hasError = true;
  }

  return {
    options,
    hasError,
  };
};

export const useLoadMoreContactsFunction = (client, workspaceId) => {
  const { query } = client;
  return async (search, searchKey, prevOptions = []) => {
    const offset = prevOptions.length;

    const { options, hasError } = await getOptions(
      () =>
        query({
          query: queries.GET_CONTACTS,
          variables: {
            workspaceId,
            offset,
            limit: 10,
            predicates: {
              [searchKey]: search,
            },
          },
        }),
      'contacts'
    );

    return {
      options,
      hasMore: !hasError && options.length > 0,
    };
  };
};

export const useLoadMoreDistributionsFunction = (client, workspaceId) => {
  const { query } = client;
  return async (search, searchKey, prevOptions = []) => {
    const offset = prevOptions.length;

    const { options, hasError } = await getOptions(
      () =>
        query({
          query: queries.GET_DISTRIBUTIONS,
          variables: {
            workspaceId,
            offset,
            limit: 10,
            predicates: {
              [searchKey]: search,
            },
          },
        }),
      'lists'
    );

    return {
      options,
      hasMore: !hasError && options.length > 0,
    };
  };
};
