import React from 'react';
import { ActionCardRadioGroup, ButtonGroup, Button } from '@whispir/ui-lib-v2';
import { ActionRadioCardWrapper } from './SendMessageLayout.styles';

export const WebformChannelSelection = ({
  onWebformChannelSelection,
  selectedChannel,
  onCloseRequest,
  onSubmitClick,
}) => (
  <ActionRadioCardWrapper>
    <div className="intro">
      Choose how your recipients will receive the form.
    </div>
    <ActionCardRadioGroup
      items={[
        {
          heading: 'SMS Message',
          icon: 'SmsOutline',
          description: 'Deliver your web form via a Text Message',
          value: 'send-sms',
        },
        {
          heading: 'Email Message',
          icon: 'EmailOutline',
          description: 'Deliver your web form via an email',
          value: 'send-email',
        },
      ]}
      onChange={(value) => {
        onWebformChannelSelection(value);
      }}
      selectedValue={selectedChannel}
    />
    <ButtonGroup className="button-actions">
      <Button
        disabled={selectedChannel === 'not-selected'}
        text="Get started"
        onClick={onSubmitClick}
      />
      <Button variant="link" text="Cancel" onClick={onCloseRequest} />
    </ButtonGroup>
  </ActionRadioCardWrapper>
);
