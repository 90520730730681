//
// noinspection JSLint
const defaults = {
  draft: {
    messageTemplateName: '',
    templateId: '',
    __typename: '',
  },
  notifications: [],
  workspaces: [],
};

export default defaults;
