import React, { Fragment, PureComponent } from 'react';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import withNamedMutation from '../../apollo/hoc/withNamedMutation';
import { UserContext } from '../../state/User.context';
import { GET_NOTIFICATIONS_LOCAL } from '../../apollo/queries/workflow-queries';
import { REMOVE_NOTIFICATION_LOCAL } from '../../apollo/mutations/workflow-mutation';
import { Notification } from './Notification';

export class NotificationsProvider extends PureComponent {
  static contextType = UserContext;

  static defaultProps = {
    children: <div />,
    notificationsData: {
      notifications: [],
    },
    userWorkspacesGQL: {},
  };

  destroyNotification = (id) => {
    const { removeNotification } = this.props;
    const { submit } = removeNotification;
    const transitionTime = 180;

    setTimeout(() => {
      submit({ id });
    }, transitionTime);
  };

  renderNotifications = () => {
    const { notificationsData } = this.props;
    const { notifications = [] } = notificationsData;
    const hasNotifications = notifications.length;
    return hasNotifications ? (
      <Notification
        notifications={notifications}
        onClose={this.destroyNotification}
      />
    ) : null;
  };

  render() {
    const { children } = this.props;
    return (
      <Fragment>
        {children}
        {this.renderNotifications()}
      </Fragment>
    );
  }
}

export default compose(
  graphql(GET_NOTIFICATIONS_LOCAL, { name: 'notificationsData' }),
  withNamedMutation(REMOVE_NOTIFICATION_LOCAL, { name: 'removeNotification' }),
)(NotificationsProvider);
