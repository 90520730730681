import { AxiosInstance } from 'axios';
import { useCallback } from 'react';
import { GET_PAGINATED_WORKFLOWS } from '../apollo/queries/workflow-queries';
import { useLoading } from './useLoading';

const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_OFFSET = 0;

export const useGetWorkflows = ({
  api,
  workspaceId,
}: {
  api: AxiosInstance;
  workspaceId: string;
}) => {
  const getWorkflows = useCallback(
    async ({ pageSize = DEFAULT_PAGE_SIZE, offset = DEFAULT_OFFSET }) => {
      const { data } = await api.request(
        {
          data: {
            operationName: 'getPaginatedUserWorkflows',
            variables: {
              pageSize,
              offset,
            },
            query: GET_PAGINATED_WORKFLOWS,
          }
        },
      );

      return data;
    },
    [api],
  );

  return useLoading(getWorkflows);
};
