import styled from 'styled-components';
import { Fonts } from '@whispir/ui-lib-v2';

export const Title = styled.div`
  ${Fonts.subhead};
  margin: 0 0 16px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 32px;
  & > * {
    margin-right: 20px;
  }
`;

export const DeleteButtonWrapper = styled.a`
  display: block;
  cursor: pointer;
  ${Fonts.link};
  background-color: ${(props) => props.backgroundColor};
  text-decoration: none;
  padding: 12px 0px;
`;
