//
import { GET_NOTIFICATIONS_LOCAL } from '../../queries/workflow-queries';

const removeNotification = (_, { id }, { cache }) => {
  const { notifications } = cache.readQuery({ query: GET_NOTIFICATIONS_LOCAL });
  const newNotifications = notifications.filter((n) => n.id !== id);
  const data = {
    notifications: [...newNotifications],
  };

  cache.writeQuery({ query: GET_NOTIFICATIONS_LOCAL, data });

  return null;
};

export default removeNotification;
