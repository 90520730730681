import styled from 'styled-components';
import { Colors } from '@whispir/ui-lib-v2';

export const StyledTitle = styled.p`
  svg {
    fill: ${Colors.warning};
    padding-right: 8px;
  }

  * {
    vertical-align: middle;
  }
`;
