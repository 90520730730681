import { parse, getRootStep } from '@whispir/workflow-definition';
import { CopyText, Icons } from '@whispir/ui-lib-v2';
import moment from 'moment';

export const WORKFLOW_STATUS_DRAFT = 'Draft';
export const WORKFLOW_STATUS_ACTIVE = 'Active';
export const WORKFLOW_STATUS_ARCHIVED = 'Archived';

export const parseDate = (value) => {
  let date = new Date(value).valueOf();

  if (Number.isNaN(date)) {
    let parsedValue;
    // If value cannot be parsed initially, try Number.parse the value
    try {
      parsedValue = Number.parseInt(value, 10);
      date = new Date(parsedValue).valueOf();
    } catch (e) {
      throw new Error(`Value: ${value} could not be parsed`);
    }
  }

  return date;
};

export const getWorkflowListWithStatus = (workflowTriggers, workflowsList) => {
  const workflowTriggerMap = workflowTriggers.reduce(
    (acc, { workflowId, workflowEndDate }) => {
      const currentList = acc[workflowId] || [];

      return {
        ...acc,
        [workflowId]: [...currentList, workflowEndDate],
      };
    },
    {},
  );

  const getWorkflowStatusFromTriggers = (workflowEndDates) => {
    const now = Date.now();
    const parsedWorkflowEndDates = workflowEndDates.map(parseDate);
    const isActive = parsedWorkflowEndDates.some(
      (workflowEndDate) => workflowEndDate > now,
    );

    let status;
    if (isActive) {
      status = WORKFLOW_STATUS_ACTIVE;
    } else {
      status = WORKFLOW_STATUS_ARCHIVED;
    }

    return status;
  };

  const workflowStatusMap = Object.entries(workflowTriggerMap).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: getWorkflowStatusFromTriggers(value),
    }),
    {},
  );

  const updatedWorkflows = workflowsList.map((workflow) => ({
    ...workflow,
    status: workflowStatusMap[workflow.id] || WORKFLOW_STATUS_DRAFT,
  }));

  return updatedWorkflows;
};

// returns workflow data with status
export const sortWorkflowsByDate = ({ workflowsList }) => {
  return (
    workflowsList &&
    workflowsList.sort((a, b) => parseDate(b.created) - parseDate(a.created))
  );
};

export const createWorkflowRows = (data) => {
  return data.map(
    ({ name, status, modified, creatorUserName, id, definition }) => {
      const parsedDefinition = parse(definition);
      const rootStep = getRootStep(parsedDefinition);

      const {
        data: { activationId, triggers },
      } = rootStep;
      const [workflowTrigger] = triggers;

      // resolving the "type" (e.g. form/manual) of each workflow to determine which icon to render
      const { type } = workflowTrigger;

      const enhancedWorkflowName = {
        name,
        status,
        type,
      };

      return {
        id,
        name: enhancedWorkflowName,
        status,
        modified: parseDate(modified),
        creatorUserName,
        triggerFormUrl: activationId
          ? `${process.env.REACT_APP_VIEWER_API_URL}/${activationId}`
          : 'N/A',
      };
    },
  );
};

export const renderWorkflowName = (enhancedWorkflowName) => {
  const { name, status, type } = enhancedWorkflowName;
  const iconMap = {
    form: 'WebformTrigger',
    manual: 'DefaultTrigger',
  };

  const iconToRender = iconMap[type];
  const Icon = Icons[iconToRender];
  return (
    <div className="workflow-name-wrapper" data-testid="workflow-name">
      <div
        className={
          status === WORKFLOW_STATUS_ARCHIVED
            ? 'workflow-icon disabled'
            : 'workflow-icon'
        }
      >
        <Icon />
      </div>
      <div className="workflow-name">{name}</div>
    </div>
  );
};

export const renderTableDate = (value) => (
  <div>{moment(value).format('MMM DD, HH:mm')}</div>
);

export const renderStatusValue = (value) => (
  <div className="status-wrapper" data-testid="status">
    <div className={`${value.toLowerCase()}-status-icon`} />
    {value}
  </div>
);

export const createWorkflowsTableHeader = ({ createNotification }) => {
  return [
    {
      field: 'name',
      headerName: 'Workflow Name',
      dataType: 'Text',
      truncateLongText: true,
      maxWidth: '400px',
      renderCell: renderWorkflowName,
    },
    {
      field: 'status',
      headerName: 'Status',
      dataType: 'Text',
      renderCell: renderStatusValue,
      disableColumnSort: true,
    },
    {
      field: 'creatorUserName',
      headerName: 'Created By',
      dataType: 'Text',
      disableColumnSort: true,
    },
    {
      disableColumnSort: true,
      field: 'triggerFormUrl',
      headerName: 'Webform URL',
      dataType: 'Text',
      renderCell: (data) => {
        return data !== 'N/A' ? (
          <CopyText
            text={data}
            onClick={(message) => {
              navigator.clipboard.writeText(data);
              createNotification(message);
            }}
            successMessage="Link copied successfully!"
          />
        ) : (
          data
        );
      },
    },
    {
      field: 'modified',
      headerName: 'Last Updated',
      dataType: 'Date',
      truncateLongText: true,
      maxWidth: '170px',
      renderCell: renderTableDate,
    },
  ];
};

// General sorting function and this was copied from 'QueryDataTable.stories'
export const sortRows = ({ rowsData, field, isAscending }) =>
  field
    ? [...rowsData].sort((a, b) => {
        let aColumn = a[field];
        let bColumn = b[field];

        if (!isAscending) {
          aColumn = b[field];
          bColumn = a[field];
        }
        if (aColumn === bColumn) return 0;
        else if (aColumn < bColumn) return -1;
        return 1;
      })
    : [...rowsData];
