/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import {
  withSideDrawerContext,
  SideDrawerContent,
  Divider,
  Button,
  Icons,
  Tooltip,
} from '@whispir/ui-lib-v2';
import Options from '../PropertyContent/Options';
import { NodeWrapper, StyledCloseButton } from './BaseAddNodeButton.style';
import { ReactComponent as Arrow } from './assets/Arrow.svg';

export class BaseAddNodeButtonRender extends PureComponent {
  state = {
    popoverContent: null,
    isConfigDisplayed: false,
  };

  // This function is called when the popover/drawer is removed, whether that was by us, or by another means (ie. the user clicking a different node).
  hideAddNodesConfiguration = () => {
    this.setState({
      isConfigDisplayed: false,
    });
  };

  // This is function is to programatically close the current popover/drawer
  handleExplicitClose = () => {
    const {
      drawerContext: { setContent },
    } = this.props;
    setContent(null);

    this.hideAddNodesConfiguration();
  };

  handleActionSelect = (actionType) => {
    const { onAddNode } = this.props;
    if (actionType) {
      onAddNode(actionType);
    }
    this.handleExplicitClose();
  };

  // suppress panning when clicking the node
  onPress = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  showAddNodesOptions = (event) => {
    const { previousNode, drawerContext } = this.props;
    const { setContent } = drawerContext;
    const nodeConfigurationContent = (
      <Options
        onActionSelect={this.handleActionSelect}
        previousNode={previousNode}
      />
    );

    // This is required in order to show the 'active' state on the button
    this.setState({
      isConfigDisplayed: true,
    });

    if (!setContent) {
      throw new Error(
        'side-drawer feature toggle is emabled, but we were not passed a setContent function (the function for updating the SideDrawer context)',
      );
    }

    const cancelButton = (
      <StyledCloseButton>
        <div className="sticky-cancel-button">
          <Divider isHorizontal dividerType="solid" />
          <Button
            variant="link"
            onClick={() => setContent(null)}
            startIcon=""
            text="Cancel"
          />
        </div>
      </StyledCloseButton>
    );

    setContent(
      <SideDrawerContent
        onUnmount={this.hideAddNodesConfiguration}
        title="Add a Step"
        key={previousNode.id}
        footer={cancelButton}
      >
        {nodeConfigurationContent}
      </SideDrawerContent>,
    );
  };

  render() {
    const { isConfigDisplayed } = this.state;
    const { isBetweenNode } = this.props;
    return (
      <NodeWrapper
        className={`${
          isBetweenNode ? 'between-node-button' : 'leaf-node-button'
        }
        `}
      >
        {!isBetweenNode && <div className="vertical-connector" />}
        <button
          className={`${isConfigDisplayed ? 'active' : ''} `}
          type="button"
          onMouseDown={this.onPress}
          onClick={this.showAddNodesOptions}
          data-button={isBetweenNode ? 'add-in-between' : 'add'}
        >
          {isBetweenNode && (
            <React.Fragment>
              <div className="vertical-connector" />
              <Arrow className="arrow-icon" />
              <div className="plusWrapper">
                <Tooltip ariaLabel="Insert a step">
                  <Icons.Plus />
                </Tooltip>
              </div>
            </React.Fragment>
          )}
          {!isBetweenNode && <Icons.Plus />}
        </button>
      </NodeWrapper>
    );
  }
}

export const BaseAddNodeButton = withSideDrawerContext(BaseAddNodeButtonRender);
