import { richSubmitButtonBlockModel } from '@whispir/ui-lib-v2';
import { sendMessageFactory, ACTION_TYPES } from '@whispir/workflow-factory';

const { SEND_EMAIL, SEND_SMS, SEND_WEBFORM } = ACTION_TYPES;
const {
  dataFactory: { generateSendWebformData },
  contentFactory: { generateRawContent, generateComponentContent },
  messageFactory: { generateEmailMessage, generateSmsMessage },
} = sendMessageFactory;

export const initialiseWebformNode = ({ webformMode, node }) => {
  switch (webformMode) {
    case SEND_SMS: {
      return {
        ...node,
        data: generateSendWebformData({
          ...node.data,
          message: generateSmsMessage({
            content: generateRawContent({
              subject: '',
              body: '',
            }),
          }),
          link: {
            type: 'webform',
            content: generateComponentContent({
              subject: '',
              components: [richSubmitButtonBlockModel(Date.now())],
            }),
          },
        }),
      };
    }

    case SEND_EMAIL: {
      return {
        ...node,
        data: generateSendWebformData({
          ...node.data,
          message: generateEmailMessage({
            content: generateComponentContent({
              subject: '',
              components: [],
            }),
          }),
          link: {
            type: 'webform',
            content: generateComponentContent({
              subject: '',
              components: [richSubmitButtonBlockModel(Date.now())],
            }),
          },
        }),
      };
    }
    default: {
      throw new Error(
        `${webformMode} is not a valid action type for ${SEND_WEBFORM}`,
      );
    }
  }
};
