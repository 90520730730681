import { AxiosInstance } from 'axios';
import { useCallback } from 'react';
import { GET_TRIGGERED_WORKFLOWS_QUERY } from '../apollo/queries/workflow-queries';
import { useLoading } from './useLoading';

// Queries the trigger history of all workflows in a given workspace
export const useGetTriggeredWorkflows = ({
  api,
  workspaceId,
}: {
  api: AxiosInstance;
  workspaceId: string;
}) => {
  const getTriggeredWorkflows = useCallback(async () => {
    const { data } = await api.request(
      {
        data: {
          operationName: 'getTriggeredWorkflows',
          variables: {
            workspaceId,
          },
          query: GET_TRIGGERED_WORKFLOWS_QUERY,
        }
      },
    );

    return data;
  }, [api, workspaceId]);

  return useLoading(getTriggeredWorkflows);
};
