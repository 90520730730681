import { useCallback, useState } from 'react';

export const useLoading = <T, U>(fn: (args: T) => Promise<U>) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const callback = useCallback(
    async (args: T) => {
      setIsLoading(true);

      try {
        const data = await fn(args);

        return data;
      } catch (err) {
        // @ts-ignore
        setError(err);
        throw err;
      } finally {
        setIsLoading(false);
      }
    },
    [fn],
  );

  return { callback, isLoading, error };
};
