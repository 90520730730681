//
import React, { Fragment } from 'react';
import cx from 'classnames';
import { ColoredIcons } from '@whispir/ui-lib-v2';

const { Web, Sms, Email, SmsDraftState, EmailDraftState } = ColoredIcons;

const SendMessageTask = ({ node, isValid }) => {
  const getMessageIcon = () => {
    const {
      data: {
        message: { type, content },
      },
    } = node;

    switch (type) {
      case 'sms':
        const { body } = content;
        return {
          name: type,
          icon: body.length !== 0 ? <Sms /> : <SmsDraftState />,
        };
      case 'email':
        const doesEmailHaveContent =
          !!content.contentId ||
          (content.components && content.components.length);

        return {
          name: type,
          icon: doesEmailHaveContent ? <Email /> : <EmailDraftState />,
        };
      default:
        throw new Error(`Action type not found: ${type}`);
    }
  };

  const getLinkIcon = () => {
    const {
      data: { link },
    } = node;
    if (link) {
      const { type } = link;
      switch (type) {
        case 'web':
          return {
            name: type,
            icon: <Web />,
          };
        default:
          throw new Error(`Link type not found: ${link.type}`);
      }
    }

    return null;
  };
  const classes = cx({ dirty: node.isDirty, error: !isValid });
  let nodeStatusLabel = 'Create message';

  if (isValid && node.isDirty) {
    nodeStatusLabel = null;
  }
  const renderGroupIcon = () => {
    const {
      data: { stepName },
    } = node;
    const actionLabel = stepName;

    const MessageIcon = getMessageIcon();
    const LinkIcon = getLinkIcon();

    const icons = [MessageIcon, LinkIcon].filter((icon) => icon !== null);

    return (
      <Fragment>
        <div className={`${icons.length !== 1 ? 'group-icon' : 'single-icon'}`}>
          {icons.map(({ name, icon }) => (
            <div
              data-icontype={name}
              className={`${name} icon-shadow`}
              key={name}
            >
              {icon}
            </div>
          ))}
        </div>
        <div className="label">{actionLabel}</div>
      </Fragment>
    );
  };

  return (
    <Fragment>
      {renderGroupIcon()}
      <div className={`state ${classes}`} title={nodeStatusLabel}>
        {nodeStatusLabel}
      </div>
    </Fragment>
  );
};

SendMessageTask.defaultProps = {
  id: 0,
  data: {},
  type: '',
  action: '',
  isDirty: false,
  next: [],
};

export default SendMessageTask;
