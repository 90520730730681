import styled from 'styled-components';
import { Colors, Fonts } from '@whispir/ui-lib-v2';

const SnackbarBackgrounds = {
  success: Colors.success,
  error: Colors.error,
  info: Colors.white_3,
  warning: Colors.warning,
};

export const SnackbarWrapper = styled.div`
  .MuiPaper-root {
    margin-left: 86px;
    background-color: ${Colors.grey_blue}; //popover is overwriting this, so we set it here.
  }
`;

export const SvgWrapper = styled.span`
  position: absolute;
  display: block;
  top: calc(50% - (20px / 2));
  width: 20px;
  height: 20px;
  margin-top: -12px;
  margin-left: -11px;
  left: 50%;

  svg {
    fill: ${Colors.white};
  }
`;

export const MessageWrapper = styled.span`
  ${Fonts.bodyLarge};
  height: 60px;
  display: block;
  line-height: 60px;
`;

export const IconWrapper = styled.span`
  float: left;
  position: relative;
  ${Fonts.bodyLarge};
  display: block;
  width: 48px;
  height: 60px;
  margin-right: 16px;
  background-color: ${(props) => SnackbarBackgrounds[props.type]};
`;
