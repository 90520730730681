import { AxiosInstance } from 'axios';
import { useCallback } from 'react';
import { TRIGGER_WORKFLOW_MUTATION } from '../apollo/mutations/workflow-mutation';
import { useLoading } from './useLoading';

/**
 * This API endpoint triggers the execution of a basic workflow and
 * creates a trigger token instance that records the status of the execution
 * (e.g. workflowStartTime, workflowEndDate, etc)
 */
export const useTriggerWorkflow = ({ api }: { api: AxiosInstance }) => {
  const triggerWorkflow = useCallback(
    async (body) => {
      const { data } = await api.request(
        {
          data: {
            operationName: 'triggerWorkflow',
            variables: {
              // https://bitbucket.org/whispirdevops/workflow-engine/src/47f81aa12ff36d20cc16042ce7d880e18f03d7f4/sparx/workflow-studio-api/src/schema/workflowTrigger/workflowTrigger.resolvers.js#lines-136
              // based on this, the mutation resolver only requires these two values to perform the task
              input: {
                workflowId: body.workflowId,
                workflowVersion: body.workflowVersion,
              },
            },
            query: TRIGGER_WORKFLOW_MUTATION,
          }
        },
      );

      return data;
    },
    [api],
  );

  return useLoading(triggerWorkflow);
};
