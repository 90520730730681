import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import ApolloLinkTimeout from 'apollo-link-timeout';
import { onError } from 'apollo-link-error';
import { createHttpLink } from 'apollo-link-http';
import { withClientState } from 'apollo-link-state';

import cache from './cache';
import resolvers from './resolvers';
import defaults from './defaults';
import typeDefs from './schema';

const MAX_REQUEST_SECONDS = 60;

export const hasAuthError = (errors = []) =>
  errors.some((err) => err.extensions.code === 'UNAUTHENTICATED');

// Handle errors from all GraphQL queries, mutations and network issues
export function errorHandler({
  graphQLErrors,
  networkError,
  operation,
  forward,
}) {
  const { operationName } = operation;
  const type = 'mutation' in operation ? 'mutation' : 'query';

  // handle other error types here
  if (graphQLErrors) {
    console.error(`Error executing ${type}: ${operationName}`, {
      operation,
      errors: graphQLErrors,
    });
    return;
  }

  if (networkError) {
    console.error(`Intercepted networkError: ${operationName}`, {
      operation,
      error: networkError,
    });
  }
}

export default function createClient(
  graphQLServer = 'http://local.whispirdev.com:4000/',
) {
  const httpLink = createHttpLink({
    uri: graphQLServer,
    credentials: 'include',
    headers: {
      'Cache-Control': 'no-store, max-age=0',
    },
  });
  const clientState = withClientState({
    cache,
    defaults,
    resolvers,
    typeDefs,
  });

  return new ApolloClient({
    name: 'WorkflowStudioClient',
    cache,
    link: new ApolloLinkTimeout(MAX_REQUEST_SECONDS * 1000).concat(
      ApolloLink.from([onError(errorHandler), clientState, httpLink]),
    ),
    connectToDevTools: true,
  });
}
