import styled from 'styled-components';
import { Colors, Fonts, transitionStyle } from '@whispir/ui-lib-v2';

export const ExpressionsContainer = styled.div`
  overflow-y: auto;
  padding: 8px 16px 8px 0;
  min-height: 500px;
`;

export const StyledExpressionComposite = styled.div`
  > .secondary {
    display: flex;
    justify-content: center;
  }

  .and-button {
    display: block;
    button {
      color: ${Colors.grey_blue};
      display: block;
      margin-left: auto;
    }
  }

  .or-button {
    display: block;
    button {
      color: ${Colors.grey_blue};

      display: block;
      margin: 0 auto;
    }
  }
`;

export const ExpressionFieldset = styled.div`
  border-radius: 8px;
  margin-bottom: 14px;
  padding: 3px 8px 8px;
  position: relative;
  background-color: ${Colors.grey_blue_0};

  .secondary {
    display: flex;
    justify-content: flex-end;
  }
`;

export const ExpressionMoleculeWrapper = styled.div`
  align-self: center;
  width: 100%;
`;

export const ExpressionItemWrapper = styled.div`
  margin-bottom: 32px;
  margin-right: 0;

  .delete-button {
    cursor: pointer;
    margin-bottom: -4px;

    svg {
      fill: ${Colors.grey_blue_3};
      transition: fill ${transitionStyle};
    }

    &:hover {
      svg {
        fill: ${Colors.accent};
      }
    }
  }

  .MuiChip-deleteIcon {
    height: 18px;
  }

  .expression-item-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 2px;

    .small {
      width: 30px;
      height: 30px;
      margin-right: 7px;
    }
  }
`;

export const ConditionTitle = styled.div`
  align-self: start;
  color: ${Colors.voice};
  text-transform: uppercase;
  ${Fonts.body};
  font-weight: 900;
  letter-spacing: -0.01em;
  width: 50px;
  padding: 8px 0;

  &.headline {
    width: calc(100% - 78px);
    padding: 0 14px;
    margin: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > span {
      display: inline-block;
      padding: 0 14px;
    }
  }
`;
