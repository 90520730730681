import axios from 'axios';

export const saveContent = async (contentApiUrl, data) => {
  const api = axios.create({
    withCredentials: true,
  });

  const saveContentRes = await api.post(`${contentApiUrl}/content`, data);

  const { contentId } = saveContentRes.data;
  // No need to await publications because loading the template will take longer
  // this callback function only needs to return the Save Content API response
  api.post(`${contentApiUrl}/content/${contentId}/publications`, {
    workspaceId: data.workspaceId,
    contentId,
  });

  return saveContentRes;
};
